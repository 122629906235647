import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PersonalParticularModel } from '@shared/data-access/models';
import { filter, Observable, take } from 'rxjs';
import { TeamOrgChartAction } from '../actions';
import { TeamOrgChartReducer } from '../reducers';
import { TeamOrgChartSelector } from '../selectors';

@Injectable()
export class TeamOrgChartFacade {
  selectedItem$ = this._store.select(TeamOrgChartSelector.selectTeamOrgChart);
  constructor(private _store: Store<TeamOrgChartReducer.TeamOrgChartState>) {}

  /**
   * Get @PersonalParticularModel by id
   */
  getById(id: number): Observable<PersonalParticularModel | null> {
    this._store.dispatch(TeamOrgChartAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<PersonalParticularModel | null>;
  }
}
