import { createAction, props } from '@ngrx/store';
import { OverridingDistributionModel } from '@shared/data-access/models';


export const getDetail = createAction(
  '[OVERRIDING DISTRIBUTION] GET DETAIL',
  props<{item:OverridingDistributionModel}>()
);


