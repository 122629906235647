import { createAction, props } from '@ngrx/store';
import { ProjectTransactionModel } from '@shared/data-access/models';


export const getDetail = createAction(
  '[PROJECT TRANSACTION] GET DETAIL',
  props<{item:ProjectTransactionModel}>()
);


