import { createFeature, createReducer, on } from '@ngrx/store';
import { KeyAppointmentModel, UpdatedByModel } from '@shared/data-access/models';
import { KeyAppointmentListAction } from '../actions';

export const featureName = 'keyAppointmentList';

export interface KeyAppointmentListState {
  updatedAt: string | null;
  updatedBy: UpdatedByModel | null;
  item: KeyAppointmentModel | null;
}

export const initialState: KeyAppointmentListState = {
  updatedAt: null,
  updatedBy: null,
  item: null,
};

export const keyAppointmentListFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(KeyAppointmentListAction.loadItemSuccess, (state, prop: { item: KeyAppointmentModel }) => ({ ...state, item: prop.item })),
    on(KeyAppointmentListAction.setLastUpdated, (state, prop: { updatedAt: string; updatedBy: UpdatedByModel }) => {
      const isUpdated = isDateAAfterDateB(state.updatedAt, prop.updatedAt);
      if (isUpdated) return state;
      return {
        ...state,
        updatedAt: prop.updatedAt,
        updatedBy: prop.updatedBy,
      };
    }),
    on(KeyAppointmentListAction.resetUpdateState, state => ({ ...state, updatedAt: null, updatedBy: null }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = keyAppointmentListFeature;

function isDateAAfterDateB(dateA: string | null, dateB: string): boolean {
  if (!dateA) return false;
  const dateAObj = new Date(dateA);
  const dateBObj = new Date(dateB);
  return dateAObj.getTime() > dateBObj.getTime();
}
