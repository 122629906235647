import { Injectable } from '@angular/core';
import { CompanyModel } from '@shared/data-access/models';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SwitchCompanyStateService {
  constructor() {
    // console.log('hello constructor');
  }
  selected: CompanyModel | null = null;
  onSelectionChange = new ReplaySubject<CompanyModel | null>(1);
  set(companySelected: CompanyModel | null): void {
    this.selected = cloneDeep(companySelected);
    this.onSelectionChange.next(companySelected);
  }
}
