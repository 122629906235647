import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { KeyAppointmentListModel } from '@shared/data-access/models';
import { filter, Observable, take } from 'rxjs';
import { KeyAppointmentListAction } from '../actions';
import { KeyAppointmentListReducer } from '../reducers';
import { KeyAppointmentListSelector } from '../selectors';

@Injectable()
export class KeyAppointmentListFacade {
  selectedItem$ = this._store.select(KeyAppointmentListSelector.selectKeyAppointmentList);
  constructor(private _store: Store<KeyAppointmentListReducer.KeyAppointmentListState>) {}

  /**
   * Get @KeyAppointmentListModel by id
   */
  getById(id: number): Observable<KeyAppointmentListModel | null> {
    this._store.dispatch(KeyAppointmentListAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<KeyAppointmentListModel | null>;
  }
}
