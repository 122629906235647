import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { SWITCH_COMPANY_API_LOADER, SwitchCompanyLoaderService, SwitchCompanyStateService } from '@cms/backgrounds/switch-company';

@Component({
  selector: 'cms-switch-company-wraper',
  templateUrl: './switch-company-wraper.component.html',
  styleUrls: ['./switch-company-wraper.component.scss'],
})
export class SwitchCompanyWraperComponent implements OnInit {
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _companyState: SwitchCompanyStateService,
    @Inject(SWITCH_COMPANY_API_LOADER) private _loader: SwitchCompanyLoaderService
  ) {}

  ngOnInit() {
    const companyId = this._activatedRoute.snapshot.queryParamMap.get('companyId') ?? this._companyState.selected?.id;
    console.log(this._activatedRoute.snapshot.url);
    this._router.navigate(['/company', companyId, ...this._activatedRoute.snapshot.url.map(path => path.path)], {
      relativeTo: this._activatedRoute,
      replaceUrl: true,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }
}
