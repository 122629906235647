import { createFeature, createReducer, on } from '@ngrx/store';
import { ContactAction } from '../actions';

export const featureName = 'action';

export interface DataState {
  contactType: string;
}

export const initialState: DataState = {
  contactType: '',
};

export const contactFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(ContactAction.getContactType, (state, prop: { contactType: string }) => ({ ...state, contactType: prop.contactType }))
  ),
});

export const { name, reducer } = contactFeature;
