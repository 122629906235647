import { BaseModel, Default } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
export class RateGroupDto extends BaseModel {
  @Expose()
  key!: string;

  @Expose()
  @Default(0)
  @Type(() => Number)
  value!: number;

  @Expose()
  @Default('percentage')
  unit!: string;

  @Expose()
  @Default('gstExclusive')
  @Type(() => String)
  gstType!: string;
}
export class DealValueReqDto extends BaseModel {
  @Expose()
  @Default(0)
  @Type(() => Number)
  subAmount!: number;

  @Expose()
  @Type(() => RateGroupDto)
  rateGroups!: RateGroupDto[];

  @Expose()
  @Type(() => String)
  type!: string;
}
