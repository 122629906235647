// core
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

// share
import { RecruitmentSelector } from '../selectors';
import { RecruitmentReducer } from '../reducers';
import { RecruitmentAction } from '../actions';

@Injectable()
export class RecruitmentFacade {
  recruitment$ = this._store.select(RecruitmentSelector.selectRecruitmentDetail);

  constructor(private _store: Store<RecruitmentReducer.RecruitmentState>) {}

  getRecruitmentById(id: number): void {
    this._store.dispatch(RecruitmentAction.loadDetail({ id }));
  }
}
