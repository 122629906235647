import { AuthApiService } from '@agent-portal/apis';
import { Injectable } from '@angular/core';
import { ForgotDto, LoginDto, LoginResponseDto, RefreshTokenResponseDto } from '@shared/data-access/dto';
import { AuthApiLoader } from '@shared/features/auth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends AuthApiLoader {
  constructor(private _authApiService: AuthApiService) {
    super();
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  override signIn(credentials: LoginDto): Observable<LoginResponseDto> {
    return this._authApiService.signIn(credentials);
  }

  /**
   * Refresh token
   *
   * @param credentials
   */
  override refreshToken(refreshToken: string): Observable<RefreshTokenResponseDto> {
    return this._authApiService.refreshToken(refreshToken);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(data: { password: string }): Observable<any> {
    return this._authApiService.resetPassword(data);
  }

  /**
   * Forgot password
   *
   * @param email
   */
  forgot(data: ForgotDto): Observable<any> {
    return this._authApiService.forgotPassword(data);
  }
}
