import { createFeature, createReducer, on } from '@ngrx/store';
import { ManagementCommissionCreateDto } from '@shared/data-access/dto';
import { EManagementCommissionPdRole } from '@shared/data-access/enums';
import { ManagementCommissionModel, ManagementCommissionPdModel } from '@shared/data-access/models';
import { ManagementCommissionAction } from '../actions';
import { MANAGEMENT_COMMISSION_PD_DESCRIPTIONS } from '@shared/data-access/data/management-commission.data';

export const featureName = 'managementCommission';

export interface ManagmentCommissionState {
  item: ManagementCommissionModel | null;
  itemCreating: ManagementCommissionCreateDto;
  submittingStatus: {
    success: boolean;
    loading: boolean;
    error: any;
  };
}

export const initialState: ManagmentCommissionState = {
  item: null,
  itemCreating: ManagementCommissionCreateDto.createEmpty(),
  submittingStatus: {
    success: false,
    loading: false,
    error: null,
  },
};

export const managementCommissionFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(ManagementCommissionAction.setItemDetail, (state, { item }) => ({
      ...state,
      item,
    })),
    on(ManagementCommissionAction.updateItemDetail, (state, { data }) => ({
      ...state,
      item: ManagementCommissionModel.merge(state.item ?? ManagementCommissionModel.createEmpty(), data, {
        enableImplicitConversion: true,
      }),
    })),
    on(ManagementCommissionAction.setItemCreating, (state, { data }) => ({
      ...state,
      itemCreating: ManagementCommissionCreateDto.merge(state.itemCreating, data, {
        enableImplicitConversion: true,
      }),
    })),
    on(ManagementCommissionAction.addPdCreating, (state, { data }) => {
      const key: keyof Pick<ManagementCommissionCreateDto, 'pdOverride' | 'pdReferral' | 'pdManagement'> =
        ([
          { key: 'pdOverride', role: EManagementCommissionPdRole.PDOverride },
          { key: 'pdReferral', role: EManagementCommissionPdRole.PDReferral },
          {
            key: 'pdManagement',
            role: EManagementCommissionPdRole.PDManagement,
          },
        ].find(({ role }) => role === data.pdRole)?.key as keyof Pick<ManagementCommissionCreateDto, 'pdOverride' | 'pdReferral' | 'pdManagement'>) ?? 'pdOverride';
      const listItems: ManagementCommissionPdModel[] = [...(state.itemCreating[key] ?? [])];
      const { appointmentTypeId, appointmentType, percentage, pdRole, additional } = data;
      const item = ManagementCommissionPdModel.fromJson({
        id: listItems.length,
        appointmentTypeId,
        appointmentType,
        percentage,
        pdRole,
        additional,
      });
      return {
        ...state,
        itemCreating: ManagementCommissionCreateDto.fromJson({
          ...state.itemCreating,
          [key]: [...listItems, item],
        }),
      };
    }),
    on(ManagementCommissionAction.editPdCreating, (state, { id, pdRole, data }) => {
      const key: keyof Pick<ManagementCommissionCreateDto, 'pdOverride' | 'pdReferral' | 'pdManagement'> =
        ([
          { key: 'pdOverride', role: EManagementCommissionPdRole.PDOverride },
          { key: 'pdReferral', role: EManagementCommissionPdRole.PDReferral },
          {
            key: 'pdManagement',
            role: EManagementCommissionPdRole.PDManagement,
          },
        ].find(({ role }) => role === pdRole)?.key as keyof Pick<ManagementCommissionCreateDto, 'pdOverride' | 'pdReferral' | 'pdManagement'>) ?? 'pd';
      const listItems = [...(state.itemCreating[key] ?? [])];
      const { appointmentTypeId, appointmentType, percentage, additional } = data;
      const index = listItems.findIndex(i => id === i.id);
      const item = ManagementCommissionPdModel.fromJson({
        ...listItems[index],
        id,
        appointmentTypeId,
        appointmentType,
        percentage,
        additional,
      });
      listItems[index] = item;
      return {
        ...state,
        itemCreating: ManagementCommissionCreateDto.fromJson({
          ...state.itemCreating,
          [key]: [...listItems],
        }),
      };
    }),
    on(ManagementCommissionAction.deletePdCreating, (state, { id, pdRole }) => {
      const key: keyof Pick<ManagementCommissionCreateDto, 'pdOverride' | 'pdReferral' | 'pdManagement'> =
        ([
          { key: 'pdOverride', role: EManagementCommissionPdRole.PDOverride },
          { key: 'pdReferral', role: EManagementCommissionPdRole.PDReferral },
          {
            key: 'pdManagement',
            role: EManagementCommissionPdRole.PDManagement,
          },
        ].find(({ role }) => role === pdRole)?.key as keyof Pick<ManagementCommissionCreateDto, 'pdOverride' | 'pdReferral' | 'pdManagement'>) ?? 'pd';
      const listItems = [...(state.itemCreating[key] ?? [])];
      const index = listItems.findIndex(i => id === i.id);
      listItems.splice(index, 1);
      return {
        ...state,
        itemCreating: ManagementCommissionCreateDto.fromJson({
          ...state.itemCreating,
          [key]: [...listItems],
        }),
      };
    }),
    on(ManagementCommissionAction.toggleActivePdCreating, (state, { pdRole, active }) => {
      const { activeKey } = MANAGEMENT_COMMISSION_PD_DESCRIPTIONS[pdRole];
      return {
        ...state,
        itemCreating: ManagementCommissionCreateDto.merge(state.itemCreating, {
          [activeKey]: active,
        }),
      };
    }),
    on(ManagementCommissionAction.resetItemCreating, state => ({
      ...state,
      itemCreating: ManagementCommissionCreateDto.createEmpty(),
    })),
    on(ManagementCommissionAction.submitItemCreating, state => ({
      ...state,
      submittingStatus: {
        loading: true,
        success: false,
        error: null,
      },
    })),
    on(ManagementCommissionAction.submitItemCreatingSuccess, state => ({
      ...state,
      submittingStatus: {
        loading: false,
        success: true,
        error: null,
      },
    })),
    on(ManagementCommissionAction.submitItemCreatingFail, (state, { error }) => ({
      ...state,
      submittingStatus: {
        loading: false,
        success: false,
        error,
      },
    })),
    on(ManagementCommissionAction.resetSubmittingStatus, state => ({
      ...state,
      submittingStatus: {
        loading: false,
        success: false,
        error: null,
      },
    }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = managementCommissionFeature;
