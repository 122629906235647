import { createFeature, createReducer, on } from '@ngrx/store';
import { LedgerAccountModel, LedgerPathModel } from '@shared/data-access/models';
import { ChartOfAccountsAction } from '../actions';

export const featureName = 'chartOfAccounts';

export interface DataState {
  item: LedgerAccountModel | null;
  title: string;
  event: boolean;
  path: Map<number, { lastUpdatedAt: number; pathFull: string; result: LedgerPathModel[]; level: number; name: string }>;
  keywork: string;
}

export const initialState: DataState = {
  item: null,
  title: '',
  event: false,
  path: new Map<number, { lastUpdatedAt: number; pathFull: string; result: LedgerPathModel[]; level: number; name: string }>(),
  keywork: '',
};

export const chartOfAccountsFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(ChartOfAccountsAction.getDetail, (state, prop: { item: LedgerAccountModel }) => ({ ...state, item: prop.item })),
    on(ChartOfAccountsAction.getAccountsAction, (state, prop: { title: string }) => ({ ...state, title: prop.title })),
    on(ChartOfAccountsAction.detectFilterAction, (state, prop: { event: boolean }) => ({ ...state, event: prop.event })),
    on(ChartOfAccountsAction.searchByKeywork, (state, prop: { keywork: string }) => ({ ...state, keywork: prop.keywork })),
    on(ChartOfAccountsAction.getLeaderSuccess, (state, prop: { accountItemId: number; pathFull: string; result: LedgerPathModel[]; level: number; name: string }) => {
      const path = state.path;
      path.set(prop.accountItemId, { lastUpdatedAt: new Date().getTime(), pathFull: prop.pathFull, result: prop.result, level: prop.level, name: prop.name });
      return {
        ...state,
        path,
      };
    })
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = chartOfAccountsFeature;
