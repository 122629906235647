// core
import { exhaustMap, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// share
import { RecruitmentApiService } from '@cms/apis';
import { RecruitmentAction } from '../actions';

@Injectable()
export class RecruitmentEffect {
  loadDetail$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(RecruitmentAction.loadDetail),
      exhaustMap(action => {
        return this._apiService.get(action.id).pipe(map(detail => RecruitmentAction.loadDetailSuccess({ detail })));
      })
    );
  });

  constructor(private readonly _apiService: RecruitmentApiService, private readonly _actions$: Actions) {}
}
