import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TaxInvoiceModel } from '@shared/data-access/models';
import { Observable, filter, take } from 'rxjs';
import { TaxInvoiceAction } from '../actions';
import { TaxInvoiceReducer } from '../reducers';
import { TaxInvoiceSelector } from '../selectors';

@Injectable()
export class TaxInvoiceFacade {
  selectedItem$ = this._store.select(TaxInvoiceSelector.selectTaxInvoice);
  constructor(private _store: Store<TaxInvoiceReducer.DataState>) {}

  /**
   * Get @TaxInvoiceModel by id
   */
  getById(id: number): Observable<TaxInvoiceModel | null> {
    this._store.dispatch(TaxInvoiceAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<TaxInvoiceModel | null>;
  }
}
