import { DEFAULT_OPTIONS } from './print-dom.constant';
import { PrintDomCallback, PrintDomOptions } from './print-dom.model';
import { createIFrame, createLinkStyle, createStyle, isValidURL } from './print-dom.util';

export class PrintDom {
  private readonly opts: Required<PrintDomOptions>;
  private readonly iframe: HTMLIFrameElement;
  private isLoading = false;
  private hasEvents = false;
  private callback?: PrintDomCallback;
  private onbeforeprint?: (event: Event) => void;
  private onafterprint?: (event: Event) => void;
  private elCopy?: HTMLElement;

  constructor(options?: PrintDomOptions) {
    // IE 11+ "Object.assign" polyfill
    this.opts = [DEFAULT_OPTIONS, options || {}].reduce((a, b) => {
      Object.keys(b).forEach(k => (a[k] = b[k]));
      return a;
    }, {}) as Required<PrintDomOptions>;

    this.iframe = createIFrame(this.opts.parent);
  }
  private launchPrint(contentWindow: Window) {
    if (!this.isLoading) {
      contentWindow.print();
    }
  }
  private onLoad() {
    if (this.iframe) {
      this.isLoading = false;

      const { contentDocument, contentWindow } = this.iframe;

      if (!contentDocument || !contentWindow) return;

      if (typeof this.callback === 'function') {
        this.callback({
          iframe: this.iframe,
          element: this.elCopy,
          launchPrint: () => this.launchPrint(contentWindow),
        });
      } else {
        this.launchPrint(contentWindow);
      }
    }
  }
  private addEvents() {
    if (!this.hasEvents) {
      this.hasEvents = true;
      this.iframe.addEventListener('load', () => this.onLoad(), false);

      const { contentWindow } = this.iframe;
      if (contentWindow) {
        if (this.onbeforeprint) {
          contentWindow.addEventListener('beforeprint', this.onbeforeprint);
        }
        if (this.onafterprint) {
          contentWindow.addEventListener('afterprint', this.onafterprint);
        }
      }
    }
  }

  /** Gets current Iframe reference */
  getIFrame() {
    return this.iframe;
  }

  /**
   * Print an HTMLElement
   *
   * @param el HTMLElement
   * @param styles Optional styles (css texts or urls) that will add to iframe document.head
   * @param scripts Optional scripts (script texts or urls) that will add to iframe document.body
   * @param callback Optional callback that will be triggered when content is ready to print
   */
  print(el: HTMLElement, styles?: string[], scripts?: string[], callback?: PrintDomCallback) {
    if (this.isLoading) return;

    const { contentDocument, contentWindow } = this.iframe;

    if (!contentDocument || !contentWindow) return;

    this.iframe.src = 'about:blank';
    this.elCopy = el.cloneNode(true) as HTMLElement;

    if (!this.elCopy) return;

    this.isLoading = true;
    this.callback = callback;

    const doc = contentWindow.document;

    doc.open();
    doc.write('<!DOCTYPE html><html><head><meta charset="utf-8"></head><body></body></html>');

    this.addEvents();

    // 1. append custom elements
    const { headElements, bodyElements } = this.opts;

    // 1.1 append custom head elements
    if (Array.isArray(headElements)) {
      headElements.forEach(el => doc.head.appendChild(el));
    }

    // 1.1 append custom body elements
    if (Array.isArray(bodyElements)) {
      bodyElements.forEach(el => doc.body.appendChild(el));
    }

    // 2. append custom styles
    if (Array.isArray(styles)) {
      styles.forEach(value => {
        if (value) {
          doc.head.appendChild(isValidURL(value) ? createLinkStyle(doc, value) : createStyle(doc, value));
        }
      });
    }

    // 3. append element copy
    doc.body.appendChild(this.elCopy);

    // 4. append custom scripts
    if (Array.isArray(scripts)) {
      scripts.forEach(value => {
        if (value) {
          const script = doc.createElement('script');
          if (isValidURL(value)) {
            script.src = value;
          } else {
            script.innerText = value;
          }
          doc.body.appendChild(script);
        }
      });
    }

    doc.close();
  }
  /**
   * Print an URL
   *
   * @param url URL to print
   * @param callback Optional callback that will be triggered when content is ready to print
   */
  printURL(url: string, callback?: PrintDomCallback) {
    if (this.isLoading) return;

    this.addEvents();
    this.isLoading = true;
    this.callback = callback;
    this.iframe.src = url;
  }
  /**
   * Print an URL
   *
   * @param url URL to print
   * @param callback Optional callback that will be triggered when content is ready to print
   */
  printHtmlString(htmlString: string, callback?: PrintDomCallback) {
    if (this.isLoading) return;

    this.addEvents();
    this.isLoading = true;
    this.callback = callback;
    this.iframe.srcdoc = htmlString;
  }
}
