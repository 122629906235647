import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BlankFormReducer } from '../reducers';
import { EProjectType } from '@shared/data-access/enums';

export const selectBlankFormFeature = createFeatureSelector<BlankFormReducer.BlankFormState>(BlankFormReducer.featureName);

export const selectItemDetail = createSelector(selectBlankFormFeature, ({ item }) => item);

export const selectBlankFormId = createSelector(selectBlankFormFeature, ({ item }) => item?.id);

export const selectDraftItem = createSelector(selectBlankFormFeature, ({ draftItem }) => draftItem);

export const selectDraftType = createSelector(selectBlankFormFeature, ({ draftType }) => draftType);

export const selectDraftState = createSelector(selectBlankFormFeature, ({ draftItem, draftType }) => ({
  draftItem,
  draftType,
}));

export const selectOptionDateDraftItem = createSelector(selectDraftItem, draftItem => draftItem?.optionDate);

export const selectBlankFormReferenceDraftItem = createSelector(selectDraftItem, draftItem => draftItem?.blankFormCode);

export const selectTransactionReferenceDraftItem = createSelector(selectDraftItem, draftItem => draftItem?.transactionCode);

export const selectSubmittingStatus = createSelector(selectBlankFormFeature, ({ submittingStatus }) => submittingStatus);

export const selectIsInternationalProjectDraftItem = createSelector(selectDraftItem, draftItem => draftItem.project?.entity === EProjectType.INTERNATIONAL);

export const selectLeaders = createSelector(selectBlankFormFeature, ({ leaders }) => leaders);

export const selectBds = createSelector(selectBlankFormFeature, ({ bds }) => bds);

export const selectBonuses = createSelector(selectBlankFormFeature, ({ bonuses }) => bonuses);

export const selectOverridings = createSelector(selectBlankFormFeature, ({ overridings }) => overridings);

export const selectPools = createSelector(selectBlankFormFeature, ({ pools }) => pools);

export const selectPds = createSelector(selectBlankFormFeature, ({ pds }) => pds);

export const selectReferrals = createSelector(selectBlankFormFeature, ({ referrals }) => referrals);

export const selectOtherFees = createSelector(selectBlankFormFeature, ({ otherFees }) => otherFees);

export const selectBuyers = createSelector(selectBlankFormFeature, ({ buyers }) => buyers);

export const selectBlankFormTypeDraftItem = createSelector(selectDraftItem, draftItem => draftItem?.blankFormType);

export const selectInvalidAgents = createSelector(selectBlankFormFeature, state => state.invalidAgents);
