import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { ResaleTransactionAction } from '../actions';
import { ResaleTransactionSelector } from '../selectors';
import { ResaleDocumentModel, ResaleExternalCoBrokeModel, ResaleTransactionModel } from '@shared/data-access/models';
import { EResaleType } from '@shared/data-access/enums';
import { IResaleBilling } from '@shared/data-access/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ResaleTransactionFacade {
  selectedItem$ = this._store.select(ResaleTransactionSelector.selectItemDetail);
  selectedDraftItem$ = this._store.select(ResaleTransactionSelector.selectDraftItem);
  selectedDraftState$ = this._store.select(ResaleTransactionSelector.selectDraftState);
  selectedSubmittingStatus$ = this._store.select(ResaleTransactionSelector.selectSubmittingStatus);
  selectedDocumentsDraftItem$ = this._store.select(ResaleTransactionSelector.selectDocumentsDraftItem);
  selectedBillingValueDraftItem$ = this._store.select(ResaleTransactionSelector.selectBillingValueDraftItem);
  selectedTotalExternalsReceivedPercentDraftItem$ = this._store.select(ResaleTransactionSelector.selectTotalExternalsReceivedPercentDraftItem);
  selectedTransactionAmountDraftItem$ = this._store.select(ResaleTransactionSelector.selectTransactionAmountDraftItem);

  constructor(private _store: Store) { }

  setItemDetail(item: ResaleTransactionModel | null) {
    this._store.dispatch(ResaleTransactionAction.setItemDetail({ item }));
  }

  loadItemDetail(id: number) {
    this._store.dispatch(ResaleTransactionAction.loadItemDetail({ id }));
  }

  setMetadata(metadata: object) {
    this._store.dispatch(ResaleTransactionAction.setMetadata({ metadata }));
  }
  loadMetadata(id: number) {
    this._store.dispatch(ResaleTransactionAction.loadMetadata({ id }));
  }

  updateItemDetail(data: Partial<ResaleTransactionModel>) {
    this._store.dispatch(ResaleTransactionAction.updateItemDetail({ data }));
  }

  setDraftItemAsCreate() {
    this._store.dispatch(ResaleTransactionAction.setDraftItemAsCreate());
  }

  setDraftItemAsEdit() {
    this._store.dispatch(ResaleTransactionAction.setDraftItemAsEdit());
  }

  updateDraftItem(data: Partial<ResaleTransactionModel>) {
    this._store.dispatch(ResaleTransactionAction.updateDraftItem({ data }));
  }

  updateResaleTypeDraftItem(resaleType: EResaleType) {
    this._store.dispatch(ResaleTransactionAction.updateResaleTypeDraftItem({ resaleType }));
  }

  updateTransactionAmountDraftItem(transactionAmount: number | null) {
    this._store.dispatch(ResaleTransactionAction.updateTransactionAmountDraftItem({ transactionAmount }));
  }

  updateBillingDraftItem(data: Partial<IResaleBilling>) {
    this._store.dispatch(ResaleTransactionAction.updateBillingDraftItem({ data }));
  }

  addExternalDraftItem(item: ResaleExternalCoBrokeModel) {
    this._store.dispatch(ResaleTransactionAction.addExternalDraftItem({ item }));
  }

  updateExternalDraftItem(index: number, item: ResaleExternalCoBrokeModel) {
    this._store.dispatch(ResaleTransactionAction.updateExternalDraftItem({ index, item }));
  }

  deleteExternalDraftItem(index: number) {
    this._store.dispatch(ResaleTransactionAction.deleteExternalDraftItem({ index }));
  }

  addDocumentsDraftItem(items: ResaleDocumentModel[]) {
    this._store.dispatch(ResaleTransactionAction.addDocumentsDraftItem({ items }));
  }

  deleteDocumentsDraftItem(items: ResaleDocumentModel[]) {
    this._store.dispatch(ResaleTransactionAction.deleteDocumentDraftItem({ items }));
  }

  saveDraftItem() {
    this._store.dispatch(ResaleTransactionAction.saveDraftItem());
  }

  saveAndSubmitDraftItem() {
    this._store.dispatch(ResaleTransactionAction.saveAndSubmitDraftItem());
  }

  submitItemSuccess(res: any) {
    this._store.dispatch(ResaleTransactionAction.submitItemSuccess({ res }));
  }

  submitItemFail(error: any) {
    this._store.dispatch(ResaleTransactionAction.submitItemFail({ error }));
  }

  resetDraftItem() {
    this._store.dispatch(ResaleTransactionAction.resetDraftItem());
  }

  resetSubmittingStatus() {
    this._store.dispatch(ResaleTransactionAction.resetSubmittingStatus());
  }

  updateMetadataDraftItem(metadata: object) {
    this._store.dispatch(ResaleTransactionAction.updateMetadataDraftItem({ metadata }));
  }

  submitDraftItem() {
    this._store.dispatch(ResaleTransactionAction.submitDraftItem());
  }
}
