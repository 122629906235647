import { createAction, props } from '@ngrx/store';
import { ProjectConsultantModel, ProjectTeamMemberModel, UpdatedByModel } from '@shared/data-access/models';

export const getDetail = createAction('[Project] GET DETAIL', props<{ item: ProjectConsultantModel }>());

export const countMember = createAction('[Project] COUNT MEMBER', props<{ teamId: number }>());
export const countMemberSuccess = createAction('[Project] COUNT MEMBER SUCCESS', props<{ teamId: number; total: number }>());
export const countMemberFailure = createAction('[Project] COUNT MEMBER FAILURE', props<{ error: any }>());

export const getLeader = createAction('[Project] GET LEADER', props<{ teamId: number }>());
export const getLeaderSuccess = createAction('[Project] GET LEADER SUCCESS', props<{ teamId: number; leader: ProjectTeamMemberModel }>());
export const getLeaderFailure = createAction('[Project] GET LEADER FAILURE', props<{ error: any }>());

export const setLastUpdated = createAction('[Project] SET LAST UPDATED', props<{ updatedAt: string; updatedBy: UpdatedByModel }>());

export const resetState = createAction('[Project] RESET STATE');

export const resetUpdateState = createAction('[Project] RESET UPDATE STATE');
