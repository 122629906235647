import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap, of, switchMap } from 'rxjs';
import { ProjectTeamMemberApiService, ProjectConsultantApiService } from '@cms/apis';
import { ProjectConsultantAction } from '../actions';

@Injectable()
export class ProjectConsultantEffects {
  countMember$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectConsultantAction.countMember),
      mergeMap(action =>
        this.projectTeamMemberApiService.countMember(action.teamId, true).pipe(
          map(data => ProjectConsultantAction.countMemberSuccess(data)),
          catchError(error => of(ProjectConsultantAction.countMemberFailure({ error })))
        )
      )
    );
  });
  leader$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectConsultantAction.getLeader),
      mergeMap(action =>
        this.projectTeamMemberApiService.getTeamLeader(action.teamId).pipe(
          map(data => ProjectConsultantAction.getLeaderSuccess({ teamId: action.teamId, leader: data })),
          catchError(error => of(ProjectConsultantAction.countMemberFailure({ error })))
        )
      )
    );
  });
  constructor(
    private actions$: Actions,
    private projectConsultantApiService: ProjectConsultantApiService,
    private projectTeamMemberApiService: ProjectTeamMemberApiService
  ) {}
}
