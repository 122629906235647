import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DeveloperReducer } from "../reducers";

export const selectDeveloperFeature = createFeatureSelector<DeveloperReducer.DataState>(
  DeveloperReducer.featureName
);

export const selectFeatureDeveloper = createSelector(
  selectDeveloperFeature,
  (state: DeveloperReducer.DataState) => state.detail
);


export const selectFeatureAddressDefault = createSelector(
  selectDeveloperFeature,
  (state: DeveloperReducer.DataState) => state.addressDefault
);
