
import { BaseModel } from '@red/data-access';
import {  Expose, Type } from 'class-transformer';
import { IBusinessUnit } from '../interfaces/business-unit.interface';
import { BusinessUnitMetaData } from './business-unit-metadata.model';
import { ConfigSettingModel } from './config-setting.model';



export class BusinessUnitModel extends BaseModel implements IBusinessUnit {

    @Expose()
  id!: string;
    @Expose()
  name!: string;
    @Expose()
  status!: string;
    @Expose()
  logo!: string;
    @Expose()
  addressLine1!: string;
    @Expose()
  addressLine2!: string;
    @Expose()
  city!: string;
    @Expose()
  country!: string;
    @Expose()
  state!: string;
    @Expose()
  zipCode!: string;
    @Expose()
  language!: string;
    @Expose()
  currencyCode!: string;
    @Expose()
  isActive!: boolean;
    @Expose()
  timeZone!: string;
    @Expose()
  createdBy!: number;
    @Expose()
  organizationId!: number;
    @Expose()
  @Type(() => BusinessUnitMetaData)
  metadata!: BusinessUnitMetaData;

    @Expose()
  settings!: Array<ConfigSettingModel>;

}
