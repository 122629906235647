import { MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import * as moment from 'moment';
import { Inject, Injectable, Optional } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { AppConstant } from '../../app.constant';

@Injectable()
export class AppMomentDateAdapter extends MomentDateAdapter {
  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
    super(dateLocale);
  }

  override format(date: moment.Moment, displayFormat: string): string {
    if (!date) {
      return '';
    }
    date = this.clone(date);
    if (!this.isValid(date)) {
      throw Error('MomentDateAdapter: Cannot format invalid date.');
    }
    if (displayFormat === 'input') {
      return date.format(AppConstant.FORMAT_DATE);
    } else {
      return date.format(displayFormat);
    }
  }
  override clone(date: moment.Moment): moment.Moment {
    return date.clone().locale(this.locale);
  }
  override parse(value: any, parseFormat: string | string[]): moment.Moment | null {
    return super.parse(value, parseFormat);
  }

  override deserialize(value: any) {
    if (typeof value === 'number') {
      value = moment(value);
    }
    if (typeof value === 'string') {
      const val = new Date(value);
      // return moment({year:val.getFullYear(),month:val.getMonth(),date:val.getDate() })
      value = moment({ year: val.getFullYear(), month: val.getMonth(), date: val.getDate() });
    }
    return super.deserialize(value);
    // console.log('deserialize --->',value)
    // if (typeof value === 'string' || typeof value === 'number') {
    //   const date = new Date(value);
    //   if (moment(date, AppConstant.FORMAT_DATE).isValid()) {
    //     return moment(date, AppConstant.FORMAT_DATE);
    //   } else if (moment(date, AppConstant.FORMAT_TIME_DATE).isValid()) {
    //     return moment(date, AppConstant.FORMAT_TIME_DATE);
    //   } else if (moment(date).isValid()) {
    //     return moment(date);
    //   } else {
    //     return '';
    //   }
    // }
    // if (!value) {
    //   return '';
    // }
    // return value;
  }

  override isValid(date: moment.Moment) {
    return super.isValid(date);
  }
}

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    //dateInput: 'l',
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
    monthYearLabel: 'MMM YYYY',
  },
};
