import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ManagementCommissionPdCreateDto, ManagementCommissionUpdateActivePd } from '@shared/data-access/dto';
import { forkJoin, mapTo, of } from 'rxjs';
import { catchError, mergeMap, switchMap, switchMapTo } from 'rxjs/operators';
import { ManagementCommissionApiService } from '@cms/apis';
import { ManagementCommissionAction } from '../actions';
import { ManagementCommissionSelector } from '../selectors';

@Injectable()
export class ManagementCommissionEffects {
  getItemDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManagementCommissionAction.loadItemDetail),
      mergeMap(({ id }) => this.apiService.get(id)),
      switchMap(item => of(ManagementCommissionAction.setItemDetail({ item })))
    )
  );

  reloadItemDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManagementCommissionAction.reloadItemDetail),
      concatLatestFrom(() => this.store.select(ManagementCommissionSelector.selectManagementCommissionDetail)),
      mergeMap(([_, item]) => {
        if (item) {
          return this.apiService.get(item.id);
        }
        return of(null);
      }),
      switchMap(item => of(ManagementCommissionAction.setItemDetail({ item })))
    )
  );

  submitItemCreating$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManagementCommissionAction.submitItemCreating),
      concatLatestFrom(() => this.store.select(ManagementCommissionSelector.selectItemCreating)),
      mergeMap(([_, payload]) =>
        this.apiService.create(payload).pipe(
          switchMap(res => {
            const { id: managementCommId } = res;
            const { pdOverride, pdManagement, pdReferral, activePdManagement, activePdReferal, activePdOverride } = payload;
            const listCreatePdApi = [...pdOverride, ...pdManagement, ...pdReferral].map(pd => {
              const payloadPd = ManagementCommissionPdCreateDto.fromJson({
                ...pd,
                managementCommId,
              });
              return this.apiService.createPd(payloadPd);
            });
            const listActivePdApi = [{ activePdManagement }, { activePdReferal }, { activePdOverride }].map(activePd => {
              const payloadActivePd = ManagementCommissionUpdateActivePd.fromJson(activePd);
              return this.apiService.setPdState(managementCommId, payloadActivePd);
            });
            return forkJoin([...listCreatePdApi, ...listActivePdApi]).pipe(mapTo(res));
          }),
          switchMapTo(of(ManagementCommissionAction.submitItemCreatingSuccess())),
          catchError(error => of(ManagementCommissionAction.submitItemCreatingFail({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private store: Store, private apiService: ManagementCommissionApiService) {}
}
