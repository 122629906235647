import { Injectable } from '@angular/core';
import { ChequeApiService } from '@cms/apis';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, map } from 'rxjs';
import { ChequeAction } from '../actions';
import { FnWithCompany, WithCompany } from '@cms/backgrounds/switch-company';
import { ChequeBookCreateDto } from '@shared/data-access/dto';

@Injectable()
export class ChequeEffect {
  loadItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ChequeAction.loadItem),
      exhaustMap(action => {
        return this.fetch(action.id).pipe(map(item => ChequeAction.loadItemSuccess({ item })));
      })
    );
  });

  createItems$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ChequeAction.createItems),
      exhaustMap(action => {
        return this.create(action.data).pipe(map(() => ChequeAction.createItemsSuccess({ isSuccess: true })));
      })
    );
  });

  @FnWithCompany()
  fetch(id: number, @WithCompany() query = {}) {
    return this._apiService.get(id, query);
  }

  @FnWithCompany()
  create(@WithCompany() data: ChequeBookCreateDto) {
    return this._apiService.createBook(data);
  }

  constructor(private _apiService: ChequeApiService, private _actions$: Actions, private _store: Store) {}
}
