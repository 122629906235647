import { createFeature, createReducer, on } from '@ngrx/store';
import { GceAmountModel } from '@shared/data-access/models';
import { GceAmountAction } from '../actions';

export const featureName = 'gceAmount';

export interface GceAmountState {
  item: GceAmountModel | null;
}

export const initialState: GceAmountState = {
  item: null,
};

export const gceAmountFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(GceAmountAction.loadItemSuccess, (state, { item }) => ({ ...state, item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = gceAmountFeature;
