import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ScommMatrixTypeModel } from '@shared/data-access/models';
import { filter, Observable, take } from 'rxjs';
import { CommMatrixTypeAction } from '../actions';
import { CommMatrixTypeReducer } from '../reducers';
import { CommMatrixTypeSelector } from '../selectors';

@Injectable()
export class CommMatrixTypeFacade {
  selectedItem$ = this._store.select(CommMatrixTypeSelector.selectCommMatrixType);
  constructor(private _store: Store<CommMatrixTypeReducer.CommMatrixTypeState>) { }

  /**
   * Get @CommMatrixTypeModel by id
   */
  getById(id: number): Observable<ScommMatrixTypeModel | null> {
    this._store.dispatch(CommMatrixTypeAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<ScommMatrixTypeModel | null>;
  }
}
