import { createFeature, createReducer, on } from '@ngrx/store';
import { ReferenceConfigModel } from '@shared/data-access/models';
import { SwitchCompanyLoaderAction } from '../actions';

export const featureName = 'witchCompanyLoader';

export interface DataState {
  companySelected: ReferenceConfigModel | null;
}
export const initialState: DataState = {
  companySelected: null,
};
export const switchCompanyLoaderFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(SwitchCompanyLoaderAction.getCompanySelected, (state, prop: { companySelected: ReferenceConfigModel }) => ({
      ...state,
      companySelected: prop.companySelected,
    }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = switchCompanyLoaderFeature;
