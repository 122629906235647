import { environment } from '../environments/environment';

export class AppConstant {
  static APP_BASE_URL = environment.baseUrl;
  static API_HOST = environment.apiHost;
  static API_URL_PREFIX = environment.apiPrefix;
  static APP_PRODUCTION = environment.production;
  static APP_STORAGE_DRIVE = environment.storageDrive;
  static APP_ID = environment.appId;
  static ENV_NAME = environment.name;
  static DEFAULT_TIMEZONE = environment.defaultTimeZone;

  static DEFAULT_SETTINGS = {
    LANGUAGE: 'en',
    DEBOUNCE_TIME: 500,
    FILTER_DEBOUNCE_TIME: 1500,
    REQUEST_TIMEOUT: 90000,
  };

  static GLOBAL_STORAGE = {
    TOKEN: 'token',
    REFRESH_TOKEN: 'refresh_token',
    USER: 'user',
    GROUPS_PERMISSIONS: 'groups_permissions',
    ORGANIZATION: 'organization',
    BUSINESS_UNITS: 'business_units',
    BUSINESS_UNIT: 'business_unit',
    LOGIN_STATUS: 'login_status',
    HAS_NEW_USER_LOGIN: 'has_new_user_login',
  };

  static LOCAL_STORAGE_KEYS = {
    LAYOUT_STATE: 'layout_state',
    TOKEN: 'token',
    REFRESH_TOKEN: 'refresh_token',
    USER_INFO: 'user_info',
    LOGIN_STATUS: 'login_status',
  };

  static SESSION_STORAGE_KEYS = {};

  static SCREEN_WIDTH = {
    MOBILE: 600,
    TABLET: 1280,
  };

  static FIELD_VALIDATION = {
    NAME_LENGTH: 120,
    // tslint:disable-next-line:max-line-length
    // eslint-disable-next-line no-useless-escape
    // EMAIL_REGEXP:
    //   /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    EMAIL_REGEXP: /[a-zA-Z\-0-9]/,
    EMAIL_LENGTH: 120,
    PASSWORD_MIN_LENGTH: 6,
    DESCRIPTION_LENGTH: 512,
    DIMENSION_LENGTH: 255,
    SKU_LENGTH: 128,
    // URL: /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/,
    URL: /[a-zA-Z\-0-9]/,
    PHONE: /^-?[0-9]+(\.[0-9]*){0,1}$/g,
    NRICFIN_REGEXP: /^[STFGstfg]\d{7}[Cc]$/,
    ADDRESS_LENGTH: 240,
    PHONE_LENGTH: 20,
    NRIC_NUMBER_LENGTH: 20,
    PASSPORT_LENGTH: 30,
    WEBSITE_LENGTH: 240,
    ADDRESS_CHILD_LENGTH: 50,
    ADDRESS_STREET_LENGTH: 120,
    ZIP_CODE_LENGTH: 20,
    CATEGORY_LENGTH: 50,
    ITEM_NAME_LENGTH: 240,
  };

  static VIEW_MODE_OPTIONS = {
    list: 'list',
    grid: 'grid',
    detail: 'detail',
  };

  static CUSTOMER_TYPE = {
    COMPANY: 'company',
    INDIVIDUAL: 'individual',
  };

  static FORMAT_DATE_STRING = {
    SHORT_DATE: 'DD MMM, YYYY',
  };

  static MAX_PRICE_INPUT = 999999999;
  static MAX_QUANTITY_INPUT = 999999;

  // Xin hoi a Minh truoc khi them format moi
  static FORMAT_DATE = 'DD/MM/YYYY';
  static FORMAT_DATE_4 = 'DD MMM YYYY';
  static FORMAT_TIME = 'hh:mm A';
  static FORMAT_DATE_TIME = 'DD MMM YYYY LT';

  static CURRENCY = '$';
  static CURRENCY_SINGAPORE = 'S$';
  static HTTP_ERROR = {
    HTTP_STATUS_CODE_EXCLUDE: 'http-status-code-exclude',
  };
  static HOME_PAGE_URL = 'https://realestatedoc.co/';

  static IS_SHOW_WHAT_NEW_POPUP = true;
  static AUTOSAVE_INTERVAL_TIME = 5000;

  static ROUTE = {
    LOGIN: 'login',
    LOGOUT: 'logout',
    FORGOT_ACCOUNT: 'forgot-account',
    RESET_PASSWORD: 'reset-password',

    APPLICATION: 'application',
    PUBLIC: 'public',
    CREATE: 'create',
    EDIT: 'edit',

    // personal verification
    PERSONAL_VERIFICATION: 'personal-verification',
    PERSONAL_VERIFICATION_INFO: 'personal-info',
    PERSONAL_VERIFICATION_BANK: 'bank-info',

    // gce opening amount
    GCE_OPENING_AMOUNT: 'gce-opening-amount',

    // ir8a opening amount
    IR8A_OPENING_AMOUNT: 'ir8a-opening-amount',

    // recruitment
    RECRUITMENT: 'manage-recruitments',
    MY_RECRUITMENT: 'my-recruitment',
    RECRUITMENT_LIST: 'list',
    RECRUITMENT_TEAM: 'team',
    RECRUITMENT_APPROVED: 'approved',
    RECRUITMENT_AGREEMENT: 'agreement',
    RECRUITMENT_VERIFICATION: 'verify-recruitment',

    // personalparticular
    PERSONAL_PARTICULAR: 'personal-particular',
    PERSONAL_PARTICULARS: 'personal-particulars',
    PERSONAL_PARTICULAR_UPLOAD_DOCUMENT: 'upload-document',

    // career progression
    CAREER_PROGRESSION: 'career-progression',
    CAREER_PROGRESSIONS: 'career-progressions',
    // additional information
    ADDITIONAL_INFORMATION: 'additional-information',
    ADDITIONAL_INFORMATIONS: 'additional-informations',
    // additional information
    EDUCATION_RECORD: 'education-record',
    EDUCATION_RECORDS: 'education-records',
    // Employment History
    EMPLOYMENT_HISTORY: 'employment-history',
    EMPLOYMENT_HISTORIES: 'employment-histories',
    TEAM_ORG_CHART: 'team-org-chart',
    // Transfer/ Emplacement History
    TRANSFER_EMPLACEMENT_HISTORY: 'transfer-emplacement-history',

    // Promotion
    DESIGNATION: 'designation',
    COMMISSION_SCHEME: 'commission-scheme',
    COMMISSION_SALESPERSON_SCHEME: 'salesperson',
    COMMISSION_LEADERSHIP_SCHEME: 'leadership',
    PROFIT_SHARING: 'multi-tier-sharing',
    PROMOTION_APPROVAL: 'promotion-approval',
    PROMOTION_APPROVAL_LIST: 'list',
    PROMOTION_APPROVAL_HISTORY: 'history',

    // ecdd
    ECDD: 'ecdd',
    ECDD_LIST: 'list',
    ECDD_VERIFICATION_LIST: 'verification-list',

    // Transaction
    PROJECT_ADMIN: 'project-admin',
    PROJECT_SETUP: 'project-setup',
    PROJECT_GENERAL_INFO: 'project-info',
    PROJECT_UNIT: 'units',
    PROJECT_KEY_APPOINTMENTS: 'key-appointments',
    PROJECT_TEAMS: 'teams',
    PROJECT_COMMISSIONS: 'commissions',
    PROJECT_HI: 'project-hi',
    DEVELOPERS: 'developers',
    DEVELOPER_GENERAL_INFO: 'general-info',
    DEVELOPER_ADDRESS: 'address',
    DEVELOPER_CONTACT_PERSON: 'contact-person',
    // project commission matrix
    PROJECT_COMMISSION_MATRIX: 'commission-matrix',
    PROJECT_COMMISSION_MATRIX_VERIFICATION: 'commission-matrix-verification',
    PROJECT_COMMISSION_MATRIX_HA: 'ha',
    PROJECT_COMMISSION_MATRIX_HI: 'hi',
    PROJECT_COMMISSION_MATRIX_HI_GENERAL: 'general',
    PROJECT_COMMISSION_MATRIX_HI_BD_TEAM: 'bd-team',
    PROJECT_COMMISSION_MATRIX_GENERAL_INFO: 'general-info',
    PROJECT_COMMISSION_MATRIX_AGENT_INFO: 'agent-info',
    PROJECT_COMMISSION_MATRIX_COMPANY_INFO: 'company-info',
    // Overriding Distribution
    OVERRIDING_DISTRIBUTIONS: 'overriding-distributions',
    // bonus Distribution
    BONUS_DISTRIBUTIONS: 'bonus-distributions',
    // appointment type
    APPOINTMENT_TYPES: 'appointment-types',
    // management commission
    MANAGEMENT_COMMISSION: 'management-commission',
    MANAGEMENT_COMMISSION_GENERAL: 'general-info',
    MANAGEMENT_COMMISSION_PD_OVERRIDE: 'pd-override',
    MANAGEMENT_COMMISSION_PD_REFERRAL: 'pd-referral',
    MANAGEMENT_COMMISSION_PD_MANAGEMENT: 'pd-management',
    MANAGEMENT_COMMISSION_APPROVAL_STATUS: 'approval-status',
    // blank form
    BLANK_FORM: 'blank-form',
    BLANK_FORM_GENERAL: 'general-info',
    BLANK_FORM_HI: 'hi',
    BLANK_FORM_CO_BROKE: 'co-broke',
    BLANK_FORM_GROUP_LEADER: 'group-leader',
    BLANK_FORM_IC_BONUS: 'ic-bonus',
    BLANK_FORM_IC_COMM: 'ic-comm',
    BLANK_FORM_POOL: 'pool',
    BLANK_FORM_PDS: 'pds',
    BLANK_FORM_OTHERS: 'others',
    BLANK_FORM_BUYERS: 'buyers',
    BLANK_FORM_SUMMARY: 'summary',
    BLANK_FORM_SUMMARY_HI: 'hi-summary',

    BLANK_FORM_VERIFICATION: 'blank-form-verification',
    PROJECT_ADJUSTMENT_VERIFICATION: 'project-adjustment-verification',
    BLANK_FORM_APPROVAL: 'blank-form-approval',
    PROJECT_ADJUSTMENT_APPROVAL: 'project-adjustment-approval',
    BLANK_FORM_INVOICE: 'blank-form-invoice',
    BLANK_FORM_BATCHING_APPROVAL: 'blank-form-batching-approval',

    KEY_APPOINTMENT_LIST: 'key-appointment-list',

    // project consultants
    PROJECT_CONSULTANTS: 'project-consultants',
    // project transactions
    PROJECT_TRANSACTIONS: 'project-transactions',
    PROJECT_TRANSACTION_GENERAL_INFO: 'general-info',
    PROJECT_TRANSACTION_HI_INFO: 'hi',
    PROJECT_TRANSACTION_COBROKE_INFO: 'co-broke',
    PROJECT_TRANSACTION_LEADER_INFO: 'group-leader',
    PROJECT_TRANSACTION_BONUS_INFO: 'bonus',
    PROJECT_TRANSACTION_OVERRIDING_INFO: 'commission',
    PROJECT_TRANSACTION_POOL_INFO: 'pool',
    PROJECT_TRANSACTION_MANAGEMENT_COMM_INFO: 'management-commission',
    PROJECT_TRANSACTION_OTHER_FEE_INFO: 'other-fee',
    PROJECT_TRANSACTION_BUYER_INFO: 'buyer',
    PROJECT_TRANSACTION_SUMMARY_INFO: 'summary',
    PROJECT_TRANSACTION_SUMMARY_HI_INFO: 'hi-summary',
    // general ledger
    GENERAL_LEDGER: 'general-ledger',
    GENERAL_LEDGER_CHART_OF_ACCOUNT: 'charts-of-accounts',
    GENERAL_LEDGER_JOURNAL_ENTRIES: 'journal-entries',
    GENERAL_LEDGER_CHART_OF_LOCAL_ACCOUNT: 'local',
    GENERAL_LEDGER_CHART_OF_MASTER_ACCOUNT: 'master',
    GENERAL_LEDGER_PROFIT_CENTES: 'profit-centes',
    GENERAL_LEDGER_ACCOUNT_SETUP: 'account-setup',
    GENERAL_LEDGER_ACCOUNTING_PERIODS: 'accounting-periods',
    GENERAL_LEDGER_ACCOUNT_SETUP_CONTROL_ACCOUNT: 'control-account',
    GENERAL_LEDGER_ACCOUNT_SETUP_DEFAULT_ACCOUNT: 'default-account',
    GENERAL_LEDGER_ACCOUNT_SETUP_BANK_ACCOUNT: 'bank-account',
    GENERAL_LEDGER_ACCOUNT_SETUP_CASH_ACCOUNT: 'cash-account',
    GENERAL_LEDGER_ACCOUNT_SETUP_PROJECT_ACCOUNT: 'project-account',
    GENERAL_LEDGER_ACCOUNT_SETUP_RESALE_ACCOUNT: 'resale-account',

    // customer
    CUSTOMER: 'customer',
    CUSTOMER_GENERAL: 'general',
    CUSTOMER_ADDRESS: 'address',
    CUSTOMER_CONTACT_PERSON: 'contact-person',
    // supplier
    SUPPLIER: 'supplier',
    SUPPLIER_LISTING: 'supplier-listing',
    //account receivable
    ACCOUNT_RECEIVABLES: 'account-receivable',
    ACCOUNT_PAYABLES: 'account-payable',
    TAX_INVOICE: 'tax-invoice',
    CREDIT_NOTE: 'credit-note',
    DEBIT_NOTE: 'debit-note',
    BANK_ACCOUNT: 'bank-account',
    RECEIPT: 'receipt',
    PAYMENT: 'payment',
    PAYMENT_GENERATOR: 'payment-generator',
    PAYMENT_REVERSAL: 'payment-reversal',
    REVERSAL_MANAGER: 'reversal-manager',
    DIRECT_DEBIT_PAYMENT: 'direct-debit-payment',
    CHEQUE: 'cheque-book',
    // commission matrix
    COMMISSION_MATRIX: 'commission-matrix',
    COMMISSION_MATRIX_GENERAL_INFO: 'general-info',
    COMMISSION_MATRIX_AGENT_INFO: 'agent-info',
    COMMISSION_MATRIX_COMPANY_INFO: 'company-info',
    // project transactions verification
    PROJECT_TRANSACTION_VERIFICATIONS: 'project-transaction-verifications',
    // project transactions approval
    PROJECT_TRANSACTION_APPROVAL: 'project-transaction-approval',
    // project transactions pending invoice
    PROJECT_TRANSACTION_PENDING_INVOICE: 'project-transaction-invoice-verifications',
    // batching approval
    BATCHING_APPROVAL: 'batching-approval',
    BATCHING_APPROVAL_GENERAL_INFO: 'general',
    BATCHING_APPROVAL_TRANSACTION: 'transaction-selection',
    BATCHING_APPROVAL_COMPUTATION: 'computation',
    BATCHING_APPROVAL_BLANK_FORM: 'blank-form-selection',

    // Access control
    ACCESS_CONTROL: 'access',
    USER_ACCOUNTS: 'user-accounts',
    USER_GROUPS: 'user-groups',
    USER_ROLES: 'user-roles',

    DATA_SETUP: 'data-setup',
    JOURNAL_DETAILS: 'jurnal-detail',
    RECURRING_SCHEDULE: 'recurring-schedule',

    DISCIPLINE: 'discipline',
    CONTACT_CATEGORY: 'contact-category',
    ID_TYPE: 'id-type',
    SCHEME_TYPE: 'scheme-type',
    COMMMATRIX_TYPE: 'comm-matrix-type',
    TRANSACTION_TYPE: 'transaction-type',
    COUNTRY: 'country',
    QUALIFICATION: 'qualification',
    NATIONALITY: 'nationality',
    DIALECT: 'dialect',
    BANK: 'bank',
    DEPARTMENT: 'department',
    GST_RATE: 'gst-rate',
    CURRENCY: 'currency',
    CURRENCY_RATE: 'currency-rate',
    LOOKUP_TABLES: 'lookup-tables',

    DATA_IMPORT: 'data-import',
    IMPORT_CONTACT: 'contact',
    IMPORT_ACCOUNT: 'chart-of-accounts',
    IMPORT_RECEIPT: 'receipt',
    IMPORT_PAYMENT: 'payment',
    IMPORT_JOURNAL_ENTRY: 'journal-entry',
    IMPORT_SUPPLIER_INVOICE: 'supplier-invoice',
    IMPORT_AR: 'account-receivables',

    // Resale Admin
    RESALE_ADMIN: 'resale-admin',
    RESALE_TRANSACTIONS: 'transactions',
    RESALE_PROPERTY: 'property',
    RESALE_BILLING_INFO: 'billing-info',
    RESALE_EXTERNAL: 'external',
    RESALE_HUTTONS_AGENTS: 'huttons-agents',
    RESALE_UPLOAD_DOCUMENT: 'upload-document',
    RESALE_SUMMARY: 'summary',
    RESALE_SUBMISSION: 'submission',
    RESALE_VERIFICATION: 'verification',
    RESALE_INVOICE_INTERFACE: 'invoice-interface',
    RESALE_INVOICE_DELIVERY: 'invoice-delivery',
    RESALE_ADJUSTMENT: 'resale-adjustment',
    RESALE_ADJUSTMENT_PROPERTY: 'property',
    RESALE_ADJUSTMENT_EXTERNAL: 'external',
    RESALE_ADJUSTMENT_HUTTONS_AGENTS: 'huttons-agents',
    RESALE_ADJUSTMENT_APPROVAL: 'resale-adjustment-approval',
    // Project Report

    PROJECT_REPORT: 'project-report',
    RESALE_REPORT: 'resale-report',
    CUSTOMER_REPORT: 'customer-report',
    SUPPLIER_REPORT: 'supplier-report',
    BANK_REPORT: 'banking-report',
    FINANCE_REPORT: 'finance-report',
    AGENT_GCE_REPORT: 'agent-gce-report',
    HR_REPORT: 'hr-report',
    AWARDS_REPORT: 'awards-report',
    REPORTS: 'reports',
    COMMON: 'common',

    // Search engine
    SEARCH_ENGINE: 'search-engine',
    SEARCH_ENGINE_FINANCE: 'search-engine-finance',
    PROJECT_SEARCH_ENGINE: 'project-search-engine',
    BATCH_SEARCH_ENGINE: 'batch-search-engine',
    RESALE_SEARCH_ENGINE: 'resale-search-engine',

    // Fixed Asset
    FIXED_ASSET: 'fixed-asset',
    ASSET_CATEGORY: 'asset-category',
    ASSET_REGISTER: 'asset-register',
    ASSET_DEPRECIATION: 'asset-depreciation',
    ASSET_DISPOSALS: 'asset-disposals',

    INVOICE_WRITEOFF: 'invoice-writeoff',

    //Message Outbox
    MESSAGE_OUTBOX: 'message-outbox',
  };

  static NGX_MASK_REGEXP = {
    TEXT: /[a-zA-Z\s]/,
    EMAIL: /[a-zA-Z0-9\\.\\_\\-\\@]/,
    // NON_EMOJI: /[\x00-\x7F]+\ *(?:[\x00-\x7F]|)/,
    NORMAL_TEXT: /[a-zA-Z0-9\s\.\,\-]/,
    NORMAL_TEXT_TRIM: /[a-zA-Z0-9\.\,\-]/,
    NORMAL_TEXT_2: /[a-zA-Z0-9\s\.\,\-\_\/\<\>\?\;\:\'\"\[\{\]\}\(\)\\\|\!\@\#\$\%\`\~]/,
    NORMAL_TEXT_TRIM_2: /[a-zA-Z0-9\.\,\-\_\/\<\>\?\;\:\'\"\[\{\]\}\(\)\\\|\!\@\#\$\%\`\~]/,
    TEXT_NUMBER: /[a-zA-Z0-9\s]/,
  };

  static NGX_MASK_CUSTOM_PATTERN = {
    A: { pattern: new RegExp(AppConstant.NGX_MASK_REGEXP.NORMAL_TEXT) },
    T: { pattern: new RegExp(AppConstant.NGX_MASK_REGEXP.NORMAL_TEXT_TRIM) },
  };

  static SQFT_TO_SQM = 10.7639;

  static defaultValue = '-';

  static toastMessagesDuration = 2000;

  static countryCodeDefault = '65';

  static pageSize = 10;

  static BROADCAST_CHANNEL = {};
}
