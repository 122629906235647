import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Helper } from '@red/utils';
import { EClientType, EResalePropertyType, EResaleType } from '@shared/data-access/enums';
import { ResaleTransactionReducer } from '../reducers';

export const selectResaleTransactionFeature = createFeatureSelector<ResaleTransactionReducer.ResaleTransactionState>(ResaleTransactionReducer.featureName);

export const selectItemDetail = createSelector(selectResaleTransactionFeature, ({ item }) => item);

export const selectResaleTransactionId = createSelector(selectResaleTransactionFeature, ({ item }) => item?.id);

export const selectDraftItem = createSelector(selectResaleTransactionFeature, ({ draftItem }) => draftItem);

export const selectRelatedAgentDraftItem = createSelector(selectDraftItem, ({ relatedAgent }) => relatedAgent);

export const selectExternalsDraftItem = createSelector(selectRelatedAgentDraftItem, ({ externals }) => externals);

export const selectDraftType = createSelector(selectResaleTransactionFeature, ({ draftType }) => draftType);

export const selectDraftState = createSelector(selectResaleTransactionFeature, ({ draftItem, draftType }) => ({
  draftItem,
  draftType,
}));

export const selectSubmittingStatus = createSelector(selectResaleTransactionFeature, ({ submittingStatus }) => submittingStatus);

export const selectTransactionAmountDraftItem = createSelector(selectDraftItem, ({ billing }) => billing?.transactionAmount as number | null);

export const selectTotalExternalsReceivedPercentDraftItem = createSelector(selectExternalsDraftItem, externals =>
  externals.reduce((total, item) => Helper.round(total + (item?.receivedPercent ?? 0), 4), 0)
);

export const selectTotalExternalsReceivedValueDraftItem = createSelector(selectExternalsDraftItem, externals =>
  externals.reduce((total, item) => Helper.round(total + (item?.grossComm?.subTotalAmount ?? item?.receivedValue ?? 0), 2), 0)
);

export const selectBillingValueDraftItem = createSelector(selectDraftItem, ({ billing }) => billing?.grossComm?.subTotalAmount ?? 0);

export const selectResaleTypeDraftItem = createSelector(selectDraftItem, ({ resaleType }) => resaleType ?? EResaleType.resale);

export const selectPropertyTypeDraftItem = createSelector(selectDraftItem, ({ propertyType }) => propertyType ?? EResalePropertyType.ResidentialCondominiumApartment);

export const selectIsWithPaymentDraftItem = createSelector(selectDraftItem, ({ metadata }) => metadata?.isWithPayment ?? false);

export const selectIsCobrokeDraftItem = createSelector(selectDraftItem, ({ metadata }) => metadata?.isCobroke ?? false);

export const selectIsInternal = createSelector(selectResaleTransactionFeature, ({ isInternal }) => isInternal);

export const selectIsExternal = createSelector(selectResaleTransactionFeature, ({ isExternal }) => isExternal);

export const selectIsHaveCobrokeDraftItem = createSelector(selectIsInternal, selectIsExternal, (isInternal, isExternal) => isInternal || isExternal);

export const selectetBilling = createSelector(selectDraftItem, ({ billing }) => billing);

export const selectRelatedAgent = createSelector(selectDraftItem, ({ relatedAgent }) => relatedAgent);

export const selectExternals = createSelector(selectRelatedAgent, ({ externals }) => externals);

export const selectExternalByIndex = (index: number) => createSelector(selectExternals, externals => externals[index]);

export const selectInternals = createSelector(selectRelatedAgent, ({ internals }) => internals);

export const selectInternalByIndex = (index: number) => createSelector(selectInternals, internals => internals[index]);

export const selectMains = createSelector(selectRelatedAgent, ({ mains }) => mains);

export const selectMainByIndex = (index: number) => createSelector(selectMains, mains => mains[index]);

export const selectDocumentsDraftItem = createSelector(selectDraftItem, ({ documents }) => documents);

export const selectClientTypeDraftItem = createSelector(selectDraftItem, ({ billing: { clientType } }) => clientType ?? EClientType.seller);

export const selectIsCloneDuplicated = createSelector(selectResaleTransactionFeature, ({ isCloneDuplicated }) => isCloneDuplicated);

export const selectOptionDate = createSelector(selectResaleTransactionFeature, ({ optionDate }) => optionDate);

export const selectMetadata = createSelector(selectResaleTransactionFeature, ({ metadata }) => metadata);
