import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SalespersonAdditionalInfoReducer } from "../reducers";

export const selectSalespersonAdditionalInfoFeature = createFeatureSelector<SalespersonAdditionalInfoReducer.SalespersonAdditionalInfoState>(
  SalespersonAdditionalInfoReducer.featureName
);

export const selectFeatureSalespersonAdditionalInfo = createSelector(
  selectSalespersonAdditionalInfoFeature,
  (state: SalespersonAdditionalInfoReducer.SalespersonAdditionalInfoState) => state.items
);
