import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { EnhancedNumberPipe } from './enhanced-number.pipe';

export const DEFAULT_NUMBER_FORMAT = new InjectionToken<string>('DEFAULT_NUMBER_FORMAT');

@NgModule({
  imports: [CommonModule],
  declarations: [EnhancedNumberPipe],
  exports: [EnhancedNumberPipe],
  providers: [
    {
      provide: DecimalPipe,
      useClass: EnhancedNumberPipe,
    },
  ],
})
export class EnhancedNumberModule {
  static forRoot(configs: { defaultNumberFormat?: string }): ModuleWithProviders<EnhancedNumberModule> {
    return {
      ngModule: EnhancedNumberModule,
      providers: [
        {
          provide: DEFAULT_NUMBER_FORMAT,
          useValue: configs?.defaultNumberFormat,
        },
      ],
    };
  }
}
