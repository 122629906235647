import { createAction, props } from '@ngrx/store';
import { SupplierReportModel } from '@shared/data-access/models';


export const getDetail = createAction(
  '[PROJECT TRANSACTION] GET DETAIL',
  props<{item:SupplierReportModel}>()
);


