import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { ProjectTransactionApiService } from '@cms/apis';
import { ProjectTransactionAction } from '../actions';

@Injectable()
export class ProjectTransactionEffects {
  getSummary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectTransactionAction.getSummary),
      mergeMap(action =>
        this.projectTransactionApiService.getSummary(action.transactionId, action.filters).pipe(
          map(data => ProjectTransactionAction.getSummarySuccess({ summary: data })),
          catchError(error => of(ProjectTransactionAction.getSummaryFailure({ error })))
        )
      )
    );
  });
  constructor(private actions$: Actions, private projectTransactionApiService: ProjectTransactionApiService) {}
}
