import { createFeatureSelector, createSelector } from "@ngrx/store";
import { OverridingDistributionReducer } from "../reducers";

export const selectOverridingDistributionFeature = createFeatureSelector<OverridingDistributionReducer.DataState>(
  OverridingDistributionReducer.featureName
);

export const selectFeatureOverridingDistribution = createSelector(
  selectOverridingDistributionFeature,
  (state: OverridingDistributionReducer.DataState) => state.item
);

