import { createAction, props } from '@ngrx/store';
import { ResaleDocumentModel, ResaleExternalCoBrokeModel, ResaleTransactionModel } from '@shared/data-access/models';
import { EResaleType } from '@shared/data-access/enums';
import { IResaleBilling } from '@shared/data-access/interfaces';

export const setItemDetail = createAction('[Resale Transaction] SET ITEM DETAIL', props<{ item: ResaleTransactionModel | null }>());

export const loadItemDetail = createAction('[Resale Transaction] LOAD ITEM DETAIL', props<{ id: number }>());

export const updateItemDetail = createAction('[Resale Transaction] UPDATE ITEM DETAIL', props<{ data: Partial<ResaleTransactionModel> }>());

export const setDraftItemAsCreate = createAction('[Resale Transaction] SET DRAFT ITEM AS CREATE');

export const setDraftItemAsEdit = createAction('[Resale Transaction] SET DRAFT ITEM AS EDIT');

export const updateDraftItem = createAction('[Resale Transaction] UPDATE DRAFT ITEM', props<{ data: Partial<ResaleTransactionModel> }>());

export const updateResaleTypeDraftItem = createAction('[Resale Transaction] UPDATE RESALE TYPE DRAFT ITEM', props<{ resaleType: EResaleType }>());

export const updateTransactionAmountDraftItem = createAction(
  '[Resale Transaction] UPDATE TRANSACTION AMOUNT DRAFT ITEM',
  props<{ transactionAmount: number | null }>()
);

export const updateBillingValueDraftItem = createAction('[Resale Transaction] UPDATE BILLING VALUE DRAFT ITEM', props<{ billingValue: number | null }>());

export const updateBillingDraftItem = createAction('[Resale Transaction] UPDATE BILLING DRAFT ITEM', props<{ data: Partial<IResaleBilling> }>());

export const addExternalDraftItem = createAction('[Resale Transaction] ADD EXTERNAL DRAFT ITEM', props<{ item: ResaleExternalCoBrokeModel }>());

export const updateExternalDraftItem = createAction('[Resale Transaction] UPDATE EXTERNAL DRAFT ITEM', props<{ index: number; item: ResaleExternalCoBrokeModel }>());

export const deleteExternalDraftItem = createAction('[Resale Transaction] DELETE EXTERNAL DRAFT ITEM', props<{ index: number }>());

export const addDocumentsDraftItem = createAction('[Resale Transaction] ADD DOCUMENTS DRAFT ITEM', props<{ items: ResaleDocumentModel[] }>());

export const deleteDocumentDraftItem = createAction('[Resale Transaction] DELETE DOCUMENT DRAFT ITEM', props<{ index: number }>());

export const saveDraftItem = createAction('[Resale Transaction] SAVE DRAFT ITEM');

export const saveAndSubmitDraftItem = createAction('[Resale Transaction] SAVE AND SUBMIT DRAFT ITEM');

export const submitItemSuccess = createAction('[Resale Transaction] SUBMIT DRAFT ITEM SUCCESS', props<{ res: any }>());

export const submitItemFail = createAction('[Resale Transaction] SUBMIT DRAFT ITEM FAIL', props<{ error: any }>());

export const resetDraftItem = createAction('[Resale Transaction] RESET DRAFT ITEM');

export const resetSubmittingStatus = createAction('[Resale Transaction] RESET SUBMITTING STATUS');
