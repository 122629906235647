import { createFeature, createReducer, on } from '@ngrx/store';
import { InvoiceWriteoffModel } from '@shared/data-access/models';
import { InvoiceWriteoffAction } from '../actions';

export const featureName = 'invoiceWriteoff';

export interface InvoiceWriteoffState {
  item: InvoiceWriteoffModel | null;
}
export const initialState: InvoiceWriteoffState = {
  item: null,
};
export const invoiceWriteoffFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(InvoiceWriteoffAction.loadItemSuccess, (state, prop: { item: InvoiceWriteoffModel }) => ({ ...state, item: prop.item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = invoiceWriteoffFeature;
