import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { PaymentReversalModel } from "@shared/data-access/models";
import { filter, Observable, take } from "rxjs";
import { PaymentReversalAction } from "../actions";
import { PaymentReversalReducer } from "../reducers";
import { PaymentReversalSelector } from "../selectors";

@Injectable()
export class PaymentReversalFacade {
  selectedItem$ = this._store.select(PaymentReversalSelector.selectPaymentReversal);
  constructor(private _store: Store<PaymentReversalReducer.PaymentReversalState>) {}

  /**
   * Get @PaymentReversalModel by id
   */
  getById(id: number): Observable<PaymentReversalModel | null> {
    this._store.dispatch(PaymentReversalAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<PaymentReversalModel | null>;
  }
}
