import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContactCustomerOrSupplierReducer } from '../reducers';

export const contactFeature = createFeatureSelector<ContactCustomerOrSupplierReducer.DataState>(ContactCustomerOrSupplierReducer.contactName);

export const selectFeatureCustomer = createSelector(contactFeature, (state: ContactCustomerOrSupplierReducer.DataState) => {
  return state.item;
});

export const selectFeatureCreateOrUpdate = createSelector(contactFeature, (state: ContactCustomerOrSupplierReducer.DataState) => state.item);
