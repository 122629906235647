import { createFeature, createReducer, on } from '@ngrx/store';
import { FinanceReportsModel } from '@shared/data-access/models';
import { FinanceReportsAction } from '../actions';

export const featureName = 'financeReports';

export interface FinanceReportsState {
  item: FinanceReportsModel | null;
}
export const initialState: FinanceReportsState = {
  item: null,
};
export const financeReportsFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(FinanceReportsAction.loadItemSuccess, (state, prop: { item: FinanceReportsModel }) => ({ ...state, item: prop.item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = financeReportsFeature;
