// core
import { createFeature, createReducer, on } from '@ngrx/store';

// share
import { Ir8aAmountModel, PersonalParticularModel } from '@shared/data-access/models';
import { Ir8aAmountAction } from '../actions';

export const featureName = 'ir8aAmount';

export interface Ir8aAmountState {
  item: Ir8aAmountModel | null;
  salesperson: PersonalParticularModel | null;
}

export const initialState: Ir8aAmountState = {
  item: null,
  salesperson: null,
};

export const ir8aAmountFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(Ir8aAmountAction.loadItemSuccess, (state, { item }) => ({ ...state, item })),
    on(Ir8aAmountAction.loadSalespersonSuccess, (state, { salesperson }) => ({ ...state, salesperson }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = ir8aAmountFeature;
