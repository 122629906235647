import { createFeature, createReducer, on } from '@ngrx/store';
import { IResaleCoBroke, IResaleExternalCoBroke, IResaleRelatedAgent, ISplitPart } from '@shared/data-access/interfaces';
import { ResaleAdjustmentModel, ResaleCoBrokeModel, ResaleRelatedAgentModel, SplitPartModel } from '@shared/data-access/models';
import { mapValues, merge } from 'lodash-es';
import { ResaleAdjustmentAction } from '../actions';

export const featureName = 'resaleAdjustment';

export interface DataState {
  item: ResaleAdjustmentModel | null;
  draftItem: ResaleAdjustmentModel;
  draftType: 'create' | 'edit';
  submittingStatus: {
    success: any;
    loading: boolean;
    error: any;
  };
  invalidAgents: SplitPartModel[] | null;
}

export const initialState: DataState = {
  item: null,
  draftItem: ResaleAdjustmentModel.createEmpty(),
  draftType: 'create',
  submittingStatus: {
    success: null,
    loading: false,
    error: null,
  },
  invalidAgents: null
};
export const resaleAdjustmentFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(ResaleAdjustmentAction.getDetail, (state, prop: { item: ResaleAdjustmentModel }) => ({
      ...state,
      item: prop.item,
    })),
    on(ResaleAdjustmentAction.setDraftItemAsCreate, state => ({
      ...state,
      draftItem: ResaleAdjustmentModel.createEmpty(),
      draftType: 'create',
    })),
    on(ResaleAdjustmentAction.setDraftItemAsEdit, state => ({
      ...state,
      draftItem: state.item ? ResaleAdjustmentModel.clone(state.item) : ResaleAdjustmentModel.createEmpty(),
      draftType: 'edit',
    })),
    on(ResaleAdjustmentAction.updateDraftItem, (state, { data }) => ({
      ...state,
      draftItem: ResaleAdjustmentModel.merge(state.draftItem, data),
    })),
    on(ResaleAdjustmentAction.saveDraftItem, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: true,
        error: null,
      },
    })),
    on(ResaleAdjustmentAction.saveAndSubmitDraftItem, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: true,
        error: null,
      },
    })),
    on(ResaleAdjustmentAction.submitItemSuccess, (state, { res }) => ({
      ...state,
      submittingStatus: {
        success: res,
        loading: false,
        error: null,
      },
    })),
    on(ResaleAdjustmentAction.submitItemFail, (state, { error }) => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: false,
        error,
      },
    })),
    on(ResaleAdjustmentAction.resetDraftItem, state => ({
      ...state,
      item: null,
      draftItem: ResaleAdjustmentModel.createEmpty(),
      draftType: 'create',
    })),
    on(ResaleAdjustmentAction.resetSubmittingStatus, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: false,
        error: null,
      },
    })),
    on(ResaleAdjustmentAction.pullDataFormResaleTransactionForDraftItem, (state, { data }) => {
      let relatedAgent = ResaleRelatedAgentModel.toJson(data.relatedAgent) as IResaleRelatedAgent;
      relatedAgent = mapValues(relatedAgent, session =>
        session
          .map(agent => {
            const { tiers = [], parties = [] } = agent as IResaleCoBroke;
            return merge(agent, {
              isDefault: true,
              grossComm: {
                amount: 0,
                taxValue: 0,
                taxType: 'number',
                totalAmount: 0,
              },
              tiers: tiers.map(tier => merge(tier, { value: 0 } as Partial<ISplitPart>)),
              parties: parties.map(party => merge(party, { value: 0, percentage: 0 } as Partial<ISplitPart>)),
            } as Partial<IResaleCoBroke | IResaleExternalCoBroke>);
          })
          .filter(agent => (agent as IResaleCoBroke).agentId || (agent as IResaleExternalCoBroke).contact)
      ) as IResaleRelatedAgent;
      return {
        ...state,
        draftItem: ResaleAdjustmentModel.merge(state.draftItem, { relatedAgent }),
      };
    }),
    on(ResaleAdjustmentAction.updateInvalidAgents, (state, { agents }) => ({
      ...state,
      invalidAgents: agents as SplitPartModel[],
    })),
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = resaleAdjustmentFeature;
