import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, PercentPipe } from '@angular/common';
import { EnhancedPercentPipe } from './percent.pipe';

export const MULTIPLIED_BY_100 = new InjectionToken<boolean>('MULTIPLIED_BY_100');
@NgModule({
  declarations: [EnhancedPercentPipe],
  imports: [CommonModule],
  exports: [EnhancedPercentPipe],
  providers: [
    {
      provide: PercentPipe,
      useClass: EnhancedPercentPipe,
    },
  ],
})
export class EnhancedPercentModule {
  static forRoot(configs: { multipliedBy100?: boolean }): ModuleWithProviders<EnhancedPercentModule> {
    const multipliedBy100 = configs?.multipliedBy100 || false;
    return {
      ngModule: EnhancedPercentModule,
      providers: [
        {
          provide: MULTIPLIED_BY_100,
          useValue: multipliedBy100,
        },
      ],
    };
  }
}
