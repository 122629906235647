import { createFeature, createReducer, on } from "@ngrx/store";
import { PaymentReversalModel } from "@shared/data-access/models";
import { PaymentReversalAction } from "../actions";

export const featureName = 'payment-reversal';

export interface PaymentReversalState {
  item: PaymentReversalModel | null;
}

export const initialState: PaymentReversalState = {
  item: null,
}

export const paymentReversalFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(PaymentReversalAction.loadItemSuccess, (state, prop: { item: PaymentReversalModel }) => ({ ...state, item: prop.item }))
  )
})

export const {
  name, // feature name
  reducer, // feature reducer
} = paymentReversalFeature
