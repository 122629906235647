import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CompanyModel } from '@shared/data-access/models';
import { of, switchMap } from 'rxjs';
import { SwitchCompanyLoaderService, SWITCH_COMPANY_API_LOADER } from './switch-company-api-loader';

@Injectable({ providedIn: 'root' })
export class SwitchCompanyResolver implements Resolve<CompanyModel> {
  constructor(@Inject(SWITCH_COMPANY_API_LOADER) private loader: SwitchCompanyLoaderService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const companyId = parseInt(route.queryParamMap.get('companyId') as string);
    if (companyId) {
      return this.loader.getCurrentSelected().pipe(
        switchMap(item => {
          if (item.id === companyId) {
            return of(item);
          }
          return this.loader.updatedSelectedInResolver(companyId);
        })
      );
    }
    return this.loader.getCurrentSelected();
  }
}
