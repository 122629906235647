import { createFeature, createReducer, on } from "@ngrx/store";
import { SupplierReportModel } from "@shared/data-access/models";
import { SupplierReportAction } from "../actions";

export const featureName = 'supplierReport';

export interface DataState {
  item:SupplierReportModel | null;

}
export const initialState:DataState = {
  item: null
};
export const supplierReportFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(SupplierReportAction.getDetail,  (state, prop:{item:SupplierReportModel}) => ({ ...state, item: prop.item})),

  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = supplierReportFeature;
