import { Helper } from '@red/utils';
import { BaseModel, Default } from '@red/data-access';
import {  Expose } from "class-transformer";
import { IUserProfile } from "../interfaces/user-profile.interface";
import { BusinessUnitModel } from './business-unit.model';


export class UserProfileModel extends BaseModel implements IUserProfile {
    @Expose()
  jobTitle!: string;
    @Expose()
  countryCode!: string;
    @Expose()
  phoneNumber!: string;
    @Expose()
  isFirstLogin!: boolean;
    @Expose()
  tourCompleted!: string[];
    @Expose()
  color!: string;

    @Expose()
  @Default(false)
  isWatchedWhatNewPopup!: boolean;

    @Expose()
  registrationNumber!: string;

    @Expose()
  estateAgentName!: string;

    @Expose()
  estateAgentLicenceNumber!: string;
    @Expose()
  estateAgentEmail!: string;
    @Expose()
  estateAgentPhoneNumber!: string;
    @Expose()
  estateAgentCountryCode!: string;
    @Expose()
  estateAgentAddress!: {
    postalCode: string;
    unitNumber: string;
    blockNumber: string;
    streetName: string;
    buildingName: string;
    floorNumber: string;
    country: string;
  };
    @Expose()
  upline1!: string;
    @Expose()
  upline2!: string;

    @Expose()
    get estateAgentFullAddress(): string {
        if (!this.estateAgentAddress) {
            return '';
        }

        return Helper.transformAddressObjectToString(this.estateAgentAddress);
    }

    buildProfileByBUInfo(bu: BusinessUnitModel) {
        if (bu) {
            this.estateAgentName = bu.name;
            this.estateAgentLicenceNumber = bu.metadata?.licenseNumber;
            this.estateAgentPhoneNumber = bu.metadata?.phoneNumber;
            this.estateAgentCountryCode = bu.metadata?.countryCode;

            this.estateAgentAddress = {
                postalCode: bu.metadata?.postalCode,
                unitNumber: bu.metadata?.unitNumber,
                blockNumber: bu.metadata?.blockNumber,
                streetName: bu.metadata?.streetName,
                buildingName: bu.metadata?.buildingName,
                floorNumber: bu.metadata?.floorNumber,
                country: bu.country
            };
        }

        return this;
    }

    @Expose()
    get phone(): string {
        return `+${this.countryCode} ${this.phoneNumber}`;
    }
}
