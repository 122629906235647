import { createFeatureSelector, createSelector } from '@ngrx/store';
import { KeyAppointmentListReducer } from '../reducers';

export const selectKeyAppointmentListFeature = createFeatureSelector<KeyAppointmentListReducer.KeyAppointmentListState>(KeyAppointmentListReducer.featureName);

export const selectKeyAppointmentList = createSelector(selectKeyAppointmentListFeature, (state: KeyAppointmentListReducer.KeyAppointmentListState) => state.item);

export const selectKeyAppointmentListUpdatedAt = createSelector(selectKeyAppointmentListFeature, (state: KeyAppointmentListReducer.KeyAppointmentListState) => {
  return {
    updatedAt: state.updatedAt,
    updatedBy: state.updatedBy,
  };
});
