import { BaseModel, EndOfDate, FormattedDateString, IsoString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class ResaleSearchEngineDto extends BaseModel {
  @Expose()
  code!: string;

  @Expose()
  salespersonNricName!: string;

  @Expose()
  salespersonBusinessName!: string;

  @Expose()
  salespersonCeaRegNo!: string;

  @Expose()
  invoiceCode!: string;

  @Expose()
  ecbInvoiceCode!: string;

  @Expose()
  billingClientName!: string;

  @Expose()
  resaleStatus!: string[];

  @Expose()
  resaleType!: string;

  @Expose()
  propertyType!: string;

  @Expose()
  totalBill!: number;

  @Expose()
  billingAttention!: string;

  @Expose()
  hideLink!: string;

  @Expose()
  address!: string;

  @Expose()
  isExternal!: string;

  @Expose()
  billingEnvDelivery!: string;

  // Date
  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  submissionDateFrom!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  submissionDateTo!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  createdAtFrom!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  createdAtTo!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  reminderDateFrom!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  reminderDateTo!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  optionDateFrom!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  optionDateTo!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  completionDateFrom!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  completionDateTo!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  agreementDateFrom!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  agreementDateTo!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  expireDateFrom!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  expireDateTo!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  billingDateFrom!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  billingDateTo!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  invoiceDateFrom!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  invoiceDateTo!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  commencementDateFrom!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  commencementDateTo!: string;
}
