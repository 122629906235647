import { createFeature, createReducer, on } from "@ngrx/store";
import { CommissionSalespersonSchemeModel } from "@shared/data-access/models";
import { CommSalespersonSchemeAction } from "../actions";

export const featureName = 'commSalespersonScheme';

export interface CommSalespersonSchemeState {
  detail:CommissionSalespersonSchemeModel | null;
}
export const initialState:CommSalespersonSchemeState = {
  detail: null,
};
export const commSalespersonSchemeFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(CommSalespersonSchemeAction.getDetail,  (state, prop) => ({ ...state, detail: prop}))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = commSalespersonSchemeFeature;
