import { createFeature, createReducer, on } from '@ngrx/store';
import { ScommMatrixTypeModel } from '@shared/data-access/models';
import { CommMatrixTypeAction } from '../actions';

export const featureName = 'commMatrixType';

export interface CommMatrixTypeState {
  item: ScommMatrixTypeModel | null;
}
export const initialState: CommMatrixTypeState = {
  item: null,
};
export const commMatrixTypeFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(CommMatrixTypeAction.loadItemSuccess, (state, prop: { item: ScommMatrixTypeModel }) => ({ ...state, item: prop.item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = commMatrixTypeFeature;
