import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResaleAdjustmentReducer } from '../reducers';

export const selectResaleAdjustmentFeature = createFeatureSelector<ResaleAdjustmentReducer.DataState>(ResaleAdjustmentReducer.featureName);

export const selectItem = createSelector(selectResaleAdjustmentFeature, state => state.item);

export const selectDraftItem = createSelector(selectResaleAdjustmentFeature, state => state.draftItem);

export const selectDraftType = createSelector(selectResaleAdjustmentFeature, state => state.draftType);

export const selectDraftState = createSelector(selectResaleAdjustmentFeature, ({ draftItem, draftType }) => ({
  draftItem,
  draftType,
}));

export const selectSubmittingStatus = createSelector(selectResaleAdjustmentFeature, state => state.submittingStatus);

export const selectInvalidAgents = createSelector(selectResaleAdjustmentFeature, state => state.invalidAgents);
