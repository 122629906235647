import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectReportModel } from '@shared/data-access/models';
import { filter, Observable, take } from 'rxjs';
import { ProjectReportAction } from '../actions';
import { ProjectReportReducer } from '../reducers';
import { ProjectReportSelector } from '../selectors';

@Injectable()
export class ProjectReportFacade {
  selectedItem$ = this._store.select(ProjectReportSelector.selectProjectReport);
  constructor(private _store: Store<ProjectReportReducer.ProjectReportState>) {}

  /**
   * Get @ProjectReportModel by id
   */
  getById(id: number): Observable<ProjectReportModel | null> {
    this._store.dispatch(ProjectReportAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val),
      take(1)
    ) as Observable<ProjectReportModel | null>;
  }
}
