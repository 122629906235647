<div class="flex flex-col flex-auto relative">
  <div class="flex flex-col px-6 h-12 bg-background">
    <div class="flex flex-row flex-auto items-center gap-1">
      <span class="text-b2">Company</span>
      <mat-form-field
        class="max-w-105 w-full fuse-mat-no-subscript fuse-mat-rounded-sm fuse-mat-dense fuse-search-box fuse-mat-float-label-always bg-transparent"
        appearance="fill"
        floatLabel="always"
      >
        <mat-select matInput [formControl]="companyCtr" compareWithId (valueChange)="onCompanyChange($event)">
          <mat-select-trigger>
            <span class="font-semibold">{{ companyCtr.value?.name }}</span>
          </mat-select-trigger>
          <mat-option *ngFor="let company of companies$ | async" [value]="company">
            {{ company.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
     Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>
</div>
