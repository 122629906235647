import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BankAccountCreateDto, BankAccountUpdateDto } from '@shared/data-access/dto';
import { AttachmentModel, BankAccountModel } from '@shared/data-access/models';
import { filter, Observable, take } from 'rxjs';
import { BankAccountAction } from '../actions';
import { BankAccountReducer } from '../reducers';
import { BankAccountSelector } from '../selectors';

@Injectable()
export class BankAccountFacade {
  selectedItem$ = this._store.select(BankAccountSelector.selectBankAccount);
  constructor(private _store: Store<BankAccountReducer.BankAccountState>) {}

  /**
   * Get @BankAccountModel by id
   */
  getById(id: number): Observable<BankAccountModel | null> {
    this._store.dispatch(BankAccountAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<BankAccountModel | null>;
  }

  create(data: BankAccountCreateDto): void {
    this._store.dispatch(BankAccountAction.createItem({ data }));
  }

  update(id: number, data: BankAccountUpdateDto): void {
    this._store.dispatch(BankAccountAction.updateItem({ id, data }));
  }
}
