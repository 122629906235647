import { PaginationAdapter } from '@red/data-access';
import { createAction, props } from '@ngrx/store';
import { AssetDisposalModel } from '@shared/data-access/models';
import { IQuerySearch } from '@shared/data-access/interfaces';

// items
export const loadItems = createAction('[ASSET_DISPOSAL] LOAD ITEMS', props<{ filters: IQuerySearch }>());
export const loadItemsSuccess = createAction('[ASSET_DISPOSAL] LOAD ITEMS SUCCESS', props<{ data: PaginationAdapter<AssetDisposalModel> }>());

// item
export const loadItem = createAction('[ASSET_DISPOSAL] LOAD ITEM', props<{ id: number }>());
export const loadItemSuccess = createAction('[ASSET_DISPOSAL] LOAD ITEM SUCCESS', props<{ item: AssetDisposalModel }>());
