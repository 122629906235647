import { createFeature, createReducer, on } from '@ngrx/store';
import { ChequeModel } from '@shared/data-access/models';
import { ChequeAction } from '../actions';

export const featureName = 'cheque';

export interface ChequeState {
  item: ChequeModel | null;
  canCreateBook: boolean;
}
export const initialState: ChequeState = {
  item: null,
  canCreateBook: false,
};
export const chequeFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(ChequeAction.loadItemSuccess, (state, prop: { item: ChequeModel }) => ({ ...state, item: prop.item })),
    on(ChequeAction.createItemsSuccess, (state, prop: { isSuccess: boolean }) => ({ ...state, canCreateBook: prop.isSuccess }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = chequeFeature;
