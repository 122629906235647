import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { filter, map, Observable, take } from 'rxjs';
import { PersonalVerificationInfoAction } from '../actions';
import { PersonalVerificationInfoReducer } from '../reducers';
import { PersonalVerificationInfoSelector } from '../selectors';
import { PersonalParticularModel, PersonalVerifyModel } from '@shared/data-access/models';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class PersonalVerificationInfoFacade {
  selectedItem$ = this._store.select(PersonalVerificationInfoSelector.selectPersonalVerificationInfo);
  list$ = this._store.select(PersonalVerificationInfoSelector.selectPersonalVerificationList);
  constructor(private _store: Store<PersonalVerificationInfoReducer.PersonalVerificationInfoState>) { }

  getById(id: number): Observable<PersonalVerifyModel[] | null> {
    this._store.dispatch(PersonalVerificationInfoAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val),
      take(1)
    );
  }

  updateList(list: PersonalParticularModel[]): void {
    this._store.dispatch(PersonalVerificationInfoAction.updateList({ list }));
  }

  getDetailById(id: number): Observable<PersonalParticularModel | null> {
    return this.list$.pipe(map(val => val?.find(item => item.id === id) ?? null));
  }
}
