import { PaginationAdapter } from '@red/data-access';
import { createAction, props } from '@ngrx/store';
import { IQuerySearch } from '@shared/data-access/interfaces';
import { PersonalParticularModel, PersonalVerifyModel } from '@shared/data-access/models';

// items
export const loadItems = createAction('[BankVerificationInfo] Load items', props<{ filters: IQuerySearch }>());
export const loadItemsSuccess = createAction('[BankVerificationInfo] Load items success', props<{ data: PaginationAdapter<PersonalParticularModel> }>());

// item
export const loadItem = createAction('[BankVerificationInfo] Load item', props<{ id: number }>());
export const loadItemSuccess = createAction('[BankVerificationInfo] Load item success', props<{ items: PersonalVerifyModel[] }>());

// list
export const updateList = createAction('[BankVerificationInfo] Update list', props<{ list: PersonalParticularModel[] }>());
