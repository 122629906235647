import { createFeature, createReducer, on } from "@ngrx/store";
import { ProjectCommissionMatrixModel } from "@shared/data-access/models";
import { ProjectCommissionMatrixAction } from "../actions";

export const featureName = 'projectCommissionMatrix';

export interface DataState {
  item:ProjectCommissionMatrixModel | null;

}
export const initialState:DataState = {
  item: null
};
export const projectCommissionMatrixFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(ProjectCommissionMatrixAction.getDetail,  (state, prop:{item:ProjectCommissionMatrixModel}) => ({ ...state, item: prop.item})),

  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = projectCommissionMatrixFeature;
