import { PaginationAdapter } from '@red/data-access';
import { createAction, props } from '@ngrx/store';
import { AgentReportModel } from '@shared/data-access/models';
import { IQuerySearch } from '@shared/data-access/interfaces';
// items
export const loadItems = createAction('[AwardsReport] Load items', props<{ filters: IQuerySearch }>());
export const loadItemsSuccess = createAction('[AwardsReport] Load items success', props<{ data: PaginationAdapter<AgentReportModel> }>());

// item
export const loadItem = createAction('[AwardsReport] Load item', props<{ id: number }>());
export const loadItemSuccess = createAction('[AwardsReport] Load item success', props<{ item: AgentReportModel }>());
