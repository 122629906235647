import { Inject, Injectable } from "@angular/core";
import { fromPairs, map } from "lodash-es";
import { Observable, ReplaySubject } from "rxjs";
// import * as extractedTailwindConfigStyle from '../../styles/tailwind-config.scss';
import { RED_TAILWIND_EXTRACTED } from "./token";
@Injectable()
export class RedTailwindService
{
    private _tailwindConfig: ReplaySubject<any> = new ReplaySubject<any>(1);

    /**
     * Constructor
     */
    constructor(
      @Inject(RED_TAILWIND_EXTRACTED) extractedTailwindConfigStyle: {[className:string]:string}
    )
    {
        // Prepare the config object
        const config: any = {};

        // Extract the style from the class
        const regexpForClass = /\.red-tailwind-extracted-config\s\{([\s\S]*)\}/g;
        const extractedDefault:RegExpExecArray = regexpForClass.exec(extractedTailwindConfigStyle['default']) as RegExpExecArray;

        // console.log('reles -->',extractedTailwindConfigStyle);
        if(extractedDefault){
          const style = extractedDefault[1].trim();

          // Extract the rules
          const regexp = /(--[\s\S]*?):'([\s\S]*?)';/g;
          let rules = regexp.exec(style);
          // Add to the config
          while ( rules !== null )
          {
              const configArr = /--([\s\S]*?)-/g.exec(rules[1]) || [];
              const configGroup = configArr[1];
              if ( !config[configGroup] )
              {
                  config[configGroup] = {};
              }

              config[configGroup][rules[1].replace(/(--[\s\S]*?-)/g, '')] = rules[2];
              rules = regexp.exec(style);
          }

          // Parse the themes objects
          config.themes = fromPairs(map(config.themes, (value, key) => [key, JSON.parse(value)]));

          // Execute the observable with the config
          this._tailwindConfig.next(config);
        }

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for _tailwindConfig
     */
    get tailwindConfig$(): Observable<any>
    {
        return this._tailwindConfig.asObservable();
    }
}
