import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PhoneNumberReducer } from "../reducers";


export const selectPersonalParticularFeature = createFeatureSelector<PhoneNumberReducer.PhoneNumberState>('phoneNumber');
export const getCountriesFeature = (state:PhoneNumberReducer.PhoneNumberState) => state.countries;
export const getLoadingFeature   = (state:PhoneNumberReducer.PhoneNumberState) => state.loading;


export const selectCountries = createSelector(
  selectPersonalParticularFeature,
  getCountriesFeature
);
