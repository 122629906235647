import { createFeature, createReducer, on } from "@ngrx/store";
import { OverridingDistributionModel } from "@shared/data-access/models";
import { OverridingDistributionAction } from "../actions";

export const featureName = 'overridingDistribution';

export interface DataState {
  item:OverridingDistributionModel | null;

}
export const initialState:DataState = {
  item: null
};
export const overridingDistributionFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(OverridingDistributionAction.getDetail,  (state, prop:{item:OverridingDistributionModel}) => ({ ...state, item: prop.item})),

  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = overridingDistributionFeature;
