import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { InvoiceWriteoffModel } from '@shared/data-access/models';
import { filter, Observable, take } from 'rxjs';
import { InvoiceWriteoffAction } from '../actions';
import { InvoiceWriteoffReducer } from '../reducers';
import { InvoiceWriteoffSelector } from '../selectors';

@Injectable()
export class InvoiceWriteoffFacade {
  selectedItem$ = this._store.select(InvoiceWriteoffSelector.selectInvoiceWriteoff);
  constructor(private _store: Store<InvoiceWriteoffReducer.InvoiceWriteoffState>) {}

  /**
   * Get @InvoiceWriteoffModel by id
   */
  getById(id: number): Observable<InvoiceWriteoffModel | null> {
    this._store.dispatch(InvoiceWriteoffAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<InvoiceWriteoffModel | null>;
  }
}
