import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AssetDepreciationReducer } from '../reducers';

export const selectAssetDepreciationFeature = createFeatureSelector<AssetDepreciationReducer.AssetDepreciationState>(AssetDepreciationReducer.featureName);

export const selectAssetDepreciation = createSelector(selectAssetDepreciationFeature, (state: AssetDepreciationReducer.AssetDepreciationState) => state.item);

export const selectNewAssetDepreciation = createSelector(
  selectAssetDepreciationFeature,
  (state: AssetDepreciationReducer.AssetDepreciationState) => state.newDepreciation
);
