import { Inject, Injectable } from '@angular/core';
import { PRINT_DOM_INSTANCE } from './print-dom.token';
import { PrintDom } from './print-dom';
import { PrintDomCallback } from './print-dom.model';

@Injectable()
export class PrintDomService {
  constructor(@Inject(PRINT_DOM_INSTANCE) private service: PrintDom) {}
  print(el: HTMLElement, styles?: string[], scripts?: string[], callback?: PrintDomCallback): void {
    this.service.print(el, styles, scripts, callback);
  }
  printFromUrl(url: string, callback?: PrintDomCallback) {
    this.service.printURL(url, callback);
  }
  printFromHtmlString(htmlString: string, data?: Record<string, any>, callback?: PrintDomCallback) {
    // const htmlEmbedded = render(htmlString, data);
    this.service.printHtmlString(htmlString, callback);
  }
}
