import { PaginationAdapter } from '@red/data-access';
import { createAction, props } from '@ngrx/store';
import { ProjectReportModel } from '@shared/data-access/models';
import { IQuerySearch } from '@shared/data-access/interfaces';
// items
export const loadItems = createAction('[ProjectReport] Load items', props<{ filters: IQuerySearch }>());
export const loadItemsSuccess = createAction('[ProjectReport] Load items success', props<{ data: PaginationAdapter<ProjectReportModel> }>());

// item
export const loadItem = createAction('[ProjectReport] Load item', props<{ id: number }>());
export const loadItemSuccess = createAction('[ProjectReport] Load item success', props<{ item: ProjectReportModel }>());
