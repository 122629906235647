import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@red/api';
import { ENV_TYPE } from '@red/data-access';
import { IPermission, PermissionLoader } from '@shared/permission';
import { isNil } from 'lodash-es';
import { debounceTime, map, Observable } from 'rxjs';
import { AppConstant } from './../../../app.constant';

@Injectable({
  providedIn: 'root',
})
export class PermissionHttpLoader extends PermissionLoader {
  constructor(private _apiService: ApiService, private _httpClient: HttpClient) {
    super();
  }

  override get(): Observable<IPermission[]> {
    if (AppConstant.ENV_NAME === ENV_TYPE.LOCAL) {
      return this.fromJsonFile();
    }
    return this.fromApi();
  }

  private fromApi(): Observable<IPermission[]> {
    return this._apiService.get('settings/profile/permissions');
  }
  private fromJsonFile(): Observable<IPermission[]> {
    let nextId = 10000;
    return this._httpClient.get<{ RECORDS: IPermission[] }>('assets/sample/__permission.data.json').pipe(
      debounceTime(300),
      map(data => data.RECORDS),
      map(data =>
        data.map(item => {
          if (isNil(item.id)) {
            item.id = nextId;
            nextId++;
          }
          return item;
        })
      )
    );
  }
}
