import { createAction, props } from '@ngrx/store';
import { ProjectModel } from '@shared/data-access/models';

export const getList = createAction('[Project] GET LIST', props<{ items: ProjectModel[] }>());

export const getDetail = createAction('[Project] GET DETAIL', props<{ item: ProjectModel }>());

export const getTotalUnit = createAction('[Project] GET TOTAL UNITS', props<{ projectId: number }>());

export const getTotalUnitSuccess = createAction('[Project] GET TOTAL UNITS SUCCESS', props<{ projectId: number; total: number }>());

export const getTotalUnitFailure = createAction('[Project] GET TOTAL UNITS FAILURE', props<{ error: any }>());
