import { createAction, props } from '@ngrx/store';
import { AppointmentTypeConfigModel, AppointmentTypeModel } from '@shared/data-access/models';


export const getDetail = createAction(
  '[APPOINTMENT TYPE] GET DETAIL',
  props<{item:AppointmentTypeModel}>()
);



export const getSystemConfigs = createAction(
  '[APPOINTMENT TYPE] GET SYSTEM CONFIGS'
);
export const getSystemConfigsSuccess = createAction(
  '[APPOINTMENT TYPE] GET SYSTEM CONFIGS SUCCESS',
  props<{ data:AppointmentTypeConfigModel[]}>()
);
export const getSystemConfigsFailure = createAction(
  '[APPOINTMENT TYPE] GET SYSTEM CONFIGS FAILURE',
  props<{error:any}>()
);
