import { createFeatureSelector, createSelector } from "@ngrx/store";
import { JournalReducer } from "../reducers";

export const journalFeature = createFeatureSelector<JournalReducer.DataState>(
  JournalReducer.featureName
);

export const selectFeatureGetId = createSelector(
  journalFeature,
  (state: JournalReducer.DataState) => state.id
);

export const selectFeatureJournal = createSelector(
  journalFeature,
  (state: JournalReducer.DataState) => state.item
);

export const selectFeatureCreateOrUpdate = createSelector(
  journalFeature,
  (state: JournalReducer.DataState) => state.item
);