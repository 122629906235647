import { createFeature, createReducer, on } from "@ngrx/store";
import { ProjectTransactionModel } from "@shared/data-access/models";
import { ProjectTransactionAction } from "../actions";

export const featureName = 'projectTransaction';

export interface DataState {
  item:ProjectTransactionModel | null;

}
export const initialState:DataState = {
  item: null
};
export const projectTransactionFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(ProjectTransactionAction.getDetail,  (state, prop:{item:ProjectTransactionModel}) => ({ ...state, item: prop.item})),

  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = projectTransactionFeature;
