import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CommissionMatrixReducer } from "../reducers";

export const selectCommissionMatrixFeature = createFeatureSelector<CommissionMatrixReducer.DataState>(
  CommissionMatrixReducer.featureName
);

export const selectFeatureCommissionMatrix = createSelector(
  selectCommissionMatrixFeature,
  (state: CommissionMatrixReducer.DataState) => state.item
);

