import { assign, defaults, isObject } from 'lodash-es';
import 'reflect-metadata';
import { SwitchCompanyStateService } from './switch-company-state.service';
import { SwitchCompanyModule } from './switch-company.module';
import { CompanyModel } from '@shared/data-access/models';
const withCompanyMetadataKey = Symbol('withCompanyMetadataKey');
const paramKey = 'businessUnitId';

export function WithCompany(options?: { key: string; valueFn: (item: CompanyModel) => any; override?: boolean }): ParameterDecorator {
  return function (target: any, propertyKey: string | symbol | undefined, parameterIndex: number) {
    if (propertyKey) {
      const existingRequiredParameters: { parameterIndex: number; options: { key: string; valueFn: (item: CompanyModel) => any } }[] =
        Reflect.getOwnMetadata(withCompanyMetadataKey, target, propertyKey) || [];
      existingRequiredParameters.push({ parameterIndex, options: options ?? { key: paramKey, valueFn: (item: CompanyModel) => item.id } });
      Reflect.defineMetadata(withCompanyMetadataKey, existingRequiredParameters, target, propertyKey);
    }
  };
}

export function FnWithCompany(): MethodDecorator {
  return function (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = function (...agrs: any[]) {
      const requiredParameters: { parameterIndex: number; options: { key: string; valueFn: (item: CompanyModel) => any; override?: boolean } }[] =
        Reflect.getOwnMetadata(withCompanyMetadataKey, target, propertyKey);
      if (requiredParameters) {
        for (const parameterSelected of requiredParameters) {
          // if (parameterIndex >= arguments.length) {
          agrs[parameterSelected.parameterIndex] = agrs[parameterSelected.parameterIndex] ?? {};
          if (!isObject(agrs[parameterSelected.parameterIndex])) {
            throw new Error('Data using CompanyId must be an object.');
          } else {
            const company = SwitchCompanyModule.injector?.get(SwitchCompanyStateService).selected;
            if (!company) {
              throw new Error('Can not find Company selected.');
            }
            agrs[parameterSelected.parameterIndex] = parameterSelected.options.override
              ? assign(agrs[parameterSelected.parameterIndex], {
                  [parameterSelected.options.key]: parameterSelected.options.valueFn(company),
                })
              : defaults(agrs[parameterSelected.parameterIndex], {
                  [parameterSelected.options.key]: parameterSelected.options.valueFn(company),
                });
          }
          // }
        }
      }
      return originalMethod.apply(this, agrs);
    };
    // return descriptor;
  };
}
