import { createFeature, createReducer, on } from "@ngrx/store";
import { EducationRecordModel } from "@shared/data-access/models";
import { EducationRecordAction } from "../actions";

export const featureName = 'educationRecordInfo';

export interface EducationRecordState {
  items:EducationRecordModel[];
}
export const initialState: EducationRecordState = {
  items: [],
};
export const educationRecordFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(EducationRecordAction.getList,  (state,{payload}) => ({ ...state, items:payload}))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = educationRecordFeature;
