import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CommLeadershipSchemeReducer } from "../reducers";

export const selectCommLeadershipSchemeFeature = createFeatureSelector<CommLeadershipSchemeReducer.CommLeadershipSchemeState>(
  CommLeadershipSchemeReducer.featureName
);

export const selectFeatureCommLeadershipSchemeInfo = createSelector(
  selectCommLeadershipSchemeFeature,
  (state: CommLeadershipSchemeReducer.CommLeadershipSchemeState) => state.detail
);
