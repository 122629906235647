import { BaseModel } from '@red/data-access';
import {  Expose } from "class-transformer";
import { IPaymentInfo } from "../interfaces/payment-info.interface";


export class PaymentInfoModel extends BaseModel implements IPaymentInfo{
    @Expose()
    qrcode?: string;

    @Expose()
    bankName?: string;

    @Expose()
    accountHolder?: string;

    @Expose()
    accountNumber?: string;

    @Expose()
    description?: string;
}
