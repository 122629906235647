import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DebitNoteModel } from '@shared/data-access/models';
import { Observable, filter, take } from 'rxjs';
import { DebitNoteAction } from '../actions';
import { DebitNoteReducer } from '../reducers';
import { DebitNoteSelector } from '../selectors';

@Injectable()
export class DebitNoteFacade {
  selectedItem$ = this._store.select(DebitNoteSelector.selectDebitNote);
  constructor(private _store: Store<DebitNoteReducer.DataState>) {}

  /**
   * Get @DebitNoteModel by id
   */
  getById(id: number): Observable<DebitNoteModel | null> {
    this._store.dispatch(DebitNoteAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<DebitNoteModel | null>;
  }
}
