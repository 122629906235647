import { Injectable } from '@angular/core';
import { ApiServiceForDetailResolver } from '@shared/core';
import { ResaleTransactionApiService } from '@cms/apis';
import { defer, forkJoin, of, switchMap, tap } from 'rxjs';
import { ResaleTransactionFacade } from '@cms/stores';
import { ResaleBillingModel, ResaleTransactionModel } from '@shared/data-access/models';
import { IResaleTransaction } from '@shared/data-access/interfaces';
import { EResaleStatus } from '@shared/data-access/enums';

@Injectable({
  providedIn: 'root',
})
export class ResaleTransactionsService implements ApiServiceForDetailResolver {
  constructor(private _apiService: ResaleTransactionApiService, private _facade: ResaleTransactionFacade) { }

  getById(id: number) {
    return defer(() =>
      this._apiService.get(id).pipe(
        switchMap(item => {
          const { status } = item;
          // const isHaveRejectReason = [EResaleStatus.rework, EResaleStatus.rework2, EResaleStatus.rejected].includes(status);
          const isHaveRejectReason = [EResaleStatus.sendBack, EResaleStatus.rework, EResaleStatus.rejected].includes(status);
          return forkJoin([
            of(item),
            // this._apiService.getBillingsByResaleId(id),
            this._apiService.searchDocuments({ resaleIds: id }),
            isHaveRejectReason
              ? this._apiService.searchStatusHistories({
                orderBy: 'createdAt',
                resaleIds: id,
              })
              : of(null),
          ]);
        }),
        tap(([item, documentsPaginated, statusHistoriesPaginated]) => {
          item = ResaleTransactionModel.merge(item, {
            rejectReason: statusHistoriesPaginated?.results[0]?.data?.reason ?? null,
            billing: item.billing,
            documents: documentsPaginated.results,
            additionals: documentsPaginated.data,
          } as Partial<IResaleTransaction>);
          if (item.metadata) {
            this._facade.setMetadata(item.metadata)
          }
          this._facade.setItemDetail(item);
        })
      )
    );
  }
}
