export const HTTP_ERROR_MESSAGES = [
  {
    errorCode: 2627,
    message: 'Code already exists!',
  },
  {
    errorCode: 420001,
    message: ' Accounting Period Closed for the following Date entered: {{ date }}. \n Please change the Date.',
  },
  {
    errorCode: 1,
    message: 'Something when wrong!',
  },
];
