import { createFeature, createReducer, on } from '@ngrx/store';
import { SupplierInvoiceModel } from '@shared/data-access/models';
import { SupplierInvoiceAction } from '../actions';

export const featureName = 'supplierInvoice';

export interface DataState {
  item: SupplierInvoiceModel | null;
}

export const initialState: DataState = {
  item: null,
};

export const supplierInvoiceFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(SupplierInvoiceAction.loadItemSuccess, (state, prop: { item: SupplierInvoiceModel }) => ({ ...state, item: prop.item }))
  ),
});

export const { name, reducer } = supplierInvoiceFeature;
