import { createAction, props } from '@ngrx/store';
import { EResaleType } from '@shared/data-access/enums';
import { IResaleBilling } from '@shared/data-access/interfaces';
import { ResaleCoBrokeModel, ResaleDocumentModel, ResaleExternalCoBrokeModel, ResaleTransactionModel } from '@shared/data-access/models';

export const setItemDetail = createAction('[Resale Transaction] SET ITEM DETAIL', props<{ item: ResaleTransactionModel | null }>());

export const loadItemDetail = createAction('[Resale Transaction] LOAD ITEM DETAIL', props<{ id: number }>());

export const setMetadata = createAction('[Resale Transaction] SET MEATADATA ITEM', props<{ metadata: object }>());

export const loadMetadata = createAction('[Resale Transaction] LOAD METADATA', props<{ id: number }>());

export const updateItemDetail = createAction('[Resale Transaction] UPDATE ITEM DETAIL', props<{ data: Partial<ResaleTransactionModel> }>());

export const setDraftItemAsCreate = createAction('[Resale Transaction] SET DRAFT ITEM AS CREATE');

export const setDraftItemAsEdit = createAction('[Resale Transaction] SET DRAFT ITEM AS EDIT');

export const setDraftItemAsClone = createAction('[Resale Transaction] SET DRAFT ITEM AS CLONE');

export const updateDraftItem = createAction('[Resale Transaction] UPDATE DRAFT ITEM', props<{ data: Partial<ResaleTransactionModel> }>());

export const updateResaleTypeDraftItem = createAction('[Resale Transaction] UPDATE RESALE TYPE DRAFT ITEM', props<{ resaleType: EResaleType }>());

export const updateTransactionAmountDraftItem = createAction(
  '[Resale Transaction] UPDATE TRANSACTION AMOUNT DRAFT ITEM',
  props<{ transactionAmount: number | null }>()
);

export const updateBillingDraftItem = createAction('[Resale Transaction] UPDATE BILLING DRAFT ITEM', props<{ data: Partial<IResaleBilling> }>());

export const addExternalDraftItem = createAction('[Resale Transaction] ADD EXTERNAL DRAFT ITEM', props<{ item: ResaleExternalCoBrokeModel }>());

export const updateExternalDraftItem = createAction('[Resale Transaction] UPDATE EXTERNAL DRAFT ITEM', props<{ index: number; item: ResaleExternalCoBrokeModel }>());

export const deleteExternalDraftItem = createAction('[Resale Transaction] DELETE EXTERNAL DRAFT ITEM', props<{ index: number }>());

export const addDocumentsDraftItem = createAction('[Resale Transaction] ADD DOCUMENTS DRAFT ITEM', props<{ items: ResaleDocumentModel[] }>());

export const deleteDocumentDraftItem = createAction('[Resale Transaction] DELETE DOCUMENT DRAFT ITEM', props<{ items: ResaleDocumentModel[] }>());

export const saveDraftItem = createAction('[Resale Transaction] SAVE DRAFT ITEM');

export const saveAndSubmitDraftItem = createAction('[Resale Transaction] SAVE AND SUBMIT DRAFT ITEM');

export const submitItemSuccess = createAction('[Resale Transaction] SUBMIT DRAFT ITEM SUCCESS', props<{ res: any }>());

export const submitItemFail = createAction('[Resale Transaction] SUBMIT DRAFT ITEM FAIL', props<{ error: any }>());

export const resetDraftItem = createAction('[Resale Transaction] RESET DRAFT ITEM');

export const resetSubmittingStatus = createAction('[Resale Transaction] RESET SUBMITTING STATUS');

export const updateIsInternal = createAction('[Resale Transaction] UPDATE IS INTERNAL', props<{ isInternal: boolean }>());

export const loadGrossCommPreview = createAction('[Resale Transaction] LOAD GROSS COMM PREVIEW');

export const addInternalDraftItem = createAction('[Resale Transaction] ADD INTERNAL DRAFT ITEM', props<{ item: ResaleCoBrokeModel }>());

export const updateInternalDraftItem = createAction('[Resale Transaction] UPDATE INTERNAL DRAFT ITEM', props<{ index: number; item: ResaleCoBrokeModel }>());

export const deleteInternalDraftItem = createAction('[Resale Transaction] DELETE INTERNAL DRAFT ITEM', props<{ index: number }>());

export const addMainDraftItem = createAction('[Resale Transaction] ADD MAIN DRAFT ITEM', props<{ item: ResaleCoBrokeModel }>());

export const updateMainDraftItem = createAction('[Resale Transaction] UPDATE MAIN DRAFT ITEM', props<{ index: number; item: ResaleCoBrokeModel }>());

export const deleteMainDraftItem = createAction('[Resale Transaction] DELETE MAIN DRAFT ITEM', props<{ index: number }>());

export const checkDuplicateDraftItem = createAction('[Resale Transaction] CHECK DUPLICATE DRAFT ITEM');

export const submitDraftItem = createAction('[Resale Transaction] SUBMIT DRAFT ITEM');

export const updateMetadataDraftItem = createAction('[Resale Transaction] UPDATE METADATA DRAFT ITEM', props<{ metadata: object }>());

export const saveRemark = createAction('[Resale Transaction] SAVE REMARK', props<{ remarks: string }>());

export const updateOptionDate = createAction('[Resale Transaction] CHANGE OPTION DATE', props<{ date: string }>());
