import { createFeature, createReducer, on } from '@ngrx/store';
import { PaymentGeneratorModel } from '@shared/data-access/models';
import { PaymentGeneratorAction } from '../actions';

export const featureName = 'paymentGenerator';

export interface PaymentGeneratorState {
  item: PaymentGeneratorModel | null;
}
export const initialState: PaymentGeneratorState = {
  item: null,
};
export const paymentGeneratorFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(PaymentGeneratorAction.loadItemSuccess, (state, prop: { item: PaymentGeneratorModel }) => ({ ...state, item: prop.item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = paymentGeneratorFeature;
