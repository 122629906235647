import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
export class HttpErrorWrapper {
  statusCode!: number;
  errorCode?: number;
  message!: string;

  constructor(options: { statusCode: number; error: any }) {
    this.statusCode = options.statusCode;
    const { errorCode, message } = options.error instanceof ArrayBuffer ? this.convertArrayBufferToHttpError(options.error) : options.error;

    this.errorCode = errorCode;
    this.message = message;
  }

  private convertArrayBufferToHttpError(buffer: ArrayBuffer): { errorCode: string; message: string } {
    const decoder = new TextDecoder('utf-8');
    const errorText = decoder.decode(buffer);
    const errorObj = JSON.parse(errorText);

    return {
      errorCode: errorObj.statusCode,
      message: errorObj.message,
    };
  }
}
@Injectable()
export class HttpErrorWrapperInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log('Error Wrapper', error);
        return throwError(() => {
          return new HttpErrorWrapper({ statusCode: error.status, error: error.error ?? { errorCode: error.status, message: error.message } });
        });
      })
    );
  }
}
