import { createAction, props } from '@ngrx/store';
import { BlankFormModel } from '@shared/data-access/models/blank-form.model';
import {
  BlankFormBdModel,
  BlankFormBuyerModel,
  BlankFormIcModel,
  BlankFormOtherFeeModel,
  BlankFormPdModel,
  BlankFormReferralModel,
  BusinessDirectorModel,
  ProjectModel,
  ProjectTransactionBdsModel,
  ProjectTransactionBonusModel,
  ProjectTransactionBuyerModel,
  ProjectTransactionOverridingModel,
  ProjectTransactionPdModel,
  ProjectTransactionPoolModel,
  ProjectTransactionReferralModel,
  ProjectTransactionRelatedPartyModel,
} from '@shared/data-access/models';
import { IBlankFormRelatedAgent, ISplitPart } from '@shared/data-access/interfaces';
import { ProjectTransactionOtherModel } from '@shared/data-access/models/project-transaction-other.model';
import { ProjectTransactionCobrokeReferralModel } from '@shared/data-access/models/project-transaction-cobroke-referral.model';

export const setItemDetail = createAction('[Blank Form] SET ITEM DETAIL', props<{ item: BlankFormModel | null }>());

export const loadItemDetail = createAction('[Blank Form] LOAD ITEM DETAIL', props<{ id: number }>());

export const updateItemDetail = createAction('[Blank Form] UPDATE ITEM DETAIL', props<{ data: Partial<BlankFormModel> }>());

export const setDraftItemAsCreate = createAction('[Blank Form] SET DRAFT ITEM AS CREATE');

export const setDraftItemAsEdit = createAction('[Blank Form] SET DRAFT ITEM AS EDIT');

export const setDraftItemAsClone = createAction('[Blank Form] SET DRAFT ITEM AS CLONE');

export const updateDraftItem = createAction('[Blank Form] UPDATE DRAFT ITEM', props<{ data: Partial<BlankFormModel> }>());

export const setDefaultValueRelatedAgentFromReference = createAction(
  '[Blank Form] SET DEFAULT VALUE RELATED AGENT FROM REFERENCE',
  props<{ relatedAgent: ProjectTransactionRelatedPartyModel | IBlankFormRelatedAgent }>()
);

export const setDefaultValueBonusesFromReference = createAction(
  '[Blank Form] SET DEFAULT VALUE BONUSES FROM TRANSACTION',
  props<{ bonuses: ProjectTransactionBonusModel[] | BlankFormIcModel[] }>()
);

export const setDefaultValueOverridingsFromReference = createAction(
  '[Blank Form] SET DEFAULT VALUE OVERRIDINGS FROM TRANSACTION',
  props<{ overridings: ProjectTransactionOverridingModel[] | BlankFormIcModel[] }>()
);

export const setDefaultValuePoolsFromReference = createAction(
  '[Blank Form] SET DEFAULT VALUE POOLS FROM TRANSACTION',
  props<{ pools: ProjectTransactionPoolModel[] | BlankFormIcModel[] }>()
);

export const setDefaultValuePdsFromReference = createAction(
  '[Blank Form] SET DEFAULT VALUE PDS FROM TRANSACTION',
  props<{ pds: ProjectTransactionPdModel[] | BlankFormPdModel[] }>()
);

export const setDefaultValueReferralsFromReference = createAction(
  '[Blank Form] SET DEFAULT VALUE REFERRALS FROM TRANSACTION',
  props<{ referrals: ProjectTransactionReferralModel[] | ProjectTransactionCobrokeReferralModel[] | BlankFormReferralModel[] }>()
);

export const setDefaultValueOtherFeeFromReference = createAction(
  '[Blank Form] SET DEFAULT VALUE OTHER FEE FROM REFERENCE',
  props<{ otherFees: BlankFormOtherFeeModel[] | ProjectTransactionOtherModel[] }>()
);

export const setDefaultValueBuyersFromReference = createAction(
  '[Blank Form] SET DEFAULT VALUE BUYERS FROM REFERENCE',
  props<{ buyers: BlankFormBuyerModel[] | ProjectTransactionBuyerModel[] }>()
);

export const setDefaultValueBdsFromReference = createAction(
  '[Blank Form] SET DEFAULT VALUE HI FROM REFERENCE',
  props<{ bds: BlankFormBdModel[] | ProjectTransactionBdsModel[] }>()
);

export const loadDefaultDataFromProject = createAction('[Blank Form] LOAD DEFAULT DATA FROM PROJECT', props<{ project: ProjectModel; optionDate?: string | null }>());

export const loadBdsFromProject = createAction('[Blank Form] LOAD BDS FROM PROJECT', props<{ project: ProjectModel; optionDate?: string | null }>());

export const setBdsFromProject = createAction('[Blank Form] SET BDS FROM PROJECT', props<{ bdsFromProject: BusinessDirectorModel[] }>());

export const saveDraftItem = createAction('[Blank Form] SAVE DRAFT ITEM');

export const saveAndSubmitDraftItem = createAction('[Blank Form] SAVE AND SUBMIT DRAFT ITEM');

export const submitItemSuccess = createAction('[Blank Form] SUBMIT DRAFT ITEM SUCCESS', props<{ res: any }>());

export const submitItemFail = createAction('[Blank Form] SUBMIT DRAFT ITEM FAIL', props<{ error: any }>());

export const resetDraftItem = createAction('[Blank Form] RESET DRAFT ITEM');

export const resetSubmittingStatus = createAction('[Blank Form] RESET SUBMITTING STATUS');

export const setLoadingStatus = createAction('[Blank Form] SET LOADING STATUS', props<{ loading: boolean }>());

export const reset = createAction('[Blank Form] RESET');

export const updateOptionDate = createAction('[Blank Form] UPDATE OPTION DATE', props<{ date: string }>());

export const updateInvalidAgents = createAction('[Resale Adjustment] UPDATE INVAILD AGENTS', props<{ agents: Partial<ISplitPart[]> }>());