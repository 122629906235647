import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AgentReportModel } from '@shared/data-access/models';
import { filter, Observable, take } from 'rxjs';
import { AgentReportAction } from '../actions';
import { AgentReportReducer } from '../reducers';
import { AgentReportSelector } from '../selectors';

@Injectable()
export class AgentReportFacade {
  selectedItem$ = this._store.select(AgentReportSelector.selectAgentReport);
  constructor(private _store: Store<AgentReportReducer.AgentReportState>) {}

  /**
   * Get @AgentReportModel by id
   */
  getById(id: number): Observable<AgentReportModel | null> {
    this._store.dispatch(AgentReportAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<AgentReportModel | null>;
  }
}
