import { Injectable, InjectionToken } from '@angular/core';
import { IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { IQuerySearch } from '@shared/data-access/interfaces';
import { CompanyModel } from '@shared/data-access/models';
import { Observable } from 'rxjs';

export const SWITCH_COMPANY_API_LOADER = new InjectionToken<SwitchCompanyLoaderService>('SWITCH_COMPANY_API_LOADER');

@Injectable()
export abstract class SwitchCompanyLoaderService {
  abstract search(query: IQuerySearch, option?: IApiOption): Observable<CompanyModel[]>;

  abstract getCurrentSelected(companyId?: number): Observable<CompanyModel>;

  abstract updateSelected(companyId: number): Observable<unknown>;

  abstract updatedSelectedInResolver(companyId: number): Observable<CompanyModel>;
}
