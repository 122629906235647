import { createFeature, createReducer, on } from '@ngrx/store';
import { CustomerModel } from '@shared/data-access/models';
import { CustomerAction } from '../actions';

export const featureName = 'customer';

export interface CustomerState {
  item: CustomerModel | null;
}
export const initialState: CustomerState = {
  item: null,
};
export const customerFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(CustomerAction.loadItemSuccess, (state, prop: { item: CustomerModel }) => ({ ...state, item: prop.item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = customerFeature;
