import { ModuleWithProviders, NgModule } from "@angular/core";
import { RedTailwindService } from "./tailwind.service";
import { RED_TAILWIND_EXTRACTED } from "./token";

@NgModule({
  providers: [
    RedTailwindService
  ]
})
export class RedTailwindConfigModule
{
  /**
   * Constructor
   */
  constructor(private _tailwindConfigService: RedTailwindService)
  {
  }
  /**
    * forRoot method for setting user configuration
    *
    * @param config
    */
   static forRoot(fileExtracted: any): ModuleWithProviders<RedTailwindConfigModule>
   {
       return {
           ngModule : RedTailwindConfigModule,
           providers: [
               {
                   provide : RED_TAILWIND_EXTRACTED,
                   useValue: fileExtracted
               }
           ]
       };
   }
}
