import { createFeature, createReducer, on } from '@ngrx/store';
import { JournalEntryModel } from '@shared/data-access/models';
import { JournalEntryAction } from '../actions';

export const featureName = 'journalEntry';

export interface JournalEntryState {
  item: JournalEntryModel | null;
}
export const initialState: JournalEntryState = {
  item: null,
};
export const journalEntryFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(JournalEntryAction.loadItemSuccess, (state, prop: { item: JournalEntryModel }) => ({ ...state, item: prop.item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = journalEntryFeature;
