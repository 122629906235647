import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { BatchSearchEngineAction } from '../actions';
import { BatchSearchEngineSelector } from '../selectors';
import { BatchingApprovalApiService } from '@cms/apis';

@Injectable()
export class BatchSearchEngineFacade {
  selectedItem$ = this._store.select(BatchSearchEngineSelector.selectItem);

  constructor(private _store: Store, private _apiService: BatchingApprovalApiService) {}

  /**
   * Get @ProjectReportModel by id
   */
  getById(id: number) {
    return this._apiService.searchEngineById(id).pipe(
      tap(res => {
        this._store.dispatch(BatchSearchEngineAction.setItemDetail({ item: res }));
      })
    );
  }
}
