import { createFeature, createReducer, on } from "@ngrx/store";
import { ResaleReportModel } from "@shared/data-access/models";
import { ResaleReportAction } from "../actions";

export const featureName = 'resaleReport';

export interface DataState {
  item:ResaleReportModel | null;

}
export const initialState:DataState = {
  item: null
};
export const resaleReportFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(ResaleReportAction.getDetail,  (state, prop:{item:ResaleReportModel}) => ({ ...state, item: prop.item})),

  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = resaleReportFeature;
