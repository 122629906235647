import { createFeature, createReducer, on } from '@ngrx/store';
import { AgentReportModel } from '@shared/data-access/models';
import { AgentReportAction } from '../actions';

export const featureName = 'agentReport';

export interface AgentReportState {
  item: AgentReportModel | null;
}
export const initialState: AgentReportState = {
  item: null,
};
export const agentReportFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(AgentReportAction.loadItemSuccess, (state, prop: { item: AgentReportModel }) => ({ ...state, item: prop.item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = agentReportFeature;
