import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ProjectCommissionMatrixReducer } from "../reducers";

export const selectProjectCommissionMatrixFeature = createFeatureSelector<ProjectCommissionMatrixReducer.DataState>(
  ProjectCommissionMatrixReducer.featureName
);

export const selectFeatureProjectCommissionMatrix = createSelector(
  selectProjectCommissionMatrixFeature,
  (state: ProjectCommissionMatrixReducer.DataState) => state.item
);

