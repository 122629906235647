import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { iif, mapTo, of, switchMap } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ResaleAdjustmentAction } from '../actions';
import { ResaleAdjustmentSelector } from '../selectors';
import { ResaleAdjustmentService } from '@cms/finance/resale-adjustment/services';
import { EBlankFormStatus } from '@shared/data-access/enums';
import { ResaleAdjustmentApiService } from '@cms/apis';

@Injectable()
export class ResaleAdjustmentEffect {
  saveDratItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResaleAdjustmentAction.saveDraftItem),
      concatLatestFrom(() => this.store.select(ResaleAdjustmentSelector.selectDraftState)),
      mergeMap(([_, { draftItem, draftType }]) =>
        iif(() => draftType === 'create', this.resaleAdjustmentService.createFullData(draftItem), this.resaleAdjustmentService.updateFullData(draftItem)).pipe(
          switchMap(res => of(ResaleAdjustmentAction.submitItemSuccess({ res }))),
          catchError(error => of(ResaleAdjustmentAction.submitItemFail({ error })))
        )
      )
    )
  );

  saveAndSubmitDraftItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResaleAdjustmentAction.saveAndSubmitDraftItem),
      concatLatestFrom(() => this.store.select(ResaleAdjustmentSelector.selectDraftState)),
      mergeMap(([_, { draftItem, draftType }]) =>
        iif(() => draftType === 'create', this.resaleAdjustmentService.createFullData(draftItem), this.resaleAdjustmentService.updateFullData(draftItem)).pipe(
          switchMap(id => {
            return this.apiService.submit(id).pipe(
              mapTo({
                id,
                status: EBlankFormStatus.submitted,
              })
            );
          }),
          switchMap(res => of(ResaleAdjustmentAction.submitItemSuccess({ res }))),
          catchError(error => of(ResaleAdjustmentAction.submitItemFail({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private resaleAdjustmentService: ResaleAdjustmentService,
    private apiService: ResaleAdjustmentApiService
  ) {}
}
