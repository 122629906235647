import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SupplierInvoiceModel } from '@shared/data-access/models';
import { Observable, filter, take } from 'rxjs';
import { SupplierInvoiceAction } from '../actions';
import { SupplierInvoiceReducer } from '../reducers';
import { SupplierInvoiceSelector } from '../selectors';

@Injectable()
export class SupplierInvoiceFacade {
  selectedItem$ = this._store.select(SupplierInvoiceSelector.selectSupplierInvoice);
  constructor(private _store: Store<SupplierInvoiceReducer.DataState>) {}

  /**
   * Get @SupplierInvoiceModel by id
   */
  getById(id: number): Observable<SupplierInvoiceModel | null> {
    this._store.dispatch(SupplierInvoiceAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<SupplierInvoiceModel | null>;
  }
}
