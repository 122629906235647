import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SupplierModel } from '@shared/data-access/models';
import { filter, Observable, take } from 'rxjs';
import { SupplierAction } from '../actions';
import { SupplierReducer } from '../reducers';
import { SupplierSelector } from '../selectors';

@Injectable()
export class SupplierFacade {
  selectedItem$ = this._store.select(SupplierSelector.selectSupplier);
  constructor(private _store: Store<SupplierReducer.SupplierState>) {}

  /**
   * Get @SupplierModel by id
   */
  getById(id: number): Observable<SupplierModel | null> {
    this._store.dispatch(SupplierAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<SupplierModel | null>;
  }
}
