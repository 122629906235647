import { Injectable } from '@angular/core';
import { AssetRegisterApiService } from '@cms/apis';
import { FnWithCompany, WithCompany } from '@cms/backgrounds/switch-company';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, map } from 'rxjs';
import { AssetRegisterAction } from '../actions';

@Injectable()
export class AssetRegisterEffect {
  loadItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AssetRegisterAction.loadItem),
      exhaustMap(action => {
        return this.fetch(action.id).pipe(map(item => AssetRegisterAction.loadItemSuccess({ item })));
      })
    );
  });

  @FnWithCompany()
  fetch(id: number, @WithCompany() option: Record<string, any> = {}) {
    return this._apiService.get(id, option);
  }

  constructor(private _apiService: AssetRegisterApiService, private _actions$: Actions, private _store: Store) {}
}
