import { createFeature, createReducer, on } from '@ngrx/store';
import { UserGroupModel } from '@shared/data-access/models';
import { UserGroupAction } from '../actions';

export const featureName = 'userGroup';

export interface DataState {
  detail: UserGroupModel | null;
}
export const initialState: DataState = {
  detail: null,
};
export const userGroupFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(UserGroupAction.getDetail, (state, prop: { item: UserGroupModel }) => ({ ...state, detail: prop.item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = userGroupFeature;
