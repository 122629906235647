import { ToastService } from '@shared/components/toast';
import { ActivatedRoute } from '@angular/router';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'cms-error-404',
  templateUrl: './error-404.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Error404Component implements OnInit, OnDestroy {
  private _unsubscribeAll = new Subject<void>();

  constructor(private _route: ActivatedRoute, private _toastService: ToastService) {}

  ngOnInit(): void {
    this._route.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      if (data['no-permission'] === 'true') {
        this._toastService.open({
          type: 'danger',
          messages: "You don't have permission to access on this page",
          title: 'No permission!',
        });
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  reload(): void {
    location.reload();
  }
}
