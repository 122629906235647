import { createFeature, createReducer, on } from '@ngrx/store';
import { DirectDebitPaymentModel } from '@shared/data-access/models';
import { DirectDebitPaymentAction } from '../actions';

export const featureName = 'directDebitPayment';

export interface DirectDebitPaymentState {
  item: DirectDebitPaymentModel | null;
}
export const initialState: DirectDebitPaymentState = {
  item: null,
};
export const directDebitPaymentFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(DirectDebitPaymentAction.loadItemSuccess, (state, prop: { item: DirectDebitPaymentModel }) => ({ ...state, item: prop.item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = directDebitPaymentFeature;
