import { URL_LONG, URL_SHORT } from './print-dom.constant';

export function createIFrame(parent: HTMLElement) {
  const el = window.document.createElement('iframe');
  el.setAttribute('src', 'about:blank');
  el.setAttribute('style', 'visibility:hidden;width:0;height:0;position:absolute;z-index:-9999;bottom:0;');
  el.setAttribute('width', '0');
  el.setAttribute('height', '0');
  el.setAttribute('wmode', 'opaque');
  parent.appendChild(el);
  return el;
}

export function isValidURL(str: string) {
  return URL_LONG.test(str) || URL_SHORT.test(str);
}

export function createStyle(doc: Document, cssText: string) {
  const style = doc.createElement('style');
  style.appendChild(doc.createTextNode(cssText));
  return style;
}

export function createLinkStyle(doc: Document, url: string) {
  const style = doc.createElement('link');
  style.type = 'text/css';
  style.rel = 'stylesheet';
  style.href = url;
  return style;
}
