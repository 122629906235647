import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, NgZone, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, from, Observable, switchMap, throwError } from 'rxjs';
export const HTTP_STATUS_CODE_EXCLUDE = new InjectionToken<string>('HTTP_STATUS_CODE_EXCLUDE');
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(@Inject(HTTP_STATUS_CODE_EXCLUDE) @Optional() private _statusCodeExcludeKey: string, private router: Router, private _ngZone: NgZone) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log('hello error-->', error);
        const statusCodeExclude = request.headers.getAll(this._statusCodeExcludeKey);
        if (statusCodeExclude && statusCodeExclude.find(code => Number(code) === error.status)) {
          return throwError(() => error);
        }
        if ([403, 404, 500].some(code => code === error.status)) {
          this._ngZone.run(() => {
            this.router.navigate([`error/${String(error.status)}`], { skipLocationChange: true });
          });
        }

        return throwError(() => error);
      })
    );
  }
}
