import { PaginationAdapter } from '@red/data-access';
import { createAction, props } from '@ngrx/store';
import { AssetDepreciationModel } from '@shared/data-access/models';
import { IQuerySearch } from '@shared/data-access/interfaces';
// items
export const loadItems = createAction('[AssetDepreciation] Load items', props<{ filters: IQuerySearch }>());
export const loadItemsSuccess = createAction('[AssetDepreciation] Load items success', props<{ data: PaginationAdapter<AssetDepreciationModel> }>());

// item
export const loadItem = createAction('[AssetDepreciation] Load item', props<{ id: number }>());
export const loadItemSuccess = createAction('[AssetDepreciation] Load item success', props<{ item: AssetDepreciationModel }>());

// depreciation
export const loadDepreciation = createAction('[AssetDepreciation] Load Depreciation');
export const loadDepreciationSuccess = createAction('[AssetDepreciation] Load Depreciation Success', props<{ data: AssetDepreciationModel }>());
