import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { CompanyApiService, ReferenceConfigApiService } from '@cms/apis';
import { SwitchCompanyLoaderService, SwitchCompanyStateService } from '@cms/backgrounds/switch-company';
import { IApiOption } from '@red/api';
import { ReferenceConfigDto } from '@shared/data-access/dto';
import { IQuerySearch } from '@shared/data-access/interfaces';
import { CompanyModel } from '@shared/data-access/models';
import { Observable, from, map, of, switchMap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SwitchCompanyApiLoader extends SwitchCompanyLoaderService {
  routeSnapshot?: ActivatedRouteSnapshot;
  constructor(
    private _companyApiService: CompanyApiService,
    private _referenceConfigApiService: ReferenceConfigApiService,
    private _companyState: SwitchCompanyStateService,
    private _router: Router
  ) {
    super();
    this._fetchApi();
    this._router.events.subscribe(event => {
      if (event instanceof ActivationEnd && event.snapshot.data['isRouteListRoot']) {
        this.routeSnapshot = event.snapshot;
        console.log('evt ---> ', event);
      }
    });
  }
  isLoaded(companyId: number): boolean {
    return this._companyState.selected?.id === companyId;
  }
  search(query: IQuerySearch, option?: IApiOption | undefined): Observable<CompanyModel[]> {
    return this._referenceConfigApiService.getCompanies();
  }
  getCurrentSelected(companyId?: number): Observable<CompanyModel> {
    if (companyId) {
      if (this.isLoaded(companyId)) {
        return of(this._companyState.selected as CompanyModel);
      }
      // return this._companyApiService.get(companyId).pipe(
      //   map(company => {
      //     this._companyState.set(company);
      //     return company;
      //   })
      // );
      return this._referenceConfigApiService.getCompanies().pipe(
        switchMap(companies => {
          const company = companies.find(item => item.id === companyId);
          if (company) {
            this._companyState.set(company);
            return of(company);
          }
          this._router.navigateByUrl('error/404', { skipLocationChange: true });
          return throwError(() => `current user can not access data from company with id ${companyId}`);
        })
      );
    }
    if (this._companyState.selected) {
      return of(this._companyState.selected as CompanyModel);
    }

    return this._fetchApi();
  }
  private _fetchApi(): Observable<CompanyModel> {
    return this._referenceConfigApiService.get().pipe(
      map(data => {
        this._companyState.set(data.company);
        return data.company;
      })
    );
  }
  updateSelected(companyId: number): Observable<unknown> {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    const mySubscription = this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this._router.onSameUrlNavigation = 'ignore';
        this._router.routeReuseStrategy.shouldReuseRoute = (future, curr) => {
          return future.routeConfig === curr.routeConfig;
        };
        mySubscription.unsubscribe();
      }
    });
    return this._referenceConfigApiService.update(ReferenceConfigDto.fromJson({ companyId })).pipe(
      switchMap(() => {
        return this._fetchApi()

        // return from(this._router.navigateByUrl(this._router.url));
      }),
      map(company => {
        const spliters = this._router.url.split('/company/');
        const endUrls = spliters[1].split('/');
        endUrls.shift();
        return from(this._router.navigate([spliters[0], 'company', companyId]));
      })
    );
  }

  updatedSelectedInResolver(companyId: number) {
    return this._referenceConfigApiService.update(ReferenceConfigDto.fromJson({ companyId })).pipe(switchMap(() => this.getCurrentSelected()));
  }
}
