import { Inject, Optional, Pipe, PipeTransform, inject } from '@angular/core';
import { ErrorMessage, DEFAULT_ERROR_MESSAGE_TOKEN, HTTP_ERROR_MESSAGES_TOKEN } from './dynamic-error-message.module';

@Pipe({
  name: 'dynamicErrorMessage',
})
export class DynamicErrorMessagePipe implements PipeTransform {
  private _data: ErrorMessage[] = inject(HTTP_ERROR_MESSAGES_TOKEN);
  private _fallbackMessage?: string | null = inject(DEFAULT_ERROR_MESSAGE_TOKEN, { optional: true });

  transform(errorCode: number, placeholders: Record<string, string>): string | null | undefined {
    let message = this._fallbackMessage;

    const avalableMessage = this._data.find(x => x.errorCode === errorCode)?.message;

    if (avalableMessage) {
      Object.keys(placeholders).forEach(key => {
        message = avalableMessage.replace(`{{ ${key} }}`, placeholders[key]);
        // message = avalableMessage.replace(`{{${key}}}`, placeholders[key]);
      });
    }

    return message;
  }
}
