import { createFeature, createReducer, on } from '@ngrx/store';
import { LookupTableModel } from '@shared/data-access/models';
import { LookupTableAction } from '../actions';

export const featureName = 'lookupTable';

export interface LookupTableState {
  item: LookupTableModel | null;
}
export const initialState: LookupTableState = {
  item: null,
};
export const lookupTableFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(LookupTableAction.loadItemSuccess, (state, prop: { item: LookupTableModel }) => ({ ...state, item: prop.item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = lookupTableFeature;
