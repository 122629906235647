import { createFeature, createReducer, on } from '@ngrx/store';
import { CareerProgressionModel } from '@shared/data-access/models';
import { CareerProgressionAction } from '../actions';

export const featureName = 'careerProgressionInfoFeature';

export interface CareerProgressionInfoState {
  items: CareerProgressionModel[];
}
export const initialState: CareerProgressionInfoState = {
  items: []
};
export const careerProgressionInfoFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(CareerProgressionAction.setList, (state, { items }) => ({
      ...state,
      items
    }))
  )
});

export const {
  name, // feature name
  reducer // feature reducer
} = careerProgressionInfoFeature;
