import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SupplierCreditNoteModel } from '@shared/data-access/models';
import { Observable, filter, take } from 'rxjs';
import { SupplierCreditNoteAction } from '../actions';
import { SupplierCreditNoteReducer } from '../reducers';
import { SupplierCreditNoteSelector } from '../selectors';

@Injectable()
export class SupplierCreditNoteFacade {
  selectedItem$ = this._store.select(SupplierCreditNoteSelector.SelectSupplierCreditNote);
  constructor(private _store: Store<SupplierCreditNoteReducer.DataState>) {}

  /**
   * Get @SupplierCreditNoteModel by id
   */
  getById(id: number): Observable<SupplierCreditNoteModel | null> {
    this._store.dispatch(SupplierCreditNoteAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<SupplierCreditNoteModel | null>;
  }
}
