import { PaginationAdapter } from '@red/data-access';
import { createAction, props } from '@ngrx/store';
import { JournalEntryModel } from '@shared/data-access/models';
import { IQuerySearch } from '@shared/data-access/interfaces';
// items
export const loadItems = createAction('[JournalEntry] Load items', props<{ filters: IQuerySearch }>());
export const loadItemsSuccess = createAction('[JournalEntry] Load items success', props<{ data: PaginationAdapter<JournalEntryModel> }>());

// item
export const loadItem = createAction('[JournalEntry] Load item', props<{ id: number }>());
export const loadItemSuccess = createAction('[JournalEntry] Load item success', props<{ item: JournalEntryModel }>());
