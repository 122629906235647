import { createAction, props } from '@ngrx/store';
import { CommissionMatrixModel } from '@shared/data-access/models';


export const getDetail = createAction(
  '[COMM MATRIX] GET DETAIL',
  props<{item:CommissionMatrixModel}>()
);


