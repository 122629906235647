import { createFeature, createReducer, on } from '@ngrx/store';
import { PaymentModel } from '@shared/data-access/models';
import { PaymentAction } from '../actions';

export const featureName = 'payment';

export interface PaymentState {
  item: PaymentModel | null;
}
export const initialState: PaymentState = {
  item: null,
};
export const paymentFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(PaymentAction.loadItemSuccess, (state, prop: { item: PaymentModel }) => ({ ...state, item: prop.item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = paymentFeature;
