import { Injectable } from '@angular/core';
import { KeyAppointmentApiService } from '@cms/apis';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, map } from 'rxjs';
import { KeyAppointmentListAction } from '../actions';

@Injectable()
export class KeyAppointmentListEffect {
  loadItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(KeyAppointmentListAction.loadItem),
      exhaustMap(action => {
        return this._apiService.get(action.id).pipe(map(item => KeyAppointmentListAction.loadItemSuccess({ item })));
      })
    );
  });
  constructor(private _apiService: KeyAppointmentApiService, private _actions$: Actions, private _store: Store) {}
}
