import { createFeature, createReducer, on } from '@ngrx/store';
import { ProjectHICommMatrixModel } from '@shared/data-access/models';
import { ProjectCommissionMatrixHIAction } from '../actions';

export const featureName = 'projectCommissionMatrixHI';

export interface DataState {
  detail: ProjectHICommMatrixModel | null;
}
export const initialState: DataState = {
  detail: null,
};

export const projectCommissionMatrixHIFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(ProjectCommissionMatrixHIAction.getDetail, (state, prop: { item: ProjectHICommMatrixModel }) => ({ ...state, detail: prop.item }))
  ),
});

export const { name, reducer } = projectCommissionMatrixHIFeature;
