import { createAction, props } from '@ngrx/store';
import { LedgerAccountModel, LedgerPathModel } from '@shared/data-access/models';

export const getAccountsAction = createAction('[CHART OF ACCOUNT] GET ACTION TITLE', props<{ title: string }>());

export const detectFilterAction = createAction('[CHART OF ACCOUNT] DETECT FILTER EVENT', props<{ event: boolean }>());

export const getLeaderSuccess = createAction(
  '[CHART OF ACCOUNT] GET PATH SUCCESS',
  props<{ accountItemId: number; pathFull: string; result: LedgerPathModel[]; level: number; name: string }>()
);

export const getLeader = createAction('[CHART OF ACCOUNT] GET PATH', props<{ accountItemId: number }>());

export const countMemberFailure = createAction('[CHART OF ACCOUNT] PATH FAILURE', props<{ error: any }>());

export const getDetail = createAction('[CHART OFF ACCOUNT] GET DETAIL', props<{ item: LedgerAccountModel }>());

export const searchByKeywork = createAction('[CHART OFF ACCOUNT] SEARCH KEYWORK', props<{ keywork: string }>());
