import { ResaleAdjustmentApiService } from '@cms/apis';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ResaleAdjustmentModel } from '@shared/data-access/models';
import { ResaleAdjustmentAction } from '@cms/stores';
import { forkJoin, map, Observable, tap } from 'rxjs';
import { ApiServiceForDetailResolver } from '@shared/core';
import { IResaleAdjustment } from '@shared/data-access/interfaces';
import { ResaleAdjustmentDataDto, ResaleAdjustmentUpdateDto } from '@shared/data-access/dto';

@Injectable({
  providedIn: 'root',
})
export class ResaleAdjustmentService implements ApiServiceForDetailResolver {
  constructor(private _apiService: ResaleAdjustmentApiService, private _store: Store) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get @ResaleAdjustmentModel by id
   */
  getById(id: number): Observable<ResaleAdjustmentModel> {
    return this._apiService.get(id).pipe(
      tap(item => {
        this._store.dispatch(ResaleAdjustmentAction.getDetail({ item }));
      })
    );
  }

  createFullData(data: ResaleAdjustmentModel) {
    const dataJson = ResaleAdjustmentModel.toJson(data) as IResaleAdjustment;
    const payload = ResaleAdjustmentDataDto.fromJson(dataJson);
    return this._apiService.create(payload).pipe(map(({ id }) => id));
  }

  updateFullData(data: ResaleAdjustmentModel) {
    const dataJson = ResaleAdjustmentModel.toJson(data) as IResaleAdjustment;
    const payload = ResaleAdjustmentUpdateDto.fromJson({
      group: 'full',
      data: dataJson,
    });
    const { id } = dataJson;
    return forkJoin([this._apiService.update(id, payload)]).pipe(map(() => id));
  }
}
