import { DecimalPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DEFAULT_NUMBER_FORMAT } from './enhanced-number.module';

@Pipe({ name: 'number' })
export class EnhancedNumberPipe extends DecimalPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) _locale: string, @Inject(DEFAULT_NUMBER_FORMAT) private _defaultNumberFormat?: string) {
    super(_locale);
  }

  override transform(value: number | string, digitsInfo?: string, locale?: string): string | null;
  override transform(value: null | undefined, digitsInfo?: string, locale?: string): null;
  override transform(value: number | string | null | undefined, digitsInfo?: string, locale?: string): string | null;
  override transform(value: number | string | null | undefined, digitsInfo?: string, locale?: string): string | null {
    return super.transform(value, digitsInfo ?? this._defaultNumberFormat, locale);
  }
}
