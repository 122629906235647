import { createFeature, createReducer, on } from "@ngrx/store";
import { CommissionLeadershipSchemeModel } from "@shared/data-access/models";
import { CommLeadershipSchemeAction } from "../actions";

export const featureName = 'commLeadershipScheme';

export interface CommLeadershipSchemeState {
  detail:CommissionLeadershipSchemeModel | null;
}
export const initialState:CommLeadershipSchemeState = {
  detail: null,
};
export const commLeadershipSchemeFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(CommLeadershipSchemeAction.getDetail,  (state, prop) => ({ ...state, detail: prop}))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = commLeadershipSchemeFeature;
