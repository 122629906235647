import { Injectable } from '@angular/core';
import { BankAccountApiService } from '@cms/apis';
import { FnWithCompany, WithCompany } from '@cms/backgrounds/switch-company';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BankAccountCreateDto } from '@shared/data-access/dto';
import { exhaustMap, map } from 'rxjs';
import { BankAccountAction } from '../actions';

@Injectable()
export class BankAccountEffect {
  loadItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(BankAccountAction.loadItem),
      exhaustMap(action => {
        return this.fetch(action.id).pipe(map(item => BankAccountAction.loadItemSuccess({ item })));
      })
    );
  });

  createItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(BankAccountAction.createItem),
      exhaustMap(action => {
        return this.create(action.data).pipe(map(item => BankAccountAction.createItemSuccess({ item })));
      })
    );
  });

  updateItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(BankAccountAction.updateItem),
      exhaustMap(action => {
        return this.update(action.id, action.data).pipe(map(item => BankAccountAction.updateItemSuccess({ item })));
      })
    );
  });

  viewAttachment$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(BankAccountAction.getAttachmentUrl),
      exhaustMap(action => {
        return this._apiService.getAttachmentUrl(action.data.id).pipe(map(url => BankAccountAction.getAttachmentUrlSuccess({ attachmentId: action.data.id, url })));
      })
    );
  });

  @FnWithCompany()
  fetch(id: number, @WithCompany() query: Record<string, any> = {}) {
    return this._apiService.get(id, query);
  }

  @FnWithCompany()
  create(@WithCompany() data: BankAccountCreateDto) {
    return this._apiService.create(data);
  }

  @FnWithCompany()
  update(id: number, @WithCompany() data: BankAccountCreateDto) {
    return this._apiService.update(id, data);
  }

  constructor(private _apiService: BankAccountApiService, private _actions$: Actions, private _store: Store) {}
}
