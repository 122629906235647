import { createFeature, createReducer, on } from '@ngrx/store';
import { BankAccountModel } from '@shared/data-access/models';
import { BankAccountAction } from '../actions';

export const featureName = 'bankAccount';

export interface BankAccountState {
  item: BankAccountModel | null;
}
export const initialState: BankAccountState = {
  item: null,
};
export const bankAccountFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(BankAccountAction.loadItemSuccess, (state, prop: { item: BankAccountModel }) => ({ ...state, item: prop.item })),
    on(BankAccountAction.createItemSuccess, (state, prop: { item: BankAccountModel }) => ({ ...state, item: prop.item })),
    on(BankAccountAction.updateItemSuccess, (state, prop: { item: BankAccountModel }) => ({ ...state, item: prop.item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = bankAccountFeature;
