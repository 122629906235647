import { createFeature, createReducer, on } from "@ngrx/store";
import { AppointmentTypeConfigModel, AppointmentTypeModel } from "@shared/data-access/models";
import { AppointmentTypeAction } from "../actions";

export const featureName = 'appointmentType';

export interface DataState {
  item:AppointmentTypeModel | null;
  configs:AppointmentTypeConfigModel[]

}
export const initialState:DataState = {
  item: null,
  configs:[]
};
export const appointmentTypeFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(AppointmentTypeAction.getDetail,  (state, prop:{item:AppointmentTypeModel}) => ({ ...state, item: prop.item})),
    on(AppointmentTypeAction.getSystemConfigsSuccess,  (state, prop:{data:AppointmentTypeConfigModel[]}) =>{
      return  { ...state, configs: prop.data}
    }),
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = appointmentTypeFeature;
