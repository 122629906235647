import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ManagementCommissionReducer } from '../reducers';

export const selectManagementCommissionFeature =
  createFeatureSelector<ManagementCommissionReducer.ManagmentCommissionState>(ManagementCommissionReducer.featureName);

export const selectManagementCommissionDetail = createSelector(selectManagementCommissionFeature, ({ item }) => item);

export const selectManagementCommissionId = createSelector(selectManagementCommissionFeature, ({ item }) => item?.id);

export const selectPdOverride = createSelector(selectManagementCommissionFeature, ({ item }) => ({
  data: item?.relatedPd.pdOverride ?? [],
  active: item?.activePdOverride ?? false,
}));

export const selectPdReferral = createSelector(selectManagementCommissionFeature, ({ item }) => ({
  data: item?.relatedPd.pdReferral ?? [],
  active: item?.activePdReferal ?? false,
}));

export const selectPdManagement = createSelector(selectManagementCommissionFeature, ({ item }) => ({
  data: item?.relatedPd.pdManagement ?? [],
  active: item?.activePdManagement ?? false,
}));

export const selectItemCreating = createSelector(selectManagementCommissionFeature, ({ itemCreating }) => itemCreating);

export const selectPdOverrideCreating = createSelector(selectItemCreating, ({ pdOverride, activePdOverride }) => ({
  data: pdOverride,
  active: activePdOverride,
}));

export const selectPdReferralCreating = createSelector(selectItemCreating, ({ pdReferral, activePdReferal }) => ({
  data: pdReferral,
  active: activePdReferal,
}));

export const selectPdManagementCreating = createSelector(
  selectItemCreating,
  ({ pdManagement, activePdManagement }) => ({
    data: pdManagement,
    active: activePdManagement,
  })
);

export const selectSubmittingStatus = createSelector(
  selectManagementCommissionFeature,
  ({ submittingStatus }) => submittingStatus
);

export const selectIsActiveCreating = createSelector(selectItemCreating, ({ isActive }) => isActive);
