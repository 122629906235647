import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { filter, map, Observable, take } from 'rxjs';
import { BankVerificationInfoAction } from '../actions';
import { BankVerificationInfoReducer } from '../reducers';
import { BankVerificationInfoSelector } from '../selectors';
import { PersonalParticularModel, PersonalVerifyModel } from '@shared/data-access/models';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class BankVerificationInfoFacade {
  selectedItem$ = this._store.select(BankVerificationInfoSelector.selectBankVerificationInfo);
  list$ = this._store.select(BankVerificationInfoSelector.selectBankVerificationList);
  constructor(private _store: Store<BankVerificationInfoReducer.BankVerificationInfoState>) { }

  getById(id: number): Observable<PersonalVerifyModel[] | null> {
    this._store.dispatch(BankVerificationInfoAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val),
      take(1)
    );
  }

  updateList(list: PersonalParticularModel[]): void {
    this._store.dispatch(BankVerificationInfoAction.updateList({ list }));
  }

  getDetailById(id: number): Observable<PersonalParticularModel | null> {
    return this.list$.pipe(map(val => val?.find(item => item.id === id) ?? null));
  }
}
