import { Injectable } from '@angular/core';
import { BankVerificationInfoApiService } from '@cms/apis';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, map } from 'rxjs';
import { BankVerificationInfoAction } from '../actions';

@Injectable()
export class BankVerificationInfoEffect {
  loadItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(BankVerificationInfoAction.loadItem),
      exhaustMap(action => {
        return this._apiService.get(action.id).pipe(map(items => BankVerificationInfoAction.loadItemSuccess({ items })));
      })
    );
  });
  constructor(private _apiService: BankVerificationInfoApiService, private _actions$: Actions, private _store: Store) { }
}
