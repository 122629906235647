import { createFeature, createReducer, on } from '@ngrx/store';
import { MessageOutboxModel } from '@shared/data-access/models';
import { ResaleInvoiceDeliveryAction } from '../actions';

export const featureName = 'userAccount';

export interface DataState {
  detail: MessageOutboxModel | null;
}
export const initialState: DataState = {
  detail: null,
};
export const resaleInvoiceDeliveryFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(ResaleInvoiceDeliveryAction.getDetail, (state, prop: { item: MessageOutboxModel }) => ({ ...state, detail: prop.item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = resaleInvoiceDeliveryFeature;
