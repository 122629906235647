// core
import { createFeature, createReducer, on } from '@ngrx/store';

// share
import { RecruitmentModel } from '@shared/data-access/models';
import { RecruitmentAction } from '../actions';

export const featureName = 'recruitment';

export interface RecruitmentState {
  detail: RecruitmentModel | null;
}

export const initialState: RecruitmentState = {
  detail: null,
};

export const recruitmentFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(RecruitmentAction.loadDetailSuccess, (state, { detail }) => ({ ...state, detail }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = recruitmentFeature;
