import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ProfitCenterReducer } from "../reducers";

export const profitCenterFeature = createFeatureSelector<ProfitCenterReducer.DataState>(
  ProfitCenterReducer.featureName
);

export const selectFeatureGetPath = createSelector(
  profitCenterFeature,
  (state: ProfitCenterReducer.DataState) => state.path
);


export const selectFeatureProfitCentre = createSelector(
  profitCenterFeature,
  (state: ProfitCenterReducer.DataState) => state.item
);
