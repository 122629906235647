import { createFeatureSelector, createSelector } from "@ngrx/store";
import { EducationRecordReducer } from "../reducers";

export const selectEducationRecordFeature = createFeatureSelector<EducationRecordReducer.EducationRecordState>(
  EducationRecordReducer.featureName
);

export const selectFeatureEducationRecord = createSelector(
  selectEducationRecordFeature,
  (state: EducationRecordReducer.EducationRecordState) => state.items
);
