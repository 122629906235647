import { createFeature, createReducer, on } from "@ngrx/store";
import { AdditionalInformationModel } from "@shared/data-access/models";
import { SalespersonAdditionalInforAction } from "../actions";

export const featureName = 'salespersonAdditionalInfo';

export interface SalespersonAdditionalInfoState {
  items:AdditionalInformationModel[];
}
export const initialState: SalespersonAdditionalInfoState = {
  items: [],
};
export const salespersonAdditionalInfoFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(SalespersonAdditionalInforAction.getList,  (state,{payload}) => ({ ...state, items:payload}))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = salespersonAdditionalInfoFeature;
