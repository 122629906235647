import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isNil } from 'lodash-es';
import * as moment from 'moment';
import { Helper } from '../helper';
export type CompareType = 'lt' | 'lte' | 'gt' | 'gte';
export type ValueCompareType = 'number' | 'string';

import { isPossiblePhoneNumber } from 'libphonenumber-js';
import { isPhoneNumberValid } from './../../pipes/phone-number/phone-number.pipe';

export const urlRegex = /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/;
export const characterRegex = /[A-Za-z]/;
export const usernameRegex = /^[A-Za-z0-9_.]*$/;
export const letterOrNumberRegex = /^[A-Za-z0-9]*$/;
export const firstCharIsAlphabetic = /^[a-zA-Z]/;

export class RedrValidator {
  static containSpace(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    if ((control.value as string).indexOf(' ') >= 0) {
      return { containSpace: true };
    }

    return null;
  }

  static requireContainString(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    if (!characterRegex.test(control.value as string)) {
      return { requireContainString: true };
    }

    return null;
  }

  static whiteSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value || '').trim().length === 0) {
      return { whiteSpace: true };
    }

    return null;
  }

  static url(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    if (!urlRegex.test(control.value as string)) {
      return { url: true };
    }

    return null;
  }

  static mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  // static email(control: AbstractControl): ValidationErrors | null {
  //     if (!control.value) {
  //         return null;
  //     }
  //     const str = control.value;
  //     if (RedrEmailValidator.isEmail(str)) {
  //         return null;
  //     }
  //     return { email: true };

  // }

  static compare(controlName: string, matchingControlName: string, compareType: CompareType) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['compare']) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      const value = parseFloat(control.value);
      const matchingValue = parseFloat(matchingControl.value);
      // if (!value || !matchingValue) {
      //   matchingControl.setErrors(null);
      //   return;
      // }
      // set error on matchingControl if validation fails
      const operation = {
        lt: (a: number, b: number) => {
          return a < b;
        },
        lte: (a: number, b: number) => {
          return a <= b;
        },
        gt: (a: number, b: number) => {
          return a > b;
        },
        gte: (a: number, b: number) => {
          return a >= b;
        },
      };
      if (operation[compareType](value, matchingValue)) {
        matchingControl.setErrors(null);
      } else {
        matchingControl.setErrors({ compare: true });
      }
    };
  }

  static generateValidators(type: string): ValidatorFn[] {
    const validators = [Validators.required];
    switch (type) {
      case 'email':
        validators.push(Validators.email);
        break;
      case 'url':
        validators.push(RedrValidator.url);
        break;
    }
    return validators;
  }

  static compareDate(controlName: string, matchingControlName: string, compareType: CompareType, unit: 'day' | 'month' | 'year') {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      // if (matchingControl.errors && !matchingControl.errors.compare) {
      //     // return if another validator has already found an error on the matchingControl
      //     return;
      // }

      if (!control.value || !matchingControl.value) {
        // matchingControl.setErrors(null);
        return;
      }

      // set error on matchingControl if validation fails
      const operation = {
        lt: (differ: number) => {
          return differ < 0;
        },
        lte: (differ: number) => {
          return differ <= 0;
        },
        gt: (differ: number) => {
          return differ > 0;
        },
        gte: (differ: number) => {
          return differ >= 0;
        },
      };

      const controlValue = moment.isMoment(control.value) ? control.value : moment(control.value);

      const matchingControlValue = moment.isMoment(matchingControl.value) ? matchingControl.value : moment(matchingControl.value);

      const diff = controlValue.diff(matchingControlValue, `${unit}s` as moment.unitOfTime.Diff, true);

      if (operation[compareType](diff)) {
        matchingControl.setErrors(null);
      } else {
        matchingControl.setErrors({ compareDate: true });
      }
    };
  }

  static compareDateMomentFormat(controlName: string, matchingControlName: string, compareType: CompareType, unit: 'day' | 'month' | 'year') {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (!control.value || !matchingControl.value) {
        // matchingControl.setErrors(null);
        return;
      }

      // set error on matchingControl if validation fails
      const operation = {
        lt: (differ: number) => {
          return differ < 0;
        },
        lte: (differ: number) => {
          return differ <= 0;
        },
        gt: (differ: number) => {
          return differ > 0;
        },
        gte: (differ: number) => {
          return differ >= 0;
        },
      };

      const controlValue = moment(control.value, 'YYYY MM DD');

      const matchingControlValue = moment(matchingControl.value, 'YYYY MM DD');

      const diff = controlValue.diff(matchingControlValue, `${unit}s` as moment.unitOfTime.Diff, true);

      if (operation[compareType](diff)) {
        matchingControl.setErrors(null);
      } else {
        matchingControl.setErrors({ compareDate: true });
      }
    };
  }

  static max(max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value) || isEmptyInputValue(max)) {
        return null; // don't validate empty values to allow optional controls
      }
      const value = Helper.removeCommaFromText(control.value);
      // Controls with NaN values after parsing should be treated as not having a
      // maximum, per the HTML forms spec: https://www.w3.org/TR/html5/forms.html#attr-input-max
      return !isNaN(value) && value > max ? { max: { max: max, actual: control.value } } : null;
    };
  }

  static lessThan(max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // console.log(')lessThan')
      if (isEmptyInputValue(control.value) || isEmptyInputValue(max)) {
        return null; // don't validate empty values to allow optional controls
      }
      const value = Helper.removeCommaFromText(control.value);
      // Controls with NaN values after parsing should be treated as not having a
      // maximum, per the HTML forms spec: https://www.w3.org/TR/html5/forms.html#attr-input-max
      // console.log('lessThan', { max, value }, !isNaN(value) && value >= max);
      return !isNaN(value) && value >= max ? { lessThan: { lessThan: max, actual: control.value } } : null;
    };
  }

  static moreThan(min: number): ValidatorFn {
    // console.log(')moreThan')
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value) || isEmptyInputValue(min)) {
        return null; // don't validate empty values to allow optional controls
      }
      const value = parseFloat(control.value);
      // Controls with NaN values after parsing should be treated as not having a
      // maximum, per the HTML forms spec: https://www.w3.org/TR/html5/forms.html#attr-input-max
      // console.log('moreThan', { min, value }, !isNaN(value) && value <= min);
      return !isNaN(value) && value <= min ? { moreThan: { moreThan: min, actual: control.value } } : null;
    };
  }

  static min(min: number): ValidatorFn {
    // console.log('min -->', min);
    return (control: AbstractControl): ValidationErrors | null => {
      // console.log('min -->', min);
      if (isEmptyInputValue(control.value) || isEmptyInputValue(min)) {
        return null; // don't validate empty values to allow optional controls
      }
      const value = parseFloat(control.value);
      // console.log('min -->', value, min);
      // Controls with NaN values after parsing should be treated as not having a
      // minimum, per the HTML forms spec: https://www.w3.org/TR/html5/forms.html#attr-input-min
      return !isNaN(value) && value < min ? { min: { min: min, actual: control.value } } : null;
    };
  }

  static maxChar(max: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && control.value.trim().length > max) {
        return { maxChar: { max } };
      }
      return null;
    };
  }

  static minChar(min: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && control.value.trim().length < min) {
        return { minChar: { min } };
      }
      return null;
    };
  }

  static minLengthArray(min: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const { value } = control;
      if (Array.isArray(value)) {
        if (value.length < min) {
          return { minLengthArray: { min, actual: value.length } };
        }
      }
      return null;
    };
  }

  // static phoneNumber():ValidatorFn {
  //   return (control: AbstractControl): { [key: string]: any } | null => {
  //     const {
  //       value: {countryCode, phoneNumber},
  //     } = control;
  //     console.log('hello validate',countryCode,phoneNumber)
  //     if (phoneNumber  && isPossiblePhoneNumber(phoneNumber,{defaultCallingCode:countryCode})) {

  //       return { phoneNumber: true };
  //     }
  //     return null;
  //   };
  // }

  static phoneNumber(formGroup: AbstractControl): ValidationErrors | null {
    const phoneNumberControl = (formGroup as UntypedFormGroup).controls['phoneNumber'];
    if (formGroup.value) {
      const {
        value: { countryCode, phoneNumber },
      } = formGroup;

      // if (!isPhoneNumberValid(formGroup.value)) {
      //   return { phoneNumber: true };
      // }

      if (phoneNumber && !isPhoneNumberValid(formGroup.value)) {
        phoneNumberControl.setErrors({ phoneNumber: true });
        return { phoneNumber: true };
      }
    }

    // phoneNumberControl.setErrors(null) ;
    return null;
  }

  // Allow only alphabet letters, numbers, underscore and dot
  static username(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const value = control.value as string;
    const re = new RegExp(usernameRegex);

    if (!re.test(value)) {
      return { wrongUsername: true };
    }

    return null;
  }

  // First character must be alphabetic
  static firstCharacterIsAlphabetic(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const value = control.value as string;
    const re = new RegExp(firstCharIsAlphabetic);

    if (!re.test(value)) {
      return { firstCharIsAlphabetic: true };
    }

    return null;
  }

  // Contain only letters or number
  static onlyLetterOrNumber(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const value = control.value as string;

    const re = new RegExp(letterOrNumberRegex);

    if (!re.test(value)) {
      return { onlyLetterOrNumber: true };
    }

    return null;
  }

  static nricNumber(control: AbstractControl): ValidationErrors | null {
    const value: string | null = control.value;

    if (value && !Helper.isNricNumber(value)) {
      return { nricNumber: true };
    }

    return null;
  }

  static validateNricOnIdType(idControlName: string, nricControlName: string): (formGroup: UntypedFormGroup) => void {
    return (formGroup: UntypedFormGroup) => {
      const idControl = formGroup.controls[idControlName];
      const nricControl = formGroup.controls[nricControlName];
      const idValue = idControl?.value;
      const nricValue = nricControl?.value;

      if (!idControl || !idValue || !idValue['identification'] || !nricControl || !nricValue) return;

      const identification: string = idValue['identification'];
      const countableIdValues = ['NRIC', 'FIN'];

      if (countableIdValues.includes(identification.toUpperCase())) {
        nricControl.addValidators(RedrValidator.nricNumber);
      } else {
        nricControl.removeValidators(RedrValidator.nricNumber);
      }

      nricControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    };
  }

  static dateMinimum(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }

      const controlDate = moment(control.value, 'YYYY-MM-DD');

      if (!controlDate.isValid()) {
        return null;
      }

      const validationDate = moment(date);

      return controlDate.isAfter(validationDate) ? null : {
        'dateMinimum': {
          'dateMinimum': validationDate.format('YYYY-MM-DD'),
          'actual': controlDate.format('YYYY-MM-DD')
        }
      };
    };
  }

  static dateMaximum(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }

      const controlDate = moment(control.value, 'YYYY-MM-DD');

      if (!controlDate.isValid()) {
        return null;
      }

      const validationDate = moment(date);

      return controlDate.isBefore(validationDate) ? null : {
        'dateMaximum': {
          'dateMaximum': validationDate.format('YYYY-MM-DD'),
          'actual': controlDate.format('YYYY-MM-DD')
        }
      };
    };
  }

  static numbersOnly(control: AbstractControl): ValidationErrors | null {
    const value: string | null = control.value;

    if (value && !/^\d+$/.test(value)) {
      return { numbersOnly: true };
    }

    return null;
  }
}

function isEmptyInputValue(value: string | number | unknown[] | null): boolean {
  if (typeof value === 'number') {
    return false;
  }
  // we don't check for string here so it also works with arrays
  return isNil(value) || value.length === 0;
}
