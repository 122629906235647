import { createAction, props } from "@ngrx/store";
import { PaginationAdapter } from "@red/data-access";
import { IQuerySearch } from "@shared/data-access/interfaces";
import { PaymentReversalModel } from "@shared/data-access/models";

// items
export const loadItems = createAction('[PaymentReversal] Load items', props<{ filters: IQuerySearch }>());
export const loadItemsSuccess = createAction('[PaymentReversal] Load items success', props<{ data: PaginationAdapter<PaymentReversalModel> }>());

// item
export const loadItem = createAction('[PaymentReversal] Load item', props<{ id: number }>());
export const loadItemSuccess = createAction('[PaymentReversal] Load item success', props<{ item: PaymentReversalModel }>());
