import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { PAYMENT_STATUS } from '../data';
import { EPaymentStatus, InvoicePostingType } from '../enums';
import {
  IPayment,
  IPaymentMemo,
  IPaymentMemoContact,
  IPaymentMemoInvoice,
  IPaymentMemoPostingAccount,
  IPaymentOffset,
  IPaymentPosting,
  IPaymentPostingDataSource,
  IPaymentRecord,
  IPaymentRecordMetadata,
} from '../interfaces';
import { PaymentBatchType, PaymentMemoType, PaymentMode, PaymentType } from '../types';
import { ChequeCompactModel } from './cheque-compact.model';
import { ContactCustomerAndSupplierModel } from './contact-customer-and-supplier.model';
import { CreditNoteRecordModel } from './credit-note-enhanced.model';
import { LedgerAccountModel } from './ledger-account.model';
import { ProfitCentresModel } from './profit-centres.model';
import { DealValueModel } from './receipt.model';
import { SupplierInvoiceRecordModel } from './supplier-invoice-enhanced.model';
import { TaxInvoiceRecordModel } from './tax-invoice-enhanced.model';
import { TemplateModel } from './template.model';
import { UpdatedByModel } from './updated-by.model';
import { GstCategoryLookupModel } from './gst-category.model';

export class PaymentMemoContactModel extends BaseModel implements IPaymentMemoContact {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  receiveAccountId!: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  receiveAccount!: Partial<LedgerAccountModel>;

  @Expose()
  paidAccountId!: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  paidAccount!: Partial<LedgerAccountModel>;
}

export class PaymentRecordMetadataModel extends BaseModel implements IPaymentRecordMetadata {
  @Expose()
  taxPercent!: number;

  @Expose()
  isEdit!: boolean;
}

export class PaymentMemoPostingAccountModel extends BaseModel implements IPaymentMemoPostingAccount {
  @Expose()
  accountCode!: string;

  @Expose()
  accountId!: number;

  @Expose()
  accountName!: string;

  @Expose()
  @Transform(({ obj }) => {
    const normalized = `${obj.accountCode || ''} ${obj.accountName || ''}`;
    return normalized.trim();
  })
  normalizedAccountName!: string;
}


export class PaymentMemoInvoiceModel extends BaseModel implements IPaymentMemoInvoice {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  contactId!: number;

  @Expose()
  total!: number;

  @Expose()
  paidAmount!: number;

  @Expose()
  balanceDue!: number;

  // @Expose()
  // createdAt?: Date;

  // @Expose()
  // updatedAt?: Date;

  @Expose()
  createdAt?: string;

  @Expose()
  updatedAt?: string;

  @Expose()
  @Type(() => PaymentMemoContactModel)
  contact!: PaymentMemoContactModel;
}

export class PaymentMemoModel extends BaseModel implements IPaymentMemo {
  @Expose()
  id!: number;

  @Expose()
  itemCode!: string;

  @Expose()
  description!: string;

  @Expose()
  total!: number;

  @Expose()
  paidAmount!: number;

  @Expose()
  balanceDue!: number;

  @Expose()
  @Type(() => PaymentMemoInvoiceModel)
  invoice!: PaymentMemoInvoiceModel;

  @Expose()
  // updatedAt?: Date;
  updatedAt?: string;

  @Expose()
  @Type(() => PaymentMemoPostingAccountModel)
  posting!: PaymentMemoPostingAccountModel
}

export class PaymentModel extends BaseModel implements IPayment {
  @Expose()
  id!: number;

  @Expose()
  businessUnitId!: number;

  @Expose()
  status!: EPaymentStatus;

  @Expose()
  type!: PaymentType;

  @Expose()
  mode!: PaymentMode;

  @Expose()
  code!: string;

  @Expose()
  payerName!: string;

  @Expose()
  contactId!: number;

  @Expose()
  @Type(() => ContactCustomerAndSupplierModel)
  contact!: ContactCustomerAndSupplierModel;

  @Expose()
  accountId!: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  // @Type(() => Date)
  // paymentDate!: Date;
  paymentDate!: string;

  @Expose()
  paymentBatchId!: number;

  @Expose()
  paymentBatchCode!: string;

  @Expose()
  paymentBatchType!: PaymentBatchType;

  @Expose()
  @Default('SGD')
  currency!: string;

  @Expose()
  @Type(() => ChequeCompactModel)
  cheque?: ChequeCompactModel;

  @Expose()
  @Type(() => Number)
  chequeId?: number;

  @Expose()
  reference?: string;

  @Expose()
  @Type(() => Number)
  templateId!: number;

  @Expose()
  template?: TemplateModel;

  @Expose()
  totalAmount?: number;

  @Expose()
  inClosedPeriod?: boolean;

  @Expose()
  isBlocked?: boolean;

  @Expose()
  isSentMail?: boolean;

  // @Expose()
  // @Type(() => Date)
  // createdAt!: Date;

  // @Expose()
  // @Type(() => Date)
  // updatedAt!: Date;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy?: UpdatedByModel;

  @Expose()
  @Transform(({ obj }) => {
    const nonBatchPayment = !obj.paymentBatchType;
    const validStatus = [EPaymentStatus.confirmed].includes(obj.status);
    return validStatus && !obj.inClosedPeriod && nonBatchPayment && !obj.isBlocked;
  })
  canEdit!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    const nonBatchPayment = !obj.paymentBatchType;
    const validStatus = [EPaymentStatus.confirmed].includes(obj.status);
    return validStatus && !obj.inClosedPeriod && nonBatchPayment && !obj.isBlocked;
  })
  canCancel!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    const nonBatchPayment = !obj.paymentBatchType;
    const validStatus = [EPaymentStatus.confirmed, EPaymentStatus.cancelled].includes(obj.status);
    return validStatus && !obj.inClosedPeriod && nonBatchPayment && !obj.isBlocked;
  })
  canDelete!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    const nonBatchPayment = !obj.paymentBatchType;
    const validStatus = [EPaymentStatus.confirmed].includes(obj.status);
    const validPaymentType = ['SupplierPayment', 'CustomerRefund'].includes(obj.type);
    return validStatus && nonBatchPayment && validPaymentType && !obj.isSentMail;
  })
  canSendConfirmEmail!: boolean;

  get displayStatus() {
    return PAYMENT_STATUS[this.status];
  }
}

export class PaymentRecordModel extends BaseModel implements IPaymentRecord {
  @Expose()
  id!: number;

  @Expose()
  type!: string;

  @Expose()
  @Type(() => Number)
  paymentId!: number;

  @Expose()
  @Type(() => PaymentModel)
  payment!: PaymentModel;

  @Expose()
  @Type(() => Number)
  accountId!: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  @Type(() => Number)
  profitCenterId?: number;

  @Expose()
  @Type(() => ProfitCentresModel)
  profitCenter?: ProfitCentresModel;

  @Expose()
  paymentMemoType?: PaymentMemoType;

  @Expose()
  paymentMemoDetailId?: number;

  @Expose()
  @Type(() => SupplierInvoiceRecordModel)
  supplierInvoiceDetail?: SupplierInvoiceRecordModel;

  @Expose()
  @Type(() => CreditNoteRecordModel)
  customerCreditNoteDetail?: CreditNoteRecordModel;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => DealValueModel)
  debit!: DealValueModel;

  @Expose()
  @Type(() => DealValueModel)
  credit!: DealValueModel;

  @Expose()
  @Transform(({ obj }) => {
    return obj.debit?.amount > 0 ? 'debit' : 'credit';
  })
  recordMode!: 'debit' | 'credit';

  @Expose()
  isDefault!: boolean;

  @Expose()
  isTaxable?: boolean;

  @Expose()
  isAdvance?: boolean;

  @Expose()
  @Type(() => TaxInvoiceRecordModel)
  invoiceDetail!: TaxInvoiceRecordModel;

  @Expose()
  invoiceDetailId!: number;

  @Expose()
  invoiceDetailNumber!: string;

  @Expose()
  invoiceDetailBalance?: number;

  @Expose()
  invoiceRate!: number;

  @Expose()
  invoiceNumber?: string;

  @Expose()
  invoiceBalance?: number;

  @Expose()
  taxDescription?: string;

  @Expose()
  taxAccountId?: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  taxAccount?: LedgerAccountModel;

  @Expose()
  gstCategory?: string;

  @Expose()
  @Type(() => GstCategoryLookupModel)
  gstCategoryLookup?: GstCategoryLookupModel;

  @Expose()
  @Transform(({ obj }) => obj.gstCharged ?? Number(obj.gstCategoryLookup?.gst_charged))
  @Type(() => Number)
  gstCharged?: number;

  @Expose()
  gstType?: 'gstExclusive' | 'gstInclusive';

  @Expose()
  amount!: number;

  @Expose()
  taxAmount!: number;

  @Expose()
  taxValue!: number;

  @Expose()
  taxUnit!: 'number' | 'percentage';

  @Expose()
  @Type(() => PaymentRecordMetadataModel)
  metadata!: PaymentRecordMetadataModel;

  @Expose()
  sequence?: number;

  @Expose()
  transactionNo?: string;

  @Expose()
  remarks?: string;

  @Expose()
  index?: number;

  // @Expose()
  // @Type(() => Date)
  // createdAt!: Date;

  // @Expose()
  // @Type(() => Date)
  // updatedAt!: Date;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  @Transform(({ obj }) => obj.supplierInvoiceDetail?.taxInvoiceId ?? obj.paymentMemoDetailId)
  sourceId?: number;

  @Expose()
  @Transform(({ obj }) => {
    if (['SCN', 'VCN'].includes(obj.type)) {
      return -obj.credit?.subTotalAmount;
    }
    return obj.debit?.subTotalAmount > 0 ? obj.debit?.subTotalAmount : -obj.credit?.subTotalAmount;
  })
  displayAmount!: number;

  @Expose()
  @Transform(({ obj }) => {
    const isTaxable = obj.isTaxable;
    const isGstValid = !['NR', 'EP', 'ZP', 'ME', 'OP'].includes(obj.gstCategory);
    const isGSTPercentValid = obj.metadata?.taxPercent && Number(obj.metadata?.taxPercent) !== 0;
    const isGSTValueValid =
      (obj.debit?.rateGroup?.tax && Number(obj.debit?.rateGroup?.tax) !== 0) || (obj.credit?.rateGroup?.tax && Number(obj.credit?.rateGroup?.tax) !== 0);
    return isTaxable && isGstValid && isGSTPercentValid && isGSTValueValid;
  })
  canShowTaxRow!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    return ['PUR', 'VI', 'VCN', 'CN', 'AR', ''].includes(obj.type);
  })
  canEdit!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    return ['PUR', 'VI', 'VCN', 'CN', 'AR', ''].includes(obj.type);
  })
  canDelete!: boolean;
}

export class PaymentPostingModel extends BaseModel implements IPaymentPosting {
  @Expose()
  id!: number;

  @Expose()
  @Type(() => Number)
  paymentId!: number;

  @Expose()
  @Type(() => PaymentModel)
  payment!: PaymentModel;

  @Expose()
  @Type(() => Number)
  accountId!: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  @Type(() => Number)
  profitCenterId!: number;

  @Expose()
  @Type(() => ProfitCentresModel)
  profitCenter!: ProfitCentresModel;

  @Expose()
  description!: string;

  @Expose()
  credit!: number;

  @Expose()
  debit!: number;

  @Expose()
  amount!: number;

  @Expose()
  sequence?: number;

  @Expose()
  type?: `${InvoicePostingType}`;

  @Expose()
  taxUnit?: 'number' | 'percentage';

  @Expose()
  taxValue?: number;

  @Expose()
  taxDescription!: string;

  @Expose()
  @Type(() => LedgerAccountModel)
  taxAccount?: LedgerAccountModel;

  @Expose()
  taxAccountId?: number;

  @Expose()
  isTaxable?: boolean;
}

export class PaymentPostingDataSourceModel extends BaseModel implements IPaymentPostingDataSource {
  @Expose()
  @Type(() => PaymentPostingModel)
  postings!: PaymentPostingModel[];

  @Expose()
  total!: number;
}

export class PaymentDataSource extends BaseModel {
  @Expose()
  @Type(() => PaymentRecordModel)
  items!: PaymentRecordModel[];

  @Expose()
  @Type(() => PaymentPostingModel)
  postings!: PaymentPostingModel[];

  @Expose()
  total!: number;
}

export class PaymentOffsetModel extends BaseModel implements IPaymentOffset {
  @Expose()
  type!: string;

  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  ref!: string;

  @Expose()
  @Type(() => Number)
  amount!: number;

  @Expose()
  // date!: Date;
  date!: string;
}
