import { BaseModel } from '@red/data-access';
import {  Expose, Type } from "class-transformer";
import { IBusinessUnitMetaData } from "../interfaces/business-unit-metadata.interface";
import { PaymentInfoModel } from "./payment-info.model";


export class BusinessUnitMetaData extends BaseModel implements IBusinessUnitMetaData {
    @Expose()
  countryCode!: string;
    @Expose()
  phoneNumber!: string;
    @Expose()
  licenseNumber!: string;

    @Expose()
    @Type(() => PaymentInfoModel)
    paymentInfo!: PaymentInfoModel;
    @Expose()
  postalCode!: string;
    @Expose()
  unitNumber!: string;
    @Expose()
  blockNumber!: string;
    @Expose()
  streetName!: string;
    @Expose()
  buildingName!: string;
    @Expose()
  floorNumber!: string;
    @Expose()
  gstRegNo!: string;
}
