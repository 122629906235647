import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationItem } from '@red/components/navigation';
import { cloneDeep } from 'lodash-es';
import { Observable, of, ReplaySubject, tap } from 'rxjs';
import { compactNavigation, defaultNavigation } from '../data/navigation.data';
import { NavigationGroup } from './navigation.types';

@Injectable({
    providedIn: 'root'
})
export class NavigationService
{
    protected _navigation: ReplaySubject<NavigationGroup> = new ReplaySubject<NavigationGroup>(1);
    protected readonly _compactNavigation: NavigationItem[] = compactNavigation;
    protected readonly _defaultNavigation: NavigationItem[] = defaultNavigation;
    /**
     * Constructor
     */
    constructor(protected _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<NavigationGroup>
    {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<NavigationGroup>
    {
      // Fill compact navigation children using the default navigation
      this._compactNavigation.forEach((compactNavItem) => {
        this._defaultNavigation.forEach((defaultNavItem) => {
            if ( defaultNavItem.id === compactNavItem.id )
            {
                compactNavItem.children = cloneDeep(defaultNavItem.children);
            }
        });
      });
      return of( {
          compact   : cloneDeep(this._compactNavigation),
          default   : cloneDeep(this._defaultNavigation)
      }).pipe(
        tap((navigation) => {
            this._navigation.next(navigation);
        })
    )
    }
}
