import { createFeature, createReducer, on } from '@ngrx/store';
import { ProjectModel } from '@shared/data-access/models';
import { ProjectSetupAction } from '../actions';

export const featureName = 'projectSetup';

export interface DataState {
  items: ProjectModel[];
  detail: ProjectModel | null;
  totalUnits: Map<number, { value: number; lastUpdatedAt: number }>;
}
export const initialState: DataState = {
  items: [],
  detail: null,
  totalUnits: new Map<number, { value: number; lastUpdatedAt: number }>(),
};
export const projectSetupFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(ProjectSetupAction.getList, (state, prop: { items: ProjectModel[] }) => ({ ...state, items: prop.items })),
    on(ProjectSetupAction.getDetail, (state, prop: { item: ProjectModel }) => {
      console.log('Go detail!');
      return { ...state, detail: prop.item };
    }),
    on(ProjectSetupAction.getTotalUnitSuccess, (state, prop: { projectId: number; total: number }) => {
      const totalUnits = state.totalUnits;
      totalUnits.set(prop.projectId, { value: prop.total, lastUpdatedAt: new Date().getTime() });
      return {
        ...state,
        totalUnits,
      };
    })
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = projectSetupFeature;
