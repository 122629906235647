import { createFeature, createReducer, on } from '@ngrx/store';
import {
  PersonalParticularModel,
  ProjectTeamMemberModel,
  ProjectTransactionModel,
  ProjectTransactionRelatedAgentModel,
  ProjectTransactionSummaryModel,
  SplitMatrixModel,
  SplitPartModel,
} from '@shared/data-access/models';
import { ProjectTransactionAction } from '../actions';
import { round, uniq, uniqBy } from 'lodash-es';
import { IProjectTransactionRelatedAgent } from '@shared/data-access/interfaces';

export const featureName = 'projectTransaction';

export interface DataState {
  item: ProjectTransactionModel | null;
  cobrokeLeaders: ProjectTransactionRelatedAgentModel[];
  mainLeaders: ProjectTransactionRelatedAgentModel[];
  combinedLeaders: ProjectTransactionRelatedAgentModel[];
  agreementDate: Date | null;
  selectedSalespersonIds: number[];
  selectedExternalIds: number[];
  summary: ProjectTransactionSummaryModel | null;
}
export const initialState: DataState = {
  item: null,
  cobrokeLeaders: [],
  mainLeaders: [],
  combinedLeaders: [],
  agreementDate: null,
  selectedSalespersonIds: [],
  selectedExternalIds: [],
  summary: null,
};

const splitMatrixDefault = (salesperson: PersonalParticularModel) => {
  const splitMatrixDefault = SplitMatrixModel.createEmpty();
  let { tiers, parties } = splitMatrixDefault;
  tiers = tiers.map((tier, index) => {
    if (index === 0) {
      tier = SplitPartModel.merge(tier, { salespersonId: salesperson.id, salesperson, percentage: 100 });
    } else {
      tier = SplitPartModel.merge(tier, { percentage: 0 });
    }
    return tier;
  });
  parties = parties.map(party => SplitPartModel.merge(party, { percentage: 0 }));
  return SplitMatrixModel.merge(splitMatrixDefault, { tiers, parties });
};

const mapTeamMembersToLeaders = (teamMembers: ProjectTeamMemberModel[]) => {
  return teamMembers.map(member =>
    ProjectTransactionRelatedAgentModel.fromJson<ProjectTransactionRelatedAgentModel, Partial<IProjectTransactionRelatedAgent>>({
      agent: member.salesperson,
      agentId: member.salespersonId,
      teamId: member.teamId,
      tiers: member.salesperson.commissionScheme?.splitMatrices?.[0]?.tiers ?? splitMatrixDefault(member.salesperson).tiers,
      parties: member.salesperson.commissionScheme?.splitMatrices?.[0]?.parties ?? splitMatrixDefault(member.salesperson).parties,
      receivedPercent: 0,
    })
  );
};

const splitCommission = (leaders: ProjectTransactionRelatedAgentModel[]) => {
  let currentPercent = 0;
  return leaders.map((leader, index) => {
    let receivedPercent = round(100 / leaders.length, 4);
    if (index === leaders.length - 1) {
      receivedPercent = 100 - currentPercent;
    }
    currentPercent += receivedPercent;
    return ProjectTransactionRelatedAgentModel.merge(leader, { receivedPercent });
  });
};

export const projectTransactionFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(ProjectTransactionAction.getDetail, (state, prop: { item: ProjectTransactionModel }) => ({ ...state, item: prop.item })),
    on(ProjectTransactionAction.setAgreementDate, (state, prop: { date: Date }) => ({ ...state, agreementDate: prop.date })),
    on(ProjectTransactionAction.setMainLeadersFromTeamMembers, (state, { teamMembers }) => {
      const { cobrokeLeaders } = state;
      const mainLeaders = mapTeamMembersToLeaders(teamMembers);
      const combinedLeaders = splitCommission(uniqBy([...mainLeaders, ...cobrokeLeaders], 'agentId'));
      return { ...state, mainLeaders, combinedLeaders };
    }),
    on(ProjectTransactionAction.setMainLeaders, (state, { leaders }) => {
      const { cobrokeLeaders } = state;
      const mainLeaders = leaders;
      const combinedLeaders = uniqBy([...mainLeaders, ...cobrokeLeaders], 'agentId');
      return { ...state, mainLeaders, combinedLeaders };
    }),
    on(ProjectTransactionAction.clearMainLeaders, state => {
      const { cobrokeLeaders } = state;
      const combinedLeaders = splitCommission(cobrokeLeaders);
      return { ...state, mainLeaders: [], combinedLeaders };
    }),
    on(ProjectTransactionAction.addCobrokeLeadersFromTeamMembers, (state, { teamMembers }) => {
      const { mainLeaders } = state;
      const cobrokeLeaders = uniqBy([...state.cobrokeLeaders, ...mapTeamMembersToLeaders(teamMembers)], 'agentId');
      const combinedLeaders = splitCommission(uniqBy([...mainLeaders, ...cobrokeLeaders], 'agentId'));
      return { ...state, cobrokeLeaders, combinedLeaders };
    }),
    on(ProjectTransactionAction.setCobrokeLeaders, (state, { leaders }) => {
      const { mainLeaders } = state;
      const cobrokeLeaders = leaders;
      const combinedLeaders = uniqBy([...mainLeaders, ...cobrokeLeaders], 'agentId');
      return { ...state, cobrokeLeaders, combinedLeaders };
    }),
    on(ProjectTransactionAction.removeCobrokeLeaders, (state, { leaders }) => {
      const { mainLeaders } = state;
      const cobrokeLeaders = state.cobrokeLeaders.filter(x => !leaders.find(y => y.agentId === x.agentId));
      const combinedLeaders = splitCommission(uniqBy([...mainLeaders, ...cobrokeLeaders], 'agentId'));
      return { ...state, cobrokeLeaders, combinedLeaders };
    }),
    on(ProjectTransactionAction.clearCobrokerLeaders, state => {
      const { mainLeaders } = state;
      const combinedLeaders = splitCommission(mainLeaders);
      return { ...state, cobrokeLeaders: [], combinedLeaders };
    }),
    on(ProjectTransactionAction.addSelectedSalespersonIds, (state, { ids }) => {
      const selectedSalespersonIds = uniq([...state.selectedSalespersonIds, ...ids]);
      return { ...state, selectedSalespersonIds };
    }),
    on(ProjectTransactionAction.removeSelectedSalespersonIds, (state, { ids }) => {
      const selectedSalespersonIds = state.selectedSalespersonIds.filter(x => !ids.find(y => y === x));
      return { ...state, selectedSalespersonIds };
    }),
    on(ProjectTransactionAction.setSelectedSalespersonIds, (state, { ids }) => {
      const selectedSalespersonIds = ids;
      return { ...state, selectedSalespersonIds };
    }),
    on(ProjectTransactionAction.clearSelectedSalespersonIds, state => {
      return { ...state, selectedSalespersonIds: [] };
    }),
    on(ProjectTransactionAction.setExternalIds, (state, { ids }) => {
      const selectedExternalIds = ids;
      return { ...state, selectedExternalIds };
    }),
    on(ProjectTransactionAction.clearExternalIds, state => {
      return { ...state, selectedExternalIds: [] };
    }),
    on(ProjectTransactionAction.getSummarySuccess, (state, prop: { summary: ProjectTransactionSummaryModel }) => {
      return { ...state, summary: prop.summary };
    })
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = projectTransactionFeature;
