// core
import { exhaustMap, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// share
import { PersonalParticularApiService } from '@cms/apis';
import { PersonalParticularAction } from '../actions';

@Injectable()
export class PersonalParticularEffect {
  loadItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(PersonalParticularAction.loadItem),
      exhaustMap(action => {
        return this._apiService.get(action.id).pipe(map(item => PersonalParticularAction.loadItemSuccess({ item })));
      })
    );
  });

  loadDetail$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(PersonalParticularAction.loadInfo),
      exhaustMap(() => {
        return this._apiService.getInfo().pipe(map(info => PersonalParticularAction.loadInfoSuccess({ info })));
      })
    );
  });

  constructor(private readonly _apiService: PersonalParticularApiService, private readonly _actions$: Actions) {}
}
