import { createFeature, createReducer, on } from '@ngrx/store';
import { PromotionApprovalModel } from '@shared/data-access/models';
import { PromotionApprovalAction } from '../actions';

export const featureName = 'promotionApproval';

export interface PromotionApprovalState {
  item: PromotionApprovalModel | null;
}
export const initialState: PromotionApprovalState = {
  item: null,
};
export const promotionApprovalFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(PromotionApprovalAction.loadItemSuccess, (state, prop: { item: PromotionApprovalModel }) => ({ ...state, item: prop.item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = promotionApprovalFeature;
