import { PaginationAdapter } from '@red/data-access';
import { createAction, props } from '@ngrx/store';
import { DirectDebitPaymentModel } from '@shared/data-access/models';
import { IQuerySearch } from '@shared/data-access/interfaces';
// items
export const loadItems = createAction('[DirectDebitPayment] Load items', props<{ filters: IQuerySearch }>());
export const loadItemsSuccess = createAction('[DirectDebitPayment] Load items success', props<{ data: PaginationAdapter<DirectDebitPaymentModel> }>());

// item
export const loadItem = createAction('[DirectDebitPayment] Load item', props<{ id: number }>());
export const loadItemSuccess = createAction('[DirectDebitPayment] Load item success', props<{ item: DirectDebitPaymentModel }>());
