import { createFeature, createReducer, on } from '@ngrx/store';
import { AssetDepreciationModel } from '@shared/data-access/models';
import { AssetDepreciationAction } from '../actions';

export const featureName = 'assetDepreciation';

export interface AssetDepreciationState {
  item: AssetDepreciationModel | null;
  newDepreciation: AssetDepreciationModel | null;
}
export const initialState: AssetDepreciationState = {
  item: null,
  newDepreciation: null,
};
export const assetDepreciationFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(AssetDepreciationAction.loadItemSuccess, (state, prop: { item: AssetDepreciationModel }) => ({ ...state, item: prop.item })),
    on(AssetDepreciationAction.loadDepreciationSuccess, (state, prop: { data: AssetDepreciationModel }) => ({ ...state, newDepreciation: prop.data }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = assetDepreciationFeature;
