import { cloneDeep } from 'lodash-es';
import { NavigationGroup } from './../../core/navigation/navigation.types';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CimbNavigationService, NavigationItem, NavigationItemRouterLinkService, VerticalNavigationComponent } from '@red/components/navigation';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '../../core/navigation/navigation.service';
import { RedMediaWatcherService } from '@red/components/media-watcher';

@Component({
  selector: 'cms-classic-layout',
  templateUrl: './classic-layout.component.html',
  styleUrls: ['./classic-layout.component.scss'],
})
export class ClassicLayoutComponent implements OnInit, OnDestroy {
  isScreenSmall!: boolean;
  navigation!: NavigationGroup;
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _navigationService: NavigationService,
    private _navItemRouterLinkService: NavigationItemRouterLinkService,
    private _cimbNavigationService: CimbNavigationService,
    private _mediaWatcherService: RedMediaWatcherService
  ) {}
  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to navigation data
    this._navigationService.navigation$.pipe(takeUntil(this._unsubscribeAll)).subscribe((navigation: NavigationGroup) => {
      this.navigation = navigation;
    });

    this._navItemRouterLinkService.onLinkUpdate().pipe(takeUntil(this._unsubscribeAll)).subscribe((fn)=> {
      const navigation = cloneDeep(this.navigation);
      const newNavigation = {
        compact:navigation.compact.map(item => this.updateLink(item,fn)),
        default:navigation.default.map(item => this.updateLink(item,fn))
      }
      this.navigation = newNavigation
    })

    // Subscribe to media changes
    this._mediaWatcherService.onMediaChange$.pipe(takeUntil(this._unsubscribeAll)).subscribe(({ matchingAliases }) => {
      // Check if the screen is small
      this.isScreenSmall = !matchingAliases.includes('lg');
      this._cimbNavigationService.getComponent<VerticalNavigationComponent>('mainNavigation')?.closeAside();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation = this._cimbNavigationService.getComponent<VerticalNavigationComponent>(name);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  updateLink(item:NavigationItem, fn:(oldLink:string) => string):NavigationItem{
    if(item.link){
      item.link = fn(item.link)
    }
    if(item.children){
      item.children = item.children.map(child => this.updateLink(child,fn))
    }
    return item
  }
}
