import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthService } from './auth.service';
import { HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { LOGIN_URL } from './constants';
import { FORCED_LOGOUT_FN } from '@shared/features/auth';

@NgModule()
export class AuthModule {
  static forRoot(config: { urlLogin: string }): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: LOGIN_URL,
          useValue: config.urlLogin,
        },
        {
          provide: FORCED_LOGOUT_FN,
          useFactory: forcedLogoutFn,
        },
        AuthService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
      ],
    };
  }
}
export function forcedLogoutFn(error: HttpErrorResponse): boolean {
  if (error.status === 401 && [30002, 30003].some(err => err === error.error?.errorCode)) {
    return true;
  }
  return false;
}
