import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CustomerReportReducer } from "../reducers";

export const selectCustomerReportFeature = createFeatureSelector<CustomerReportReducer.DataState>(
  CustomerReportReducer.featureName
);

export const selectFeatureCustomerReport = createSelector(
  selectCustomerReportFeature,
  (state: CustomerReportReducer.DataState) => state.item
);

