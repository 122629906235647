import { Injectable } from '@angular/core';
import { AssetDisposalApiService } from '@cms/apis';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, map } from 'rxjs';
import { AssetDisposalAction } from '../actions';

@Injectable()
export class AssetDisposalEffect {
  loadItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AssetDisposalAction.loadItem),
      exhaustMap(action => {
        return this._apiService.get(action.id).pipe(map(item => AssetDisposalAction.loadItemSuccess({ item })));
      })
    );
  });
  constructor(private _apiService: AssetDisposalApiService, private _actions$: Actions, private _store: Store) {}
}
