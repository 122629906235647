import { PaginationAdapter } from '@red/data-access';
import { createAction, props } from '@ngrx/store';
import { AccountingPeriodsModel } from '@shared/data-access/models';
import { IQuerySearch } from '@shared/data-access/interfaces';
// items
export const loadItems = createAction('[AccountingPeriods] Load items', props<{ filters: IQuerySearch }>());
export const loadItemsSuccess = createAction('[AccountingPeriods] Load items success', props<{ data: PaginationAdapter<AccountingPeriodsModel> }>());

// item
export const loadItem = createAction('[AccountingPeriods] Load item', props<{ id: number }>());
export const loadItemSuccess = createAction('[AccountingPeriods] Load item success', props<{ item: AccountingPeriodsModel }>());
