import { createAction, props } from '@ngrx/store';
import { IQuerySearch } from '@shared/data-access/interfaces';
import { ProjectTeamMemberModel, ProjectTransactionModel, ProjectTransactionRelatedAgentModel, ProjectTransactionSummaryModel } from '@shared/data-access/models';

export const getDetail = createAction('[PROJECT TRANSACTION] GET DETAIL', props<{ item: ProjectTransactionModel }>());

export const setMainLeadersFromTeamMembers = createAction(
  '[PROJECT TRANSACTION] SET MAIN LEADERS FROM TEAM MEMBERS',
  props<{ teamMembers: ProjectTeamMemberModel[] }>()
);

export const setMainLeaders = createAction('[PROJECT TRANSACTION] SET MAIN LEADERS', props<{ leaders: ProjectTransactionRelatedAgentModel[] }>());

export const clearMainLeaders = createAction('[PROJECT TRANSACTION] CLEAR MAIN LEADERS');

export const addCobrokeLeadersFromTeamMembers = createAction(
  '[PROJECT TRANSACTION] ADD LEADERS FROM TEAM MEMBERS',
  props<{ teamMembers: ProjectTeamMemberModel[] }>()
);

export const setCobrokeLeaders = createAction('[PROJECT TRANSACTION] SET COBROKE LEADERS', props<{ leaders: ProjectTransactionRelatedAgentModel[] }>());

export const removeCobrokeLeaders = createAction('[PROJECT TRANSACTION] REMOVE LEADERS', props<{ leaders: ProjectTransactionRelatedAgentModel[] }>());

export const clearCobrokerLeaders = createAction('[PROJECT TRANSACTION] CLEAR COBROKE LEADERS');

export const setAgreementDate = createAction('[PROJECT TRANSACTION] SET AGREEMENT DATE', props<{ date: Date }>());

export const addSelectedSalespersonIds = createAction('[PROJECT TRANSACTION] ADD SELECTED SALESPERSON IDS', props<{ ids: number[] }>());

export const removeSelectedSalespersonIds = createAction('[PROJECT TRANSACTION] REMOVE SELECTED SALESPERSON IDS', props<{ ids: number[] }>());

export const setSelectedSalespersonIds = createAction('[PROJECT TRANSACTION] SET SELECTED SALESPERSON IDS', props<{ ids: number[] }>());

export const clearSelectedSalespersonIds = createAction('[PROJECT TRANSACTION] CLEAR SELECTED SALESPERSON IDS');

export const setExternalIds = createAction('[PROJECT TRANSACTION] SET EXTERNAL AGENTS IDS', props<{ ids: number[] }>());

export const clearExternalIds = createAction('[PROJECT TRANSACTION] CLEAR EXTERNAL AGENTS IDS');

export const getSummary = createAction('[PROJECT TRANSACTION] GET SUMMARY', props<{ transactionId: number; filters: IQuerySearch }>());

export const getSummarySuccess = createAction('[PROJECT TRANSACTION] GET SUMMARY SUCCESS', props<{ summary: ProjectTransactionSummaryModel }>());

export const getSummaryFailure = createAction('[PROJECT TRANSACTION] GET SUMMARY FAILURE', props<{ error: any }>());
