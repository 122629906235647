import { createFeature, createReducer, on } from '@ngrx/store';
import { CreditNoteModel } from '@shared/data-access/models';
import { CreditNoteAction } from '../actions';

export const featureName = 'creditNote';

export interface DataState {
  item: CreditNoteModel | null;
}

export const initialState: DataState = {
  item: null,
};

export const creditNoteFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(CreditNoteAction.loadItemSuccess, (state, prop: { item: CreditNoteModel }) => ({ ...state, item: prop.item }))
  ),
});

export const { name, reducer } = creditNoteFeature;
