import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap, of, switchMap } from 'rxjs';
import { MetadataApiService, ProjectApiService } from '@cms/apis';
import { ProjectSetupAction } from '../actions';

@Injectable()
export class ProjectEffects {
  totalUnit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectSetupAction.getTotalUnit),
      mergeMap(action =>
        this.projectApiService.getTotalUnits(action.projectId).pipe(
          map(data => ProjectSetupAction.getTotalUnitSuccess(data)),
          catchError(error => of(ProjectSetupAction.getTotalUnitFailure({ error })))
        )
      )
    );
  });
  constructor(private actions$: Actions, private projectApiService: ProjectApiService) {}
}
