import { createFeatureSelector, createSelector } from "@ngrx/store";
import { EmploymentHistoryReducer } from "../reducers";

export const selectEmploymentHistoryFeature = createFeatureSelector<EmploymentHistoryReducer.EmploymentHistoryState>(
  EmploymentHistoryReducer.featureName
);

export const selectFeatureEmploymentHistory = createSelector(
  selectEmploymentHistoryFeature,
  (state: EmploymentHistoryReducer.EmploymentHistoryState) => state.items
);
