import { createFeatureSelector, createSelector } from "@ngrx/store";
import { BonusDistributionReducer } from "../reducers";

export const selectBonusDistributionFeature = createFeatureSelector<BonusDistributionReducer.DataState>(
  BonusDistributionReducer.featureName
);

export const selectFeatureBonusDistribution = createSelector(
  selectBonusDistributionFeature,
  (state: BonusDistributionReducer.DataState) => state.item
);

