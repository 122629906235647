import { createFeature, createReducer, on } from '@ngrx/store';
import { BatchingApprovalModel } from '@shared/data-access/models';
import { BatchSearchEngineAction } from '../actions';

export const featureName = 'batchSearchEngine';

export interface BatchSearchEngineState {
  item: BatchingApprovalModel | null;
}

export const initialState: BatchSearchEngineState = {
  item: null,
};

export const batchSearchEngineFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(BatchSearchEngineAction.setItemDetail, (state, { item }) => ({
      ...state,
      item,
    }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = batchSearchEngineFeature;
