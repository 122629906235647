// core
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

// share
import { Ir8aAmountSelector } from '../selectors';
import { Ir8aAmountReducer } from '../reducers';
import { Ir8aAmountAction } from '../actions';

@Injectable()
export class Ir8aAmountFacade {
  salesperson$ = this._store.select(Ir8aAmountSelector.selectSalesperson);
  ir8aAmount$ = this._store.select(Ir8aAmountSelector.selectItem);

  constructor(private _store: Store<Ir8aAmountReducer.Ir8aAmountState>) {}

  getIr8aAmountById(id: number): void {
    this._store.dispatch(Ir8aAmountAction.loadItem({ id }));
  }

  getSalespersonById(id: number): void {
    this._store.dispatch(Ir8aAmountAction.loadSalesperson({ id }));
  }
}
