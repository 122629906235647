import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChartOfAccountsReducer } from '../reducers';

export const selectChartOfAccountsFeature = createFeatureSelector<ChartOfAccountsReducer.DataState>(ChartOfAccountsReducer.featureName);

export const selectFeatureActionTitle = createSelector(selectChartOfAccountsFeature, (state: ChartOfAccountsReducer.DataState) => state.title);

export const selectFeatureFilterEvent = createSelector(selectChartOfAccountsFeature, (state: ChartOfAccountsReducer.DataState) => state.event);

export const selectFeatureGetPath = createSelector(selectChartOfAccountsFeature, (state: ChartOfAccountsReducer.DataState) => state.path);

export const selectFeatureChartOfAccounts = createSelector(selectChartOfAccountsFeature, (state: ChartOfAccountsReducer.DataState) => state.item);

export const selectFeatureSearchByKeywork = createSelector(selectChartOfAccountsFeature, (state: ChartOfAccountsReducer.DataState) => state.keywork);
