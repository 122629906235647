import { createFeature, createReducer, on } from '@ngrx/store';
import { UserRoleModel } from '@shared/data-access/models';
import { UserRoleAction } from '../actions';

export const featureName = 'userRole';

export interface DataState {
  detail: UserRoleModel | null;
}
export const initialState: DataState = {
  detail: null,
};
export const userRoleFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(UserRoleAction.getDetail, (state, prop: { item: UserRoleModel }) => ({ ...state, detail: prop.item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = userRoleFeature;
