import { createFeature, createReducer, on } from "@ngrx/store";
import { AddressModel, DeveloperModel } from "@shared/data-access/models";
import { DeveloperAction } from "../actions";

export const featureName = 'developer';

export interface DataState {
  detail:DeveloperModel | null;
  addressDefault:Map<number,{data:AddressModel | null, lastUpdatedAt:number}>

}
export const initialState:DataState = {
  detail: null,
  addressDefault:new Map<number,{data:AddressModel, lastUpdatedAt:number}>()
};
export const developerFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(DeveloperAction.getDetail,  (state, prop:{item:DeveloperModel}) => ({ ...state, detail: prop.item})),
    on(DeveloperAction.getAddressDefaultSuccess,  (state, prop:{developerId:number,data:AddressModel | null}) => {
      const addressDefault = state.addressDefault;
      addressDefault.set(prop.developerId,{data:prop.data,lastUpdatedAt:new Date().getTime()})
      return {
        ...state,addressDefault
      }
    })
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = developerFeature;
