import { PaginationAdapter } from '@red/data-access';
import { createAction, props } from '@ngrx/store';
import { CustomerModel } from '@shared/data-access/models';
import { IQuerySearch } from '@shared/data-access/interfaces';
// items
export const loadItems = createAction('[Customer] Load items', props<{ filters: IQuerySearch }>());
export const loadItemsSuccess = createAction('[Customer] Load items success', props<{ data: PaginationAdapter<CustomerModel> }>());

// item
export const loadItem = createAction('[Customer] Load item', props<{ id: number }>());
export const loadItemSuccess = createAction('[Customer] Load item success', props<{ item: CustomerModel }>());
