import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ProfitSharingReducer } from "../reducers";

export const selectProfitSharingFeature = createFeatureSelector<ProfitSharingReducer.DataState>(
  ProfitSharingReducer.featureName
);

export const selectFeatureProfitSharing = createSelector(
  selectProfitSharingFeature,
  (state: ProfitSharingReducer.DataState) => state.detail
);
