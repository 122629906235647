import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectSetupReducer } from '../reducers';

export const selectProjectSetupFeature = createFeatureSelector<ProjectSetupReducer.DataState>(ProjectSetupReducer.featureName);

export const selectFeatureProjectSetupList = createSelector(selectProjectSetupFeature, (state: ProjectSetupReducer.DataState) => state.items);

export const selectFeatureProjectSetup = createSelector(selectProjectSetupFeature, (state: ProjectSetupReducer.DataState) => state.detail);

export const selectFeatureTotalUnits = createSelector(selectProjectSetupFeature, (state: ProjectSetupReducer.DataState) => state.totalUnits);
