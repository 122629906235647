// core
import { createAction, props } from '@ngrx/store';

// share
import { IQuerySearch } from '@shared/data-access/interfaces';
import { GceAmountModel } from '@shared/data-access/models';
import { PaginationAdapter } from '@red/data-access';

// items
export const loadItems = createAction('[GceAmount] Load items', props<{ filters: IQuerySearch }>());
export const loadItemsSuccess = createAction('[GceAmount] Load items success', props<{ data: PaginationAdapter<GceAmountModel> }>());

// item
export const loadItem = createAction('[GceAmount] Load item', props<{ id: number }>());
export const loadItemSuccess = createAction('[GceAmount] Load item success', props<{ item: GceAmountModel }>());
