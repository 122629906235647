import { createFeature, createReducer, on } from '@ngrx/store';
import { PersonalParticularModel, PersonalVerifyModel } from '@shared/data-access/models';
import { PersonalVerificationInfoAction } from '../actions';

export const featureName = 'personalVerificationInfo';

export interface PersonalVerificationInfoState {
  list: PersonalParticularModel[] | null;
  items: PersonalVerifyModel[] | null;
}
export const initialState: PersonalVerificationInfoState = {
  list: null,
  items: null,
};
export const personalVerificationInfoFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(PersonalVerificationInfoAction.loadItemSuccess, (state, prop: { items: PersonalVerifyModel[] }) => ({ ...state, items: prop.items })),
    on(PersonalVerificationInfoAction.updateList, (state, { list }) => ({ ...state, list }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = personalVerificationInfoFeature;
