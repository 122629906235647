import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SupplierReportReducer } from "../reducers";

export const selectSupplierReportFeature = createFeatureSelector<SupplierReportReducer.DataState>(
  SupplierReportReducer.featureName
);

export const selectFeatureSupplierReport = createSelector(
  selectSupplierReportFeature,
  (state: SupplierReportReducer.DataState) => state.item
);

