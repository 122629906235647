import { createFeature, createReducer, on } from '@ngrx/store';
import { TaxInvoiceModel } from '@shared/data-access/models';
import { TaxInvoiceAction } from '../actions';

export const featureName = 'taxInvoice';

export interface DataState {
  // Old code
  // taxInvoice: TaxInvoiceLegacyModel | null;
  // summaryModel: TaxInvoiceSummaryModel | null;
  // taxInvoiceDetailChange: TaxInvoiceDetailChanges | null;
  // taxInvoiceType: string;
  // gstReceivablePosting: TaxInvoiceSummaryModel | null;
  // templates: TemplateModel[];

  // New code
  item: TaxInvoiceModel | null;
}

export const initialState: DataState = {
  // taxInvoice: null,
  // summaryModel: null,
  // taxInvoiceDetailChange: null,
  // taxInvoiceType: CustomerOrSupplierType.CUSTOMER,
  // gstReceivablePosting: null,
  // templates: [],

  // New code
  item: null,
};

export const taxInvoiceFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    // on(TaxInvoiceAction.getTaxInvoiceDetail, (state, prop: { item: TaxInvoiceLegacyModel }) => ({ ...state, taxInvoice: prop.item })),
    // on(TaxInvoiceAction.getSummaryData, (state, prop: { summaryModel: TaxInvoiceSummaryModel }) => ({ ...state, summaryModel: prop.summaryModel })),
    // on(TaxInvoiceAction.createOrUpdateInvoiceDetails, (state, prop: { taxInvoiceDetailChange: TaxInvoiceDetailChanges }) => ({
    //   ...state,
    //   taxInvoiceDetailChange: prop.taxInvoiceDetailChange,
    // })),
    // on(TaxInvoiceAction.getGSTAndReceivable, (state, prop: { gstReceivablePosting: TaxInvoiceSummaryModel }) => ({
    //   ...state,
    //   gstReceivablePosting: prop.gstReceivablePosting,
    // })),
    // on(TaxInvoiceAction.getTaxInvoiceType, (state, prop: { taxInvoiceType: string }) => ({
    //   ...state,
    //   taxInvoiceType: prop.taxInvoiceType,
    // })),
    // on(TaxInvoiceAction.getTemplates, (state, prop: { templates: TemplateModel[] }) => ({
    //   ...state,
    //   templates: prop.templates,
    // })),

    // New code
    on(TaxInvoiceAction.loadItemSuccess, (state, prop: { item: TaxInvoiceModel }) => ({ ...state, item: prop.item }))
  ),
});

export const { name, reducer } = taxInvoiceFeature;
