import { LedgerAccountApiService } from '@cms/apis';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { ChartOfAccountsAction } from '../actions';

@Injectable()
export class ChartOfAccountEffects {
  path$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChartOfAccountsAction.getLeader),
      mergeMap(action =>
        this.ledgerAccountApiService.getPath(action.accountItemId).pipe(
          map(data => {
            const paths = new Array<string>();
            // let pathShort = new Array<string>();
            // let pathHighlight: string[] = [];

            data.forEach((value, index) => {
              // if (index < data.length - 1) {
              //   pathShort.push(value.name.split(" ").splice(0, 1).join(" ").concat('...'));
              // } else {
              //   pathHighlight.push(value.name)
              // }
              paths.push(value.name);
            });
            return ChartOfAccountsAction.getLeaderSuccess({
              accountItemId: action.accountItemId,
              pathFull: paths.join('/ ') as any,
              result: data,
              level: paths.length,
              name: paths[paths.length - 1],
            });
          }),
          catchError(error => of(ChartOfAccountsAction.countMemberFailure({ error })))
        )
      )
    );
  });

  constructor(private actions$: Actions, private ledgerAccountApiService: LedgerAccountApiService) {}
}
