import { from, tap } from 'rxjs';
import { map } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SWITCH_COMPANY_API_LOADER, SwitchCompanyLoaderService } from '@cms/backgrounds/switch-company';

@Injectable({
  providedIn: 'root',
})
export class SwitchCompanyGuard implements CanActivate, CanActivateChild, CanLoad {
  /**
   * Constructor
   */
  constructor(@Inject(SWITCH_COMPANY_API_LOADER) private _loader: SwitchCompanyLoaderService, private _router: Router) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const companyId = route.paramMap.get('companyId') ?? route.queryParamMap.get('companyId');
    return companyId ? this._check(Number(companyId)) : this._check();
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._check();
  }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this._check();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @private
   */
  private _check(companyId?: number): Observable<boolean> {
    // console.log('this._authService', this._authService);
    // Check the authentication status
    return this._loader.getCurrentSelected(companyId).pipe(
      switchMap(authenticated => {
        // If the user is authenticated...
        if (authenticated) {
          // Prevent the access
          return of(true);
        }

        // Allow the access
        return of(false);
      })
    );
  }
}
