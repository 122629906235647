import { createFeature, createReducer, on } from '@ngrx/store';
import { PersonalParticularModel, PersonalVerifyModel } from '@shared/data-access/models';
import { BankVerificationInfoAction } from '../actions';

export const featureName = 'bankVerificationInfo';

export interface BankVerificationInfoState {
  list: PersonalParticularModel[] | null;
  items: PersonalVerifyModel[] | null;
}
export const initialState: BankVerificationInfoState = {
  list: null,
  items: null,
};
export const BankVerificationInfoFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(BankVerificationInfoAction.loadItemSuccess, (state, prop: { items: PersonalVerifyModel[] }) => ({ ...state, items: prop.items })),
    on(BankVerificationInfoAction.updateList, (state, { list }) => ({ ...state, list }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = BankVerificationInfoFeature;
