// core
import { exhaustMap, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// share
import { Ir8aAmountApiService, PersonalParticularApiService } from '@cms/apis';
import { Ir8aAmountAction } from '../actions';

@Injectable()
export class Ir8aAmountEffect {
  loadItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(Ir8aAmountAction.loadItem),
      exhaustMap(action => {
        return this._ir8aAmountApiService.get(action.id).pipe(map(item => Ir8aAmountAction.loadItemSuccess({ item })));
      })
    );
  });

  loadSalesperson$ = createEffect(() =>
    this._actions$.pipe(
      ofType(Ir8aAmountAction.loadSalesperson),
      exhaustMap(action => {
        return this._personalParticularApiService.get(action.id).pipe(map(salesperson => Ir8aAmountAction.loadSalespersonSuccess({ salesperson })));
      })
    )
  );

  constructor(private _actions$: Actions, private _ir8aAmountApiService: Ir8aAmountApiService, private _personalParticularApiService: PersonalParticularApiService) {}
}
