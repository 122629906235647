import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChequeBookCreateDto } from '@shared/data-access/dto';
import { ChequeModel } from '@shared/data-access/models';
import { filter, Observable, take } from 'rxjs';
import { ChequeAction } from '../actions';
import { ChequeReducer } from '../reducers';
import { ChequeSelector } from '../selectors';

@Injectable()
export class ChequeFacade {
  selectedItem$ = this._store.select(ChequeSelector.selectCheque);
  canCreateBook$ = this._store.select(ChequeSelector.selectNewChequeBook);
  constructor(private _store: Store<ChequeReducer.ChequeState>) {}

  /**
   * Get @ChequeModel by id
   */
  getById(id: number): Observable<ChequeModel | null> {
    this._store.dispatch(ChequeAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<ChequeModel | null>;
  }
  loadItem(item: ChequeModel): void {
    this._store.dispatch(ChequeAction.loadItemSuccess({ item }));
  }
  create(data: ChequeBookCreateDto): void {
    this._store.dispatch(ChequeAction.createItems({ data }));
  }
}
