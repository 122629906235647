import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectConsultantReducer } from '../reducers';

export const selectProjectConsultantFeature = createFeatureSelector<ProjectConsultantReducer.DataState>(ProjectConsultantReducer.featureName);

export const selectFeatureProjectConsultant = createSelector(selectProjectConsultantFeature, (state: ProjectConsultantReducer.DataState) => state.detail);

export const selectFeatureCountMember = createSelector(selectProjectConsultantFeature, (state: ProjectConsultantReducer.DataState) => state.countMember);

export const selectFeatureGetLeader = createSelector(selectProjectConsultantFeature, (state: ProjectConsultantReducer.DataState) => state.leader);

export const selectFeatureLastUpdated = createSelector(selectProjectConsultantFeature, (state: ProjectConsultantReducer.DataState) => {
  return {
    updatedAt: state.updatedAt,
    updatedBy: state.updatedBy,
  };
});
