import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AppointmentTypeReducer } from "../reducers";

export const selectAppointmentTypeFeature = createFeatureSelector<AppointmentTypeReducer.DataState>(
  AppointmentTypeReducer.featureName
);

export const selectFeatureAppointmentType = createSelector(
  selectAppointmentTypeFeature,
  (state: AppointmentTypeReducer.DataState) => state.item
);


export const selectFeatureSystemConfigs = createSelector(
  selectAppointmentTypeFeature,
  (state: AppointmentTypeReducer.DataState) => state.configs
);
