import { Component } from '@angular/core';

@Component({
  selector: 'cms-empty-layout',
  templateUrl: './empty-layout.component.html',
  styleUrls: ['./empty-layout.component.scss']
})
export class EmptyLayoutComponent {

}
