import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { IAddressBuyerDto } from '../interfaces';

export class AddressDto extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  address!: string;

  @Expose()
  addressTo!: string;

  @Expose()
  addressType!: string;

  @Expose()
  countryId!: number;

  @Expose()
  country!: string;

  @Expose()
  postcode!: number;

  @Expose()
  isDefault!: boolean;

  @Expose()
  postal!: string;

  @Expose()
  unit!: string;

  @Expose()
  floor!: string;

  @Expose()
  building!: string;

  @Expose()
  noOfRooms!: string;

  @Expose()
  block!: string;

  @Expose()
  street!: string;

  @Expose()
  district!: string;

  @Expose()
  state!: string;

  @Expose()
  city!: string;
}

export class AddressBuyerDto extends BaseModel implements IAddressBuyerDto {
  @Expose()
  postal!: string;

  @Expose()
  address!: string;

  @Expose()
  addressLine1!: string;

  @Expose()
  addressLine2!: string;

  @Expose()
  addressLine3!: string;
}
