// core
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

// share
import { GceAmountSelector } from '../selectors';
import { GceAmountReducer } from '../reducers';
import { GceAmountAction } from '../actions';

@Injectable()
export class GceAmountFacade {
  gceAmount$ = this._store.select(GceAmountSelector.selectGceAmount);

  constructor(private readonly _store: Store<GceAmountReducer.GceAmountState>) {}

  getGceAmountById(id: number): void {
    this._store.dispatch(GceAmountAction.loadItem({ id }));
  }
}
