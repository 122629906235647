import { SelectSearchModule } from '@red/components/select-search';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwitchCompanyComponent } from './switch-company.component';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CompareWithIdModule } from '@shared/components/compare-with-id';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
const components = [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, RouterModule, ScrollingModule, SelectSearchModule];
const directives = [CompareWithIdModule];
@NgModule({
  declarations: [SwitchCompanyComponent],
  imports: [CommonModule, ...components, ...directives],
  exports: [SwitchCompanyComponent],
})
export class SwitchCompanyModule {
  static injector: Injector | null = null;
  constructor(public injector: Injector) {
    SwitchCompanyModule.injector = injector;
  }
}
