import { createFeature, createReducer, on } from '@ngrx/store';
import { ProjectConsultantModel, ProjectTeamMemberModel, UpdatedByModel } from '@shared/data-access/models';
import { ProjectConsultantAction } from '../actions';

export const featureName = 'projectConsultant';

export interface DataState {
  updatedAt: string | null;
  updatedBy: UpdatedByModel | null;
  detail: ProjectConsultantModel | null;
  countMember: Map<number, { value: number; lastUpdatedAt: number }>;
  leader: Map<number, { value: ProjectTeamMemberModel; lastUpdatedAt: number }>;
}
export const initialState: DataState = {
  updatedAt: null,
  updatedBy: null,
  detail: null,
  countMember: new Map<number, { value: number; lastUpdatedAt: number }>(),
  leader: new Map<number, { value: ProjectTeamMemberModel; lastUpdatedAt: number }>(),
};
export const projectConsultantFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(ProjectConsultantAction.getDetail, (state, prop: { item: ProjectConsultantModel }) => ({ ...state, detail: prop.item })),
    on(ProjectConsultantAction.countMemberSuccess, (state, prop: { teamId: number; total: number }) => {
      const countMember = state.countMember;
      countMember.set(prop.teamId, { value: prop.total, lastUpdatedAt: new Date().getTime() });
      return {
        ...state,
        countMember,
      };
    }),
    on(ProjectConsultantAction.getLeaderSuccess, (state, prop: { teamId: number; leader: ProjectTeamMemberModel }) => {
      const leader = state.leader;
      leader.set(prop.teamId, { value: prop.leader, lastUpdatedAt: new Date().getTime() });
      return {
        ...state,
        leader,
      };
    }),
    on(ProjectConsultantAction.setLastUpdated, (state, prop: { updatedAt: string; updatedBy: UpdatedByModel }) => {
      const isUpdated = isDateAAfterDateB(state.updatedAt, prop.updatedAt);
      if (isUpdated) return state;
      return {
        ...state,
        updatedAt: prop.updatedAt,
        updatedBy: prop.updatedBy,
      };
    }),
    on(ProjectConsultantAction.resetState, () => initialState),
    on(ProjectConsultantAction.resetUpdateState, state => ({ ...state, updatedAt: null, updatedBy: null }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = projectConsultantFeature;

function isDateAAfterDateB(dateA: string | null, dateB: string): boolean {
  if (!dateA) return false;
  const dateAObj = new Date(dateA);
  const dateBObj = new Date(dateB);
  return dateAObj.getTime() > dateBObj.getTime();
}
