import { ICountryPhoneNumber } from '@shared/data-access/interfaces/country-phone-number.interface';
import { createReducer, on, State } from "@ngrx/store";
import { PhoneNumberAction } from '../actions';
export interface PhoneNumberState {
  loading: boolean;
  countries: ICountryPhoneNumber[];
}
export const initialState: PhoneNumberState = {
  loading: false,
  countries: [],
};

export const phoneNumberReducer = createReducer(
  initialState,
  on(PhoneNumberAction.onCountriesLoading, state => ({ ...state, loading: true })),
  on(PhoneNumberAction.countriesLoaded, (state,{payload}) => ({ ...state, loading: false ,countries:payload})),
);
