import { PaginationAdapter } from '@red/data-access';
import { createAction, props } from '@ngrx/store';
import { AssetCategoryModel } from '@shared/data-access/models';
import { IQuerySearch } from '@shared/data-access/interfaces';
// items
export const loadItems = createAction('[AssetCategory] Load items', props<{ filters: IQuerySearch }>());
export const loadItemsSuccess = createAction('[AssetCategory] Load items success', props<{ data: PaginationAdapter<AssetCategoryModel> }>());

// item
export const loadItem = createAction('[AssetCategory] Load item', props<{ id: number }>());
export const loadItemSuccess = createAction('[AssetCategory] Load item success', props<{ item: AssetCategoryModel }>());
