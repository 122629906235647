import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AssetDisposalModel } from '@shared/data-access/models';
import { filter, Observable, take } from 'rxjs';
import { AssetDisposalAction } from '../actions';
import { AssetDisposalReducer } from '../reducers';
import { AssetDisposalSelector } from '../selectors';

@Injectable()
export class AssetDisposalFacade {
  selectedItem$ = this._store.select(AssetDisposalSelector.selectAssetDisposal);

  constructor(private _store: Store<AssetDisposalReducer.AssetDisposalState>) {}

  /**
   * Get @AssetDisposalModel by id
   */
  getById(id: number): Observable<AssetDisposalModel | null> {
    this._store.dispatch(AssetDisposalAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<AssetDisposalModel | null>;
  }
}
