import { BaseModel, Default, FormattedDateString, PhoneNumber, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { EClientType, ECommType, EFurnished, EGstType, EPaymentAdvice, EResaleAgentType, EResalePaymentMethod, EResalePropertyType, EResaleType } from '../enums';
import { AddressDto } from './address.dto';
import { GrossCommDto, InvoiceOptionDto, ResaleBillingCommissionDto, ResaleBillingMetadataDto, ResaleDocumentDto } from './resale-transaction.dto';
import { ContactAddressDto } from './contact-address.dto';

export class ResaleAreaClonedDto extends BaseModel {
  @Expose()
  @Default(0)
  @Type(() => Number)
  areaSqft!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  areaSqm!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  landSqft!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  landSqm!: number;
}
export class ResaleDocumentSetUpClonedDto extends BaseModel {
  @Expose()
  isOverride!: boolean;

  @Expose()
  @Transform(({ value }) => null)
  resaleId!: null;

  @Expose()
  @Default([])
  @Type(() => ResaleDocumentDto)
  data!: ResaleDocumentDto[];
}

export class ResaleBillingClonedDto extends BaseModel {
  @Expose()
  @Transform(({ value }) => null)
  id!: null;

  @Expose()
  @Transform(({ value }) => null)
  resaleId!: null;

  @Expose()
  name!: string;

  @Expose()
  paymentAdvice!: EPaymentAdvice;

  @Expose()
  contactId!: number;

  @Expose()
  remarks!: string;

  @Expose()
  envDelivery!: boolean;

  @Expose()
  attention!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  billingDate!: string;

  @Expose()
  clientType!: EClientType;

  @Expose()
  clientName!: string;

  @Expose()
  clientNric!: string;

  @Expose()
  @Type(() => PhoneNumber)
  clientContact!: PhoneNumber;

  @Expose()
  clientEmail!: string;

  @Expose()
  clientCountryId!: number;

  @Expose()
  countryId!: number;

  @Expose()
  gstCategoryId!: number;

  @Expose()
  gstCategory!: string;

  @Expose()
  @Type(() => Number)
  transactionAmount!: number;

  @Expose()
  @Type(() => Number)
  valuationPrice!: number;

  @Expose()
  @Type(() => Number)
  percentage!: number;

  @Expose()
  gstType!: EGstType;

  @Expose()
  gstPercent!: number;

  @Expose()
  @Type(() => GrossCommDto)
  grossComm!: GrossCommDto;

  @Expose()
  @Type(() => Number)
  subAmount!: number;

  @Expose()
  choosenAddressId!: number;

  @Expose()
  @Type(() => ContactAddressDto)
  choosenAddress!: ContactAddressDto;

  // @Expose()
  // @Type(() => ResaleBillingMetadataDto)
  // metadata!: ResaleBillingMetadataDto;

  @Expose()
  @Type(() => AddressDto)
  clientAddress!: AddressDto;

  @Expose()
  billingParty!: string;

  @Expose()
  billingCountryId!: number;

  @Expose()
  @Type(() => AddressDto)
  billingAddress!: AddressDto;

  @Expose()
  @Type(() => Number)
  ecddIds!: number[];

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  chequeDate!: string;

  @Expose()
  @Type(() => ResaleBillingCommissionDto)
  commission!: ResaleBillingCommissionDto;

  @Expose()
  invoiceOption?: {
    agent: InvoiceOptionDto;
    ecb: InvoiceOptionDto;
    client: InvoiceOptionDto;
    solicitor: InvoiceOptionDto;
    hardcopy: InvoiceOptionDto;
  };

  @Expose()
  ecddRef!: string;
}

export class ResaleInternalCoBrokeClonedDto extends BaseModel {
  @Expose()
  @Transform(({ value }) => null)
  id!: null;

  @Expose()
  agentId!: number;

  @Expose()
  @Transform(({ value }) => value?.trim())
  brokerIdNo!: string;

  @Expose()
  brokerName!: string;

  @Expose()
  @Type(() => PhoneNumber)
  brokerPhone!: PhoneNumber;

  @Expose()
  brokerEmail!: string;

  @Expose()
  @Type(() => Number)
  receivedPercent!: number;

  @Expose()
  @Type(() => Number)
  receivedValue!: number;

  @Expose()
  absorbType!: { agent: boolean; hr: boolean };

  @Expose()
  attrs!: any;

  @Expose()
  commType!: ECommType;

  @Expose()
  grouping!: string;
}

export class ResaleExternalCoBrokeClonedDto extends BaseModel {
  @Expose()
  @Transform(({ value }) => null)
  id!: null;

  @Expose()
  type!: EResaleAgentType;

  @Expose()
  contactId!: number;

  @Expose()
  gstType!: EGstType;

  @Expose()
  brokerIdNo!: string;

  @Expose()
  brokerName!: string;

  @Expose()
  @Type(() => PhoneNumber)
  brokerPhone!: PhoneNumber;

  @Expose()
  brokerEmail!: string;

  @Expose()
  remarks!: string;

  @Expose()
  commType!: ECommType;

  @Expose()
  @Type(() => Number)
  receivedPercent!: number;

  @Expose()
  @Type(() => Number)
  receivedValue!: number;

  @Expose()
  @Type(() => GrossCommDto)
  grossComm!: GrossCommDto;

  @Expose()
  attrs!: any;
}

export class ResaleRelatedAgentClonedDto extends BaseModel {
  @Expose()
  @Default([])
  @Type(() => ResaleInternalCoBrokeClonedDto)
  mains!: ResaleInternalCoBrokeClonedDto[];

  @Expose()
  @Default([])
  @Type(() => ResaleInternalCoBrokeClonedDto)
  internals!: ResaleInternalCoBrokeClonedDto[];

  @Expose()
  @Default([])
  @Type(() => ResaleExternalCoBrokeClonedDto)
  externals!: ResaleExternalCoBrokeClonedDto[];
}

export class ResaleTransactionClonedDto extends BaseModel {
  @Expose()
  name!: string;

  @Expose()
  resaleType!: EResaleType;

  @Expose()
  propertyType!: EResalePropertyType;

  @Expose()
  companyId!: number;

  @Expose()
  @Type(() => Date)
  //@StartOfDate()
  @FormattedDateString()
  submissionDate!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  agreementDate!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  commencementDate!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  optionDate!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  expireDate!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  completionDate!: string;

  @Expose()
  remarks!: string;

  @Expose()
  remarksInternal!: string;

  @Expose()
  countryId!: number;

  @Expose()
  furnished!: EFurnished;

  @Expose()
  @Type(() => ResaleRelatedAgentClonedDto)
  relatedAgent!: ResaleRelatedAgentClonedDto;

  @Expose()
  isInternal!: boolean;

  @Expose()
  paymentMethod!: EResalePaymentMethod;

  @Expose()
  @Type(() => ResaleBillingClonedDto)
  billing!: ResaleBillingClonedDto;

  @Expose()
  @Type(() => AddressDto)
  propertyAddress!: AddressDto;

  @Expose()
  metadata!: object;

  @Expose()
  @Type(() => ResaleAreaClonedDto)
  area!: ResaleAreaClonedDto;
}
