// core
import { createAction, props } from '@ngrx/store';

// share
import { IQuerySearch } from '@shared/data-access/interfaces';
import { RecruitmentModel } from '@shared/data-access/models';
import { PaginationAdapter } from '@red/data-access';

// list
export const loadList = createAction('[Recruitment] Load list', props<{ filters: IQuerySearch }>());
export const loadListSuccess = createAction('[Recruitment] Load list success', props<{ data: PaginationAdapter<RecruitmentModel> }>());

// detail
export const loadDetail = createAction('[Recruitment] Load detail', props<{ id: number }>());
export const loadDetailSuccess = createAction('[Recruitment] Load detail success', props<{ detail: RecruitmentModel }>());
