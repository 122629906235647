import { createFeature, createReducer, on } from "@ngrx/store";
import { JournalEntriesModel } from "@shared/data-access/models";
import { JournalAction } from "../actions";

export const featureName = 'journal';

export interface DataState {
  id: string;
  item: JournalEntriesModel | null,
}

export const initialState: DataState = {
  id: '',
  item: null,
};

export const journalFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(JournalAction.getIdParams, (state, prop: { id: string }) => ({ ...state, id: prop.id })),
    on(JournalAction.getDetail, (state, prop: { item: JournalEntriesModel }) => ({ ...state, item: prop.item })),
  ),
});

export const {
  name,
  reducer,
} = journalFeature;
