import { Injectable } from '@angular/core';
import { PaymentReversalApiService } from '@cms/apis';
import { FnWithCompany, WithCompany } from '@cms/backgrounds/switch-company';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, map } from 'rxjs';
import { PaymentReversalAction } from '../actions';

@Injectable()
export class PaymentReversalEffect {
  loadItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(PaymentReversalAction.loadItem),
      exhaustMap(action => {
        return this.fetch(action.id).pipe(map(item => PaymentReversalAction.loadItemSuccess({ item })));
      })
    );
  });

  @FnWithCompany()
  fetch(id: number, @WithCompany() option: Record<string, any> = {}) {
    return this._apiService.get(id, option);
  }
  constructor(private _apiService: PaymentReversalApiService, private _actions$: Actions, private _store: Store) {}
}
