import { PaginationAdapter } from '@red/data-access';
import { createAction, props } from '@ngrx/store';
import { KeyAppointmentModel, UpdatedByModel } from '@shared/data-access/models';
import { IQuerySearch } from '@shared/data-access/interfaces';
// items
export const loadItems = createAction('[KeyAppointmentList] Load items', props<{ filters: IQuerySearch }>());
export const loadItemsSuccess = createAction('[KeyAppointmentList] Load items success', props<{ data: PaginationAdapter<KeyAppointmentModel> }>());

// item
export const loadItem = createAction('[KeyAppointmentList] Load item', props<{ id: number }>());
export const loadItemSuccess = createAction('[KeyAppointmentList] Load item success', props<{ item: KeyAppointmentModel }>());

// last updated
export const setLastUpdated = createAction('[KeyAppointmentList] Set last updated', props<{ updatedAt: string; updatedBy: UpdatedByModel }>());

// reset update state
export const resetUpdateState = createAction('[KeyAppointmentList] Reset update state');
