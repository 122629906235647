import { createFeature, createReducer, on } from '@ngrx/store';
import { GrossCommissionEarnedModel, ResaleBillingModel, ResaleRelatedAgentModel, ResaleTransactionModel } from '@shared/data-access/models';
import { ResaleTransactionAction } from '../actions';

export const featureName = 'resaleTransaction';

export interface ResaleTransactionState {
  item: ResaleTransactionModel | null;
  draftItem: ResaleTransactionModel;
  draftType: 'create' | 'edit';
  submittingStatus: {
    success: any;
    loading: boolean;
    error: any;
  };
}

export const initialState: ResaleTransactionState = {
  item: null,
  draftItem: ResaleTransactionModel.createEmpty(),
  draftType: 'create',
  submittingStatus: {
    success: null,
    loading: false,
    error: null,
  },
};

export const resaleTransactionFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(ResaleTransactionAction.setItemDetail, (state, { item }) => ({
      ...state,
      item,
    })),
    on(ResaleTransactionAction.updateItemDetail, (state, { data }) => ({
      ...state,
      item: state.item ? ResaleTransactionModel.merge(state.item, data) : null,
    })),
    on(ResaleTransactionAction.setDraftItemAsCreate, state => ({
      ...state,
      draftItem: ResaleTransactionModel.createEmpty(),
      draftType: 'create',
    })),
    on(ResaleTransactionAction.setDraftItemAsEdit, state => ({
      ...state,
      draftItem: state.item ? ResaleTransactionModel.clone(state.item) : ResaleTransactionModel.createEmpty(),
      draftType: 'edit',
    })),
    on(ResaleTransactionAction.updateDraftItem, (state, { data }) => ({
      ...state,
      draftItem: ResaleTransactionModel.merge(state.draftItem, data),
    })),
    on(ResaleTransactionAction.updateResaleTypeDraftItem, (state, { resaleType }) => ({
      ...state,
      draftItem: ResaleTransactionModel.merge(state.draftItem, { resaleType }),
    })),
    on(ResaleTransactionAction.updateTransactionAmountDraftItem, (state, { transactionAmount }) => ({
      ...state,
      draftItem: ResaleTransactionModel.merge(state.draftItem, { billing: ResaleBillingModel.merge(state.draftItem.billing, { transactionAmount }) }),
    })),
    on(ResaleTransactionAction.updateBillingValueDraftItem, (state, { billingValue }) => ({
      ...state,
      draftItem: ResaleTransactionModel.merge(state.draftItem, {
        billing: ResaleBillingModel.merge(state.draftItem.billing, {
          grossComm: GrossCommissionEarnedModel.merge(state.draftItem.billing?.grossComm, {
            subTotalAmount: billingValue,
          }),
        }),
      }),
    })),
    on(ResaleTransactionAction.updateBillingValueDraftItem, (state, { billingValue }) => ({
      ...state,
      draftItem: ResaleTransactionModel.merge(state.draftItem, {
        billing: ResaleBillingModel.merge(state.draftItem.billing, {
          grossComm: GrossCommissionEarnedModel.merge(state.draftItem.billing?.grossComm, {
            subTotalAmount: billingValue,
          }),
        }),
      }),
    })),
    on(ResaleTransactionAction.addExternalDraftItem, (state, { item }) => {
      const externals = [item, ...state.draftItem.relatedAgent.externals];
      return {
        ...state,
        draftItem: ResaleTransactionModel.merge(state.draftItem, {
          relatedAgent: ResaleRelatedAgentModel.merge(state.draftItem.relatedAgent, {
            externals,
          }),
        }),
      };
    }),
    on(ResaleTransactionAction.updateExternalDraftItem, (state, { index, item }) => {
      const externals = [...state.draftItem.relatedAgent.externals];
      externals[index] = item;
      return {
        ...state,
        draftItem: ResaleTransactionModel.merge(state.draftItem, {
          relatedAgent: ResaleRelatedAgentModel.merge(state.draftItem.relatedAgent, {
            externals,
          }),
        }),
      };
    }),
    on(ResaleTransactionAction.deleteExternalDraftItem, (state, { index }) => {
      const externals = [...state.draftItem.relatedAgent.externals];
      externals.splice(index, 1);
      return {
        ...state,
        draftItem: ResaleTransactionModel.merge(state.draftItem, {
          relatedAgent: ResaleRelatedAgentModel.merge(state.draftItem.relatedAgent, {
            externals,
          }),
        }),
      };
    }),
    on(ResaleTransactionAction.addDocumentsDraftItem, (state, { items }) => {
      const documents = [...items, ...state.draftItem.documents];
      return {
        ...state,
        draftItem: ResaleTransactionModel.merge(state.draftItem, {
          documents,
        }),
      };
    }),
    on(ResaleTransactionAction.deleteDocumentDraftItem, (state, { index }) => {
      const documents = [...state.draftItem.documents];
      documents.splice(index, 1);
      return {
        ...state,
        draftItem: ResaleTransactionModel.merge(state.draftItem, {
          documents,
        }),
      };
    }),
    on(ResaleTransactionAction.saveDraftItem, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: true,
        error: null,
      },
    })),
    on(ResaleTransactionAction.saveAndSubmitDraftItem, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: true,
        error: null,
      },
    })),
    on(ResaleTransactionAction.submitItemSuccess, (state, { res }) => ({
      ...state,
      submittingStatus: {
        success: res,
        loading: false,
        error: null,
      },
    })),
    on(ResaleTransactionAction.submitItemFail, (state, { error }) => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: false,
        error,
      },
    })),
    on(ResaleTransactionAction.resetDraftItem, state => ({
      ...state,
      item: null,
      draftItem: ResaleTransactionModel.createEmpty(),
      draftType: 'create',
    })),
    on(ResaleTransactionAction.resetSubmittingStatus, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: false,
        error: null,
      },
    }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = resaleTransactionFeature;
