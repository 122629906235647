import { createFeature, createReducer, on } from '@ngrx/store';
import { TransactionTypeModel } from '@shared/data-access/models';
import { TransactionTypeAction } from '../actions';

export const featureName = 'transactionType';

export interface TransactionTypeState {
  item: TransactionTypeModel | null;
}
export const initialState: TransactionTypeState = {
  item: null,
};
export const transactionTypeFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(TransactionTypeAction.loadItemSuccess, (state, prop: { item: TransactionTypeModel }) => ({ ...state, item: prop.item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = transactionTypeFeature;
