import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { PermissionsService } from '@shared/permission';
import { forkJoin, Observable } from 'rxjs';
import { UserService } from './core/auth';
import { NavigationService } from './core/navigation/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class InitialDataResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(private _navigationService: NavigationService, private _userService: UserService, private _permissionService: PermissionsService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Use this resolver to resolve initial mock-api for the application
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    console.log('Resolve run');
    // Fork join multiple API endpoint calls to wait all of them to finish
    return forkJoin([this._navigationService.get(), this._userService.get(), this._permissionService.get()]);
  }
}
