import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CareerProgressionReducer } from '../reducers';

export const selectCareerProgressionInfoFeature =
  createFeatureSelector<CareerProgressionReducer.CareerProgressionInfoState>(
    CareerProgressionReducer.featureName
  );

export const selectCareerProgressionInfoItems = createSelector(
  selectCareerProgressionInfoFeature,
  state => state.items
);
