import { createAction, props } from '@ngrx/store';
import { BatchingApprovalModel } from '@shared/data-access/models';


export const getDetail = createAction(
  '[BATCHING APPROVAL] GET DETAIL',
  props<{item:BatchingApprovalModel}>()
);


