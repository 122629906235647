import { Injectable } from '@angular/core';
import { AppointmentTypeApiService } from '@cms/apis';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, map, mergeMap, of } from 'rxjs';
import { AppointmentTypeAction } from '../actions';

@Injectable()
export class AppointmentTypeEffects implements OnInitEffects {
  configs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppointmentTypeAction.getSystemConfigs),
      mergeMap(() =>
        this.apiService.getGlobalConfigs().pipe(
          map(data => AppointmentTypeAction.getSystemConfigsSuccess({ data })),
          catchError(error => of(AppointmentTypeAction.getSystemConfigsFailure({ error })))
        )
      )
    );
  });
  constructor(private actions$: Actions, private apiService: AppointmentTypeApiService) {}
  ngrxOnInitEffects(): Action {
    return AppointmentTypeAction.getSystemConfigs();
  }
}
