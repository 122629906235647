import { Duration, formatISO } from 'date-fns';

export class DateTimeConverter {
  static formatISO(input = new Date(), options?: Duration): string {
    const inputFormatted = new Date(
      typeof options?.years === 'number' ? options?.years : input.getFullYear(),
      typeof options?.months === 'number' ? options?.months : input.getMonth(),
      typeof options?.days === 'number' ? options?.days : input.getDate(),
      typeof options?.hours === 'number' ? options?.hours : input.getHours(),
      typeof options?.minutes === 'number' ? options?.minutes : input.getMinutes(),
      typeof options?.seconds === 'number' ? options?.seconds : input.getSeconds()
    );
    // console.log('inputFormatted', inputFormatted, options);
    return formatISO(inputFormatted);
  }
}
