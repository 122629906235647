import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PaymentModel } from '@shared/data-access/models';
import { filter, Observable, take } from 'rxjs';
import { PaymentAction } from '../actions';
import { PaymentReducer } from '../reducers';
import { PaymentSelector } from '../selectors';

@Injectable()
export class PaymentFacade {
  selectedItem$ = this._store.select(PaymentSelector.selectPayment);
  constructor(private _store: Store<PaymentReducer.PaymentState>) {}

  /**
   * Get @PaymentModel by id
   */
  getById(id: number): Observable<PaymentModel | null> {
    this._store.dispatch(PaymentAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<PaymentModel | null>;
  }
}
