import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwitchCompanyWraperComponent } from './switch-company-wraper.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [SwitchCompanyWraperComponent],
  imports: [CommonModule, RouterModule],
  exports: [SwitchCompanyWraperComponent],
})
export class SwitchCompanyWraperModule {}
