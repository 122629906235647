import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TaxInvoiceReducer } from '../reducers';

export const taxInvoiceFeature = createFeatureSelector<TaxInvoiceReducer.DataState>(TaxInvoiceReducer.featureName);

// export const selectFeatureTaxInvoice = createSelector(taxInvoiceFeature, (state: TaxInvoiceReducer.DataState) => state.taxInvoice);

// export const selectFeatureSummaryData = createSelector(taxInvoiceFeature, (state: TaxInvoiceReducer.DataState) => state.summaryModel);

// export const selectFeatureTaxInvoiceDetails = createSelector(taxInvoiceFeature, (state: TaxInvoiceReducer.DataState) => state.taxInvoiceDetailChange);

// export const selectTaxInvoiceType = createSelector(taxInvoiceFeature, (state: TaxInvoiceReducer.DataState) => state.taxInvoiceType);

// export const selectGstReceivablePosting = createSelector(taxInvoiceFeature, (state: TaxInvoiceReducer.DataState) => state.gstReceivablePosting);

// export const selectTemplate = createSelector(taxInvoiceFeature, (state: TaxInvoiceReducer.DataState) => state.templates);

// New
export const selectTaxInvoice = createSelector(taxInvoiceFeature, (state: TaxInvoiceReducer.DataState) => state.item);
