import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { BLANK_FORM_INTERNAL_AGENT_TYPE_OPTIONS } from '../data';
import { EBlankFormInternalAgentType } from '../enums/blank_form.enum';
import { IBlankFormInternalCoBroke } from '../interfaces/blank-form-internal-co-broke.interface';
import { CategoryModel } from './category.model';
import { PersonalParticularModel } from './personal-particular.model';
import { ProfitSplitPartModel, SplitPartModel } from './split-part.model';
import { EAgentBlankFormSession } from '../enums';

export class BlankFormInternalCoBrokeModel extends BaseModel implements IBlankFormInternalCoBroke {
  @Expose()
  @Default({})
  @Type(() => PersonalParticularModel)
  agent!: PersonalParticularModel;

  @Expose()
  agentType!: EAgentBlankFormSession;

  @Expose()
  agentId!: number;

  @Expose()
  type!: EBlankFormInternalAgentType;

  @Expose()
  attrs!: any;

  @Expose()
  blankFormId!: number;

  @Expose()
  createdAt!: string;

  @Expose()
  id!: number;

  @Expose()
  isActive!: boolean;

  @Expose()
  name!: string;

  @Expose()
  note!: any;

  @Expose()
  @Type(() => SplitPartModel)
  @Transform(({ value, options }) => {
    if (!value || value.length === 0) {
      return [
        SplitPartModel.fromJson(
          {
            name: 'Recruiter',
            level: 4,
          },
          options
        ),
      ];
    }
    return value.map((item: any) => SplitPartModel.fromJson(item, options));
  })
  parties!: SplitPartModel[];

  @Expose()
  splitMatrixId!: number;

  @Expose()
  @Type(() => SplitPartModel)
  @Transform(({ value, options }) => {
    if (!value || value.length === 0) {
      return [
        SplitPartModel.fromJson(
          {
            name: 'Commission',
            level: 1,
          },
          options
        ),
        SplitPartModel.fromJson(
          {
            name: 'Tier 1',
            level: 2,
          },
          options
        ),
        SplitPartModel.fromJson(
          {
            name: 'Tier 2',
            level: 3,
          },
          options
        ),
      ];
    }
    return value.map((item: any) => SplitPartModel.fromJson(item, options));
  })
  tiers!: SplitPartModel[];

  @Expose()
  updatedAt!: string;

  @Expose()
  @Default(false)
  @Type(() => Boolean)
  isDefault!: boolean;

  @Expose()
  @Default([])
  @Type(() => ProfitSplitPartModel)
  profitTiers!: ProfitSplitPartModel[];

  @Expose()
  @Type(() => Number)
  receivedValue!: number;

  @Expose()
  get typeDisplay() {
    return BLANK_FORM_INTERNAL_AGENT_TYPE_OPTIONS[this.type ?? EBlankFormInternalAgentType.internalCobroke]?.viewValue;
  }

  @Expose()
  categoryId!: number;

  @Expose()
  //@Default({})
  @Type(() => CategoryModel)
  category!: CategoryModel;

  @Expose()
  grouping!: string;

  @Expose()
  companyAmount!: number;
}
