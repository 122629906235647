import { BaseModel } from '@red/data-access';
import {  Expose } from "class-transformer";
import { IConfigSetting } from "../interfaces/config-setting.interface";


export class ConfigSettingModel extends BaseModel implements IConfigSetting {

    @Expose()
  keyName!: string;

    @Expose()
  displayName!: string;

    @Expose()
  description!: string;

    @Expose()
    value: any;
}
