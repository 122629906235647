import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AccountingPeriodsModel } from '@shared/data-access/models';
import { filter, Observable, take } from 'rxjs';
import { AccountingPeriodsAction } from '../actions';
import { AccountingPeriodsReducer } from '../reducers';
import { AccountingPeriodsSelector } from '../selectors';

@Injectable()
export class AccountingPeriodsFacade {
  selectedItem$ = this._store.select(AccountingPeriodsSelector.selectAccountingPeriods);
  constructor(private _store: Store<AccountingPeriodsReducer.AccountingPeriodsState>) {}

  /**
   * Get @AccountingPeriodsModel by id
   */
  getById(id: number): Observable<AccountingPeriodsModel | null> {
    this._store.dispatch(AccountingPeriodsAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<AccountingPeriodsModel | null>;
  }
}
