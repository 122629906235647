import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AssetDepreciationModel } from '@shared/data-access/models';
import { filter, Observable, take } from 'rxjs';
import { AssetDepreciationAction } from '../actions';
import { AssetDepreciationReducer } from '../reducers';
import { AssetDepreciationSelector } from '../selectors';

@Injectable()
export class AssetDepreciationFacade {
  selectedItem$ = this._store.select(AssetDepreciationSelector.selectAssetDepreciation);
  constructor(private _store: Store<AssetDepreciationReducer.AssetDepreciationState>) {}

  /**
   * Get @AssetDepreciationModel by id
   */
  getById(id: number): Observable<AssetDepreciationModel | null> {
    this._store.dispatch(AssetDepreciationAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<AssetDepreciationModel | null>;
  }
}
