import { ContentChildren, Directive, ElementRef, Input, QueryList, Inject } from '@angular/core';
import { defaults, fromPairs, merge, set } from 'lodash-es';
import { PRINT_DOM_DATA_DEFAULT } from './print-dom.token';

/** Used to generate unique ID's for each tab component */
let nextId = 0;
@Directive({
  selector: '[printDomElement]',
})
export class PrintDomElement {
  @Input('printDomElement') keyPath!: string;
  constructor(public element: ElementRef) {}
}

@Directive({
  selector: '[printDomContainer]',
})
export class PrintDomContainer {
  /** All of the _elements that belong to the container. */
  @ContentChildren(PrintDomElement, { descendants: true }) _elements: QueryList<PrintDomElement> = new QueryList<PrintDomElement>();
  private _containerId: number;
  constructor(public element: ElementRef, @Inject(PRINT_DOM_DATA_DEFAULT) private defaults: Record<string, any>) {
    this._containerId = nextId++;
  }
  getElements() {
    return this._elements.toArray();
  }
  getData(): Record<string, any> {
    const elementData = this.getElements().reduce((obj: Record<string, any>, item) => {
      const newObj = set({}, item.keyPath, item.element.nativeElement.textContent.trim());
      return merge(obj, newObj);
    }, {});
    return merge({}, this.defaults, elementData);
  }
}
