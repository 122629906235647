import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CompanyModel } from '@shared/data-access/models';
import { Subject } from 'rxjs';
import { SWITCH_COMPANY_API_LOADER, SwitchCompanyLoaderService } from './switch-company-api-loader';

@Component({
  selector: 'cms-switch-company',
  templateUrl: './switch-company.component.html',
  styleUrls: ['./switch-company.component.scss'],
})
export class SwitchCompanyComponent implements OnInit, OnDestroy {
  unsubscribeAll = new Subject<void>();
  companyCtr = new FormControl();
  companies$ = this.loader.search({});
  constructor(@Inject(SWITCH_COMPANY_API_LOADER) private loader: SwitchCompanyLoaderService) {}

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  ngOnInit(): void {
    this.loader.getCurrentSelected().subscribe(item => {
      this.companyCtr.setValue(item);
    });
  }

  onCompanyChange(val: CompanyModel): void {
    this.loader.updateSelected(val.id).subscribe();
  }
}
