import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PaymentGeneratorModel } from '@shared/data-access/models';
import { filter, Observable, take } from 'rxjs';
import { PaymentGeneratorAction } from '../actions';
import { PaymentGeneratorReducer } from '../reducers';
import { PaymentGeneratorSelector } from '../selectors';

@Injectable()
export class PaymentGeneratorFacade {
  selectedItem$ = this._store.select(PaymentGeneratorSelector.selectPaymentGenerator);
  constructor(private _store: Store<PaymentGeneratorReducer.PaymentGeneratorState>) {}

  /**
   * Get @PaymentGeneratorModel by id
   */
  getById(id: number): Observable<PaymentGeneratorModel | null> {
    this._store.dispatch(PaymentGeneratorAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<PaymentGeneratorModel | null>;
  }
}
