import { createAction, props } from '@ngrx/store';
import { AddressModel, DeveloperModel } from '@shared/data-access/models';


export const getDetail = createAction(
  '[Developer] GET DETAIL',
  props<{item:DeveloperModel}>()
);



export const getAddressDefault = createAction(
  '[Project] GET ADDRESS DEFAULT',
  props<{developerId:number}>()
);
export const getAddressDefaultSuccess = createAction(
  '[Project] GET ADDRESS DEFAULT SUCCESS',
  props<{developerId:number, data:AddressModel | null}>()
);
export const getAddressDefaultFailure = createAction(
  '[Project] GET ADDRESS DEFAULT FAILURE',
  props<{error:any}>()
);
