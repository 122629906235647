import { createAction, props } from '@ngrx/store';
import { TaxInvoiceModel } from '@shared/data-access/models';

// export const getTaxInvoiceDetail = createAction('[TAX INVOICE] GET TAX INVOICE DETAIL', props<{ item: TaxInvoiceLegacyModel }>());

// export const getSummaryData = createAction('[TAX INVOICE] GET SUMMARY', props<{ summaryModel: TaxInvoiceSummaryModel }>());

// export const getGSTAndReceivable = createAction('[TAX INVOICE] GET GST RECEIVABLE', props<{ gstReceivablePosting: TaxInvoiceSummaryModel }>());

// export const createOrUpdateInvoiceDetails = createAction(
//   '[TAX INVOICE] CREATE OR UPDATE INVOICE DETAILS',
//   props<{ taxInvoiceDetailChange: TaxInvoiceDetailChanges }>()
// );

// export const getTaxInvoiceType = createAction('[TAX INVOICE] GET CUSTOMER OR SUPPLIER TYPE', props<{ taxInvoiceType: string }>());

// export const getCreditNoteType = createAction('[TAX INVOICE] GET  CREDIT NOTE TYPE', props<{ creditNoteType: string }>());

// export const getTemplates = createAction('[TAX INVOICE] GET TEMPLATE TYPE', props<{ templates: TemplateModel[] }>());

// New code
export const loadItem = createAction('[TAX INVOICE] LOAD ITEM', props<{ id: number }>());
export const loadItemSuccess = createAction('[TAX INVOICE] LOAD ITEM SUCCESS', props<{ item: TaxInvoiceModel }>());
