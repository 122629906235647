import { Injectable } from '@angular/core';
import { AssetDepreciationApiService } from '@cms/apis';
import { FnWithCompany, WithCompany } from '@cms/backgrounds/switch-company';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, map } from 'rxjs';
import { AssetDepreciationAction } from '../actions';

@Injectable()
export class AssetDepreciationEffect {
  loadItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AssetDepreciationAction.loadItem),
      exhaustMap(action => {
        return this.fetch(action.id).pipe(map(item => AssetDepreciationAction.loadItemSuccess({ item })));
      })
    );
  });

  loadNewDepreciation$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AssetDepreciationAction.loadDepreciation),
      exhaustMap(() => {
        return this.getNewDepreciation().pipe(map(data => AssetDepreciationAction.loadDepreciationSuccess({ data })));
      })
    );
  });

  @FnWithCompany()
  fetch(id: number, @WithCompany() option: Record<string, any> = {}) {
    return this._apiService.get(id, option);
  }

  @FnWithCompany()
  getNewDepreciation(@WithCompany() query = {}) {
    return this._apiService.getNewDepreciation(query);
  }

  constructor(private _apiService: AssetDepreciationApiService, private _actions$: Actions, private _store: Store) {}
}
