import { ToastService } from '@shared/components/toast';
import { ActivatedRoute } from '@angular/router';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'red-error-403',
  templateUrl: './error-403.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Error403Component {
  reload(): void {
    location.reload();
  }
}
