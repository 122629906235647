import { PaginationAdapter } from '@red/data-access';
import { createAction, props } from '@ngrx/store';
import { ScommMatrixTypeModel } from '@shared/data-access/models';
import { IQuerySearch } from '@shared/data-access/interfaces';
// items
export const loadItems = createAction('[CommMatrixType] Load items', props<{ filters: IQuerySearch }>());
export const loadItemsSuccess = createAction('[CommMatrixType] Load items success', props<{ data: PaginationAdapter<ScommMatrixTypeModel> }>());

// item
export const loadItem = createAction('[CommMatrixType] Load item', props<{ id: number }>());
export const loadItemSuccess = createAction('[CommMatrixType] Load item success', props<{ item: ScommMatrixTypeModel }>());
