import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ResaleReportReducer } from "../reducers";

export const selectResaleReportFeature = createFeatureSelector<ResaleReportReducer.DataState>(
  ResaleReportReducer.featureName
);

export const selectFeatureResaleReport = createSelector(
  selectResaleReportFeature,
  (state: ResaleReportReducer.DataState) => state.item
);

