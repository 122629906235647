import { createFeature, createReducer, on } from '@ngrx/store';
import { AssetDisposalModel } from '@shared/data-access/models';
import { AssetDisposalAction } from '../actions';

export const featureName = 'assetDisposal';

export interface AssetDisposalState {
  item: AssetDisposalModel | null;
}

export const initialState: AssetDisposalState = {
  item: null,
};

export const assetDisposalFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(AssetDisposalAction.loadItemSuccess, (state, prop: { item: AssetDisposalModel }) => ({ ...state, item: prop.item }))
  ),
});

export const { name, reducer } = assetDisposalFeature;
