import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CustomerModel } from '@shared/data-access/models';
import { filter, Observable, take } from 'rxjs';
import { CustomerAction } from '../actions';
import { CustomerReducer } from '../reducers';
import { CustomerSelector } from '../selectors';

@Injectable()
export class CustomerFacade {
  selectedItem$ = this._store.select(CustomerSelector.selectCustomer);
  constructor(private _store: Store<CustomerReducer.CustomerState>) {}

  /**
   * Get @CustomerModel by id
   */
  getById(id: number): Observable<CustomerModel | null> {
    this._store.dispatch(CustomerAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<CustomerModel | null>;
  }
}
