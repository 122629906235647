import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ContactReducer } from "../reducers";

export const contactFeature = createFeatureSelector<ContactReducer.DataState>(
  ContactReducer.featureName
);

export const selectContactType = createSelector(
  contactFeature,
  (state: ContactReducer.DataState) => state.contactType
);

