import { createAction, props } from "@ngrx/store";
import { JournalEntriesModel } from "@shared/data-access/models";

export const getDetail = createAction(
  '[JOURNAL ENTRIES] GET DETAIL',
  props<{ item: JournalEntriesModel }>()
);

export const getIdParams = createAction(
  '[JOURNAL ENTRIES] GET ID PARAMS',
  props<{ id: string }>()
);

export const createOrUpdate = createAction(
  '[JOURNAL ENTRIES] SAVE JOURNAL'
);

export const refreshTable = createAction(
  '[JOURNAL ENTRIES] REFRESH TABLE'
);