import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AssetRegisterModel } from '@shared/data-access/models';
import { filter, Observable, take } from 'rxjs';
import { AssetRegisterAction } from '../actions';
import { AssetRegisterReducer } from '../reducers';
import { AssetRegisterSelector } from '../selectors';

@Injectable()
export class AssetRegisterFacade {
  selectedItem$ = this._store.select(AssetRegisterSelector.selectAssetRegister);
  constructor(private _store: Store<AssetRegisterReducer.AssetRegisterState>) {}

  /**
   * Get @AssetRegisterModel by id
   */
  getById(id: number): Observable<AssetRegisterModel | null> {
    this._store.dispatch(AssetRegisterAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<AssetRegisterModel | null>;
  }
}
