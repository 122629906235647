import { createFeature, createReducer, on } from "@ngrx/store";
import { EmploymentHistoryModel } from "@shared/data-access/models";
import { EmploymentHistoryAction } from "../actions";

export const featureName = 'employmentHistoryInfo';

export interface EmploymentHistoryState {
  items:EmploymentHistoryModel[];
}
export const initialState: EmploymentHistoryState = {
  items: [],
};
export const employmentHistoryFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(EmploymentHistoryAction.getList,  (state,{payload}) => ({ ...state, items:payload}))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = employmentHistoryFeature;
