import { BaseModel, Default } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IUser } from '../interfaces/user.interface';
import { UserProfileModel } from './user-profile.model';

export class UserModel extends BaseModel implements IUser {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  email!: string;

  @Expose()
  username!: string;

  @Expose()
  lastLogin!: string;

  @Expose()
  @Default({})
  @Type(() => UserProfileModel)
  profile!: UserProfileModel;

  @Expose()
  get defaultAvatar(): string {
    return this.name[0] || '';
  }

  @Expose()
  avatar!: string;
}
