import { createAction, props } from '@ngrx/store';
import { BonusDistributionModel } from '@shared/data-access/models';


export const getDetail = createAction(
  '[BONUS DISTRIBUTION] GET DETAIL',
  props<{item:BonusDistributionModel}>()
);


