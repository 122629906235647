import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CommSalespersonSchemeReducer } from "../reducers";

export const selectCommSalespersonSchemeFeature = createFeatureSelector<CommSalespersonSchemeReducer.CommSalespersonSchemeState>(
  CommSalespersonSchemeReducer.featureName
);

export const selectFeatureCommSalespersonSchemeInfo = createSelector(
  selectCommSalespersonSchemeFeature,
  (state: CommSalespersonSchemeReducer.CommSalespersonSchemeState) => state.detail
);
