import { InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicErrorMessagePipe } from './dynamic-error-message.pipe';

export interface ErrorMessage {
  errorCode: number;
  message: string;
}

export const HTTP_ERROR_MESSAGES_TOKEN = new InjectionToken<ErrorMessage[]>('HTTP_ERROR_MESSAGES');
export const DEFAULT_ERROR_MESSAGE_TOKEN = new InjectionToken<string | null>('FALLBACK_ERROR_MESSAGE');

@NgModule({
  imports: [CommonModule],
  declarations: [DynamicErrorMessagePipe],
  exports: [DynamicErrorMessagePipe],
})
export class DynamicErrorMessageModule {
  static forRoot(configs: { defaultErrorMessage?: string | null; errorMessages: ErrorMessage[] }) {
    return {
      ngModule: DynamicErrorMessageModule,
      providers: [
        {
          provide: HTTP_ERROR_MESSAGES_TOKEN,
          useValue: configs.errorMessages,
        },
        {
          provide: DEFAULT_ERROR_MESSAGE_TOKEN,
          useValue: configs.defaultErrorMessage,
        },
      ],
    };
  }
}
