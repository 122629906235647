import { createFeature, createReducer, on } from '@ngrx/store';
import { PersonalParticularModel } from '@shared/data-access/models';
import * as PersonalParticularActions from '../actions/personal-particular.actions';

export const featureName = 'personalParticular';

export interface PersonalParticularState {
  detail: PersonalParticularModel | null;
}

export const initialState: PersonalParticularState = {
  detail: null,
};

export const personalParticularFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(PersonalParticularActions.loadItemSuccess, (state, { item }) => ({ ...state, detail: item })),
    on(PersonalParticularActions.loadInfoSuccess, (state, { info }) => ({ ...state, detail: info }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = personalParticularFeature;
