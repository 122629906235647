export enum CustomerPermissions {
  Create = 'finance:customer:create',
  Update = 'finance:customer:update',
  Delete = 'finance:customer:delete',
  Read = 'finance:customer:read',
}

export enum SupplierPermissions {
  Create = 'finance:supplier:create',
  Update = 'finance:supplier:update',
  Delete = 'finance:supplier:delete',
  Read = 'finance:supplier:read',
}

export enum TaxInvoicePermissions {
  Create = 'finance:tax_invoice:create',
  Update = 'finance:tax_invoice:update',
  Delete = 'finance:tax_invoice:delete',
  Read = 'finance:tax_invoice:read',
}

export enum SupplierInvoicePermissions {
  Create = 'finance:supplier_invoice:create',
  Update = 'finance:supplier_invoice:update',
  Delete = 'finance:supplier_invoice:delete',
  Read = 'finance:supplier_invoice:read',
}

export enum CreditNotePermissions {
  Create = 'finance:credit_note:create',
  Update = 'finance:credit_note:update',
  Delete = 'finance:credit_note:delete',
  Read = 'finance:credit_note:read',
}

export enum SupplierCreditPermissions {
  Create = 'finance:supplier_credit_note:create',
  Update = 'finance:supplier_credit_note:update',
  Delete = 'finance:supplier_credit_note:delete',
  Read = 'finance:supplier_credit_note:read',
}

export enum DebitNotePermissions {
  Create = 'finance:debit_note:create',
  Update = 'finance:debit_note:update',
  Delete = 'finance:debit_note:delete',
  Read = 'finance:debit_note:read',
}

export enum ChartOfAccountLocalPermissions {
  Create = 'finance:chart_of_account_local:create',
  Update = 'finance:chart_of_account_local:update',
  Delete = 'finance:chart_of_account_local:delete',
  Read = 'finance:chart_of_account_local:read',
}

export enum ChartOfAccountMasterPermissions {
  Create = 'finance:chart_of_account_master:create',
  Update = 'finance:chart_of_account_master:update',
  Delete = 'finance:chart_of_account_master:delete',
  Read = 'finance:chart_of_account_master:read',
  VerifyMaster = 'finance:chart_of_account_master:verify',
}

export enum ProfitCentrePermissions {
  Create = 'finance:profit_center:create',
  Update = 'finance:profit_center:update',
  Delete = 'finance:profit_center:delete',
  Read = 'finance:profit_center:read',
}

export enum JournalPermissions {
  Create = 'finance:journal:create',
  Update = 'finance:journal:update',
  Delete = 'finance:journal:delete',
  Read = 'finance:journal:read',
}

export enum AccountSetupPermissions {
  Create = 'finance:account_setup:create',
  Update = 'finance:account_setup:update',
  Delete = 'finance:account_setup:delete',
  Read = 'finance:account_setup:read',
}
