import { createFeature, createReducer, on } from '@ngrx/store';
import { EResaleCommissionTaxType, EResalePropertyType, EResaleStatus, EResaleType } from '@shared/data-access/enums';
import { ResaleBillingModel, ResaleRelatedAgentModel, ResaleTransactionModel } from '@shared/data-access/models';
import { isEqual } from 'lodash-es';
import { ResaleTransactionAction } from '../actions';
import * as moment from 'moment';
import { IResaleMetadata } from '@shared/data-access/interfaces';
import { RESALE_PROPERTY_TYPE_OPTIONS } from '@shared/data-access/data';

export const featureName = 'resaleTransaction';

export interface ResaleTransactionState {
  item: ResaleTransactionModel | null;
  draftItem: ResaleTransactionModel;
  draftType: 'create' | 'edit' | 'clone';
  submittingStatus: {
    success: any;
    loading: boolean;
    error: any;
  };
  isInternal: boolean;
  isExternal: boolean;
  isCloneDuplicated: boolean;
  optionDate: string | null;
  metadata: IResaleMetadata | null;
}

export const initialState: ResaleTransactionState = {
  item: null,
  draftItem: ResaleTransactionModel.createEmpty({ groups: ['draft'] }),
  draftType: 'create',
  submittingStatus: {
    success: null,
    loading: false,
    error: null,
  },
  isInternal: false,
  isExternal: false,
  isCloneDuplicated: false,
  optionDate: null,
  metadata: null,
};

export const resaleTransactionFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(ResaleTransactionAction.setItemDetail, (state, { item }) => ({
      ...state,
      item,
    })),
    on(ResaleTransactionAction.updateItemDetail, (state, { data }) => ({
      ...state,
      item: state.item ? ResaleTransactionModel.merge(state.item, data) : null,
    })),
    on(ResaleTransactionAction.setDraftItemAsCreate, state => ({
      ...state,
      draftItem: ResaleTransactionModel.createEmpty({ groups: ['draft'] }),
      draftType: 'create',
    })),
    on(ResaleTransactionAction.setDraftItemAsEdit, state => ({
      ...state,
      draftItem: state.item ? ResaleTransactionModel.clone(state.item, { groups: ['draft'] }) : ResaleTransactionModel.createEmpty({ groups: ['draft'] }),
      draftType: 'edit',
      isExternal: state.item ? state.item.relatedAgent.externals.length > 0 : false,
    })),
    on(ResaleTransactionAction.setDraftItemAsClone, state => ({
      ...state,
      draftItem: state.item
        ? ResaleTransactionModel.clone(
          ResaleTransactionModel.fromJson({
            ...state.item,
            //isPC: state.item.metadata?.isPC,
            propertyType: (RESALE_PROPERTY_TYPE_OPTIONS[state.item?.propertyType as EResalePropertyType]?.['hidden'] ? undefined : state.item?.propertyType),
            status: EResaleStatus.draft,
            documents: [],
            completionDate: null,
            relatedAgent: {
              mains: [EResaleType.lease, EResaleType.leaseRental].includes(state.item.resaleType)
                ? state.item.relatedAgent.mains.map(a => ({
                  ...a, receivedPercent: 0, receivedValue: 0, agent: {
                    ...a.agent, attrs: {
                      commission: {
                        type: EResaleCommissionTaxType.percentage,
                        value: 0,
                      },
                    }
                  } ?? a?.tiers[0]?.salesperson
                }))
                : [],
              internals: [EResaleType.lease, EResaleType.leaseRental].includes(state.item.resaleType)
                ? state.item.relatedAgent.internals.map(a => ({
                  ...a, receivedPercent: 0, receivedValue: 0, agent: {
                    ...a.agent, attrs: {
                      commission: {
                        type: EResaleCommissionTaxType.percentage,
                        value: 0,
                      },
                    }
                  } ?? a?.tiers[0]?.salesperson
                }))
                : [],
              externals: [],
            },
            billing: {
              ...state.item.billing,
              clientName: null,
              billingDate: null,
              ecddIds: null,
              ecdds: null,
              ecddRef: null,
              transactionAmount: null,
              grossComm: {
                amount: null,
                subTotalAmount: null,
                taxAmount: null,
                totalAmount: null,
              },
              attention: null,
              clientEmail: null,
              metadata: {
                ...state.item.metadata,
                isCobroke: [EResaleType.lease, EResaleType.leaseRental].includes(state.item.resaleType) && !!state.item.relatedAgent.internals.length,
              },
            },
            remarks: null,
            remarksInternal: null,
            metadata: {},
          }),
          { groups: ['draft'] }
        )
        : ResaleTransactionModel.createEmpty({ groups: ['draft'] }),
      draftType: 'clone',
      isExternal: state.item ? state.item.relatedAgent.externals.length > 0 : false,
    })),
    on(ResaleTransactionAction.updateDraftItem, (state, { data }) => ({
      ...state,
      draftItem: ResaleTransactionModel.merge(state.draftItem, data, { groups: ['draft'] }),
    })),
    on(ResaleTransactionAction.updateResaleTypeDraftItem, (state, { resaleType }) => ({
      ...state,
      draftItem: ResaleTransactionModel.merge(state.draftItem, { resaleType }, { groups: ['draft'] }),
    })),
    on(ResaleTransactionAction.updateTransactionAmountDraftItem, (state, { transactionAmount }) => ({
      ...state,
      draftItem: ResaleTransactionModel.merge(
        state.draftItem,
        { billing: ResaleBillingModel.merge(state.draftItem.billing, { transactionAmount }) },
        { groups: ['draft'] }
      ),
    })),
    on(ResaleTransactionAction.updateBillingDraftItem, (state, { data }) => ({
      ...state,
      draftItem: ResaleTransactionModel.merge(
        state.draftItem,
        {
          billing: ResaleBillingModel.merge(state.draftItem.billing, data),
        },
        { groups: ['draft'] }
      ),
    })),
    on(ResaleTransactionAction.addExternalDraftItem, (state, { item }) => {
      const externals = [item, ...state.draftItem.relatedAgent.externals];
      return {
        ...state,
        draftItem: ResaleTransactionModel.merge(
          state.draftItem,
          {
            relatedAgent: ResaleRelatedAgentModel.merge(state.draftItem.relatedAgent, {
              externals,
            }),
          },
          { groups: ['draft'] }
        ),
        isExternal: externals.length > 0,
      };
    }),
    on(ResaleTransactionAction.updateExternalDraftItem, (state, { index, item }) => {
      const externals = [...state.draftItem.relatedAgent.externals];
      externals[index] = item;
      return {
        ...state,
        draftItem: ResaleTransactionModel.merge(
          state.draftItem,
          {
            relatedAgent: ResaleRelatedAgentModel.merge(state.draftItem.relatedAgent, {
              externals,
            }),
          },
          { groups: ['draft'] }
        ),
        isExternal: externals.length > 0,
      };
    }),
    on(ResaleTransactionAction.deleteExternalDraftItem, (state, { index }) => {
      const externals = [...state.draftItem.relatedAgent.externals];
      externals.splice(index, 1);
      return {
        ...state,
        draftItem: ResaleTransactionModel.merge(
          state.draftItem,
          {
            relatedAgent: ResaleRelatedAgentModel.merge(state.draftItem.relatedAgent, {
              externals,
            }),
          },
          { groups: ['draft'] }
        ),
        isExternal: externals.length > 0,
      };
    }),
    on(ResaleTransactionAction.addDocumentsDraftItem, (state, { items }) => {
      const documents = [...items, ...state.draftItem.documents];
      return {
        ...state,
        draftItem: ResaleTransactionModel.merge(
          state.draftItem,
          {
            documents,
          },
          { groups: ['draft'] }
        ),
      };
    }),
    on(ResaleTransactionAction.deleteDocumentDraftItem, (state, { items }) => {
      const documents = [...state.draftItem.documents];
      items.forEach(item => {
        const index = documents.findIndex(i => i.url === item.url);
        if (index > -1) {
          documents.splice(index, 1);
        }
      });
      return {
        ...state,
        draftItem: ResaleTransactionModel.merge(
          state.draftItem,
          {
            documents,
          },
          { groups: ['draft'] }
        ),
      };
    }),
    on(ResaleTransactionAction.saveDraftItem, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: true,
        error: null,
      },
    })),
    on(ResaleTransactionAction.saveAndSubmitDraftItem, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: true,
        error: null,
      },
    })),
    on(ResaleTransactionAction.saveSpecialFields, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: true,
        error: null,
      },
    })),
    on(ResaleTransactionAction.submitItemSuccess, (state, { res }) => ({
      ...state,
      submittingStatus: {
        success: res,
        loading: false,
        error: null,
      },
    })),
    on(ResaleTransactionAction.submitItemFail, (state, { error }) => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: false,
        error,
      },
    })),
    on(ResaleTransactionAction.resetDraftItem, state => ({
      ...state,
      item: null,
      draftItem: ResaleTransactionModel.createEmpty({ groups: ['draft'] }),
      optionDate: null,
      draftType: 'create',
    })),
    on(ResaleTransactionAction.resetSubmittingStatus, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: false,
        error: null,
      },
    })),
    on(ResaleTransactionAction.updateIsInternal, (state, { isInternal }) => ({
      ...state,
      isInternal,
    })),
    on(ResaleTransactionAction.addInternalDraftItem, (state, { item }) => {
      const internals = [...state.draftItem.relatedAgent.internals, item];
      return {
        ...state,
        draftItem: ResaleTransactionModel.merge(
          state.draftItem,
          {
            relatedAgent: ResaleRelatedAgentModel.merge(state.draftItem.relatedAgent, {
              internals,
            }),
          },
          { groups: ['draft'] }
        ),
      };
    }),
    on(ResaleTransactionAction.updateInternalDraftItem, (state, { index, item }) => {
      const internals = [...state.draftItem.relatedAgent.internals];
      internals[index] = item;
      return {
        ...state,
        draftItem: ResaleTransactionModel.merge(
          state.draftItem,
          {
            relatedAgent: ResaleRelatedAgentModel.merge(state.draftItem.relatedAgent, {
              internals,
            }),
          },
          { groups: ['draft'] }
        ),
      };
    }),
    on(ResaleTransactionAction.deleteInternalDraftItem, (state, { index }) => {
      const internals = [...state.draftItem.relatedAgent.internals];
      internals.splice(index, 1);
      return {
        ...state,
        draftItem: ResaleTransactionModel.merge(
          state.draftItem,
          {
            relatedAgent: ResaleRelatedAgentModel.merge(state.draftItem.relatedAgent, {
              internals,
            }),
          },
          { groups: ['draft'] }
        ),
      };
    }),
    on(ResaleTransactionAction.addMainDraftItem, (state, { item }) => {
      const mains = [...state.draftItem.relatedAgent.mains, item];
      return {
        ...state,
        draftItem: ResaleTransactionModel.merge(
          state.draftItem,
          {
            relatedAgent: ResaleRelatedAgentModel.merge(state.draftItem.relatedAgent, {
              mains,
            }),
          },
          { groups: ['draft'] }
        ),
      };
    }),
    on(ResaleTransactionAction.updateMainDraftItem, (state, { index, item }) => {
      const mains = [...state.draftItem.relatedAgent.mains];
      mains[index] = item;
      return {
        ...state,
        draftItem: ResaleTransactionModel.merge(
          state.draftItem,
          {
            relatedAgent: ResaleRelatedAgentModel.merge(state.draftItem.relatedAgent, {
              mains,
            }),
          },
          { groups: ['draft'] }
        ),
      };
    }),
    on(ResaleTransactionAction.deleteMainDraftItem, (state, { index }) => {
      const mains = [...state.draftItem.relatedAgent.mains];
      mains.splice(index, 1);
      return {
        ...state,
        draftItem: ResaleTransactionModel.merge(
          state.draftItem,
          {
            relatedAgent: ResaleRelatedAgentModel.merge(state.draftItem.relatedAgent, {
              mains,
            }),
          },
          { groups: ['draft'] }
        ),
      };
    }),
    on(ResaleTransactionAction.checkDuplicateDraftItem, state => {
      const { item, draftItem } = state;
      const objectItem = {
        clientName: item?.billing?.clientName,
        propertyAddress: item?.propertyAddress?.address,
        closingAgentId: item?.relatedAgent?.mains?.[0]?.agentId,
        optionDate:
          item?.optionDate || item?.agreementDate
            ? moment(item?.optionDate || item?.agreementDate)
              .startOf('day')
              .unix()
            : Math.random(),
      };
      const comparedItem = {
        clientName: draftItem.billing.clientName,
        propertyAddress: draftItem.propertyAddress?.address,
        closingAgentId: draftItem.relatedAgent?.mains?.[0]?.agentId,
        optionDate:
          draftItem?.optionDate || draftItem?.agreementDate
            ? moment(draftItem?.optionDate || draftItem?.agreementDate)
              .startOf('day')
              .unix()
            : Math.random(),
      };
      const isCloneDuplicated = isEqual(objectItem, comparedItem);
      return { ...state, isCloneDuplicated };
    }),
    on(ResaleTransactionAction.updateMetadataDraftItem, (state, { metadata }) => ({
      ...state,
      draftItem: ResaleTransactionModel.merge(state.draftItem, {
        metadata: { ...state.draftItem.metadata, ...metadata },
      }),
    })),
    on(ResaleTransactionAction.setMetadata, (state, { metadata }) => ({
      ...state,
      metadata,
    })),
    on(ResaleTransactionAction.submitDraftItem, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: true,
        error: null,
      },
    })),
    on(ResaleTransactionAction.updateOptionDate, (state, { date }) => ({
      ...state,
      optionDate: date,
    }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = resaleTransactionFeature;
