import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PRINT_DOM_DATA_DEFAULT, PRINT_DOM_INSTANCE } from './print-dom.token';
import { PrintDomOptions } from './print-dom.model';
import { PrintDom } from './print-dom';
import { PrintDomService } from './print-dom.service';
import { PrintDomContainer, PrintDomElement } from './print-dom-element.directive';

@NgModule({
  declarations: [PrintDomContainer, PrintDomElement],
  imports: [CommonModule],
  exports: [PrintDomContainer, PrintDomElement],
})
export class PrintDomModule {
  static forRoot(configs?: { options?: PrintDomOptions; dataDefaults?: Record<string, any> }): ModuleWithProviders<PrintDomModule> {
    const providers: Provider[] = [
      {
        provide: PRINT_DOM_INSTANCE,
        useValue: new PrintDom(configs?.options),
      },
      {
        provide: PRINT_DOM_DATA_DEFAULT,
        useValue: configs?.dataDefaults || {},
      },
      PrintDomService,
    ];

    return {
      ngModule: PrintDomModule,
      providers: [...providers],
    };
  }
  static forChild(configs?: { options?: PrintDomOptions; dataDefaults?: Record<string, any> }): ModuleWithProviders<PrintDomModule> {
    const providers: Provider[] = [
      {
        provide: PRINT_DOM_INSTANCE,
        useValue: new PrintDom(configs?.options),
      },
      {
        provide: PRINT_DOM_DATA_DEFAULT,
        useValue: configs?.dataDefaults || {},
      },
      PrintDomService,
    ];

    return {
      ngModule: PrintDomModule,
      providers: [...providers],
    };
  }
}
