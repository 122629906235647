import { Component, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SwitchCompanyStateService } from '@cms/backgrounds/switch-company';
import { Store } from '@ngrx/store';
import { NavigationItemRouterLinkService } from '@red/components/navigation';
import { BroadcastChannelService } from '@shared/components/broadcast-channel';
import { AuthActionEvent, AuthProvider } from '@shared/features/auth';
import { Subject, takeUntil } from 'rxjs';
import { routesUnderCompany } from './app-routing.module';
import { PhoneNumberAction } from './stores/actions';
import { UserService } from './core/auth';
import { SentryCoreModule } from '@shared/components/sentry';

@Component({
  selector: 'cms-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  title = 'cms';
  private _unsubcribledAll = new Subject<void>();
  constructor(
    private _store: Store,
    private _router: Router,
    private _authService: AuthProvider,
    private _broadcast: BroadcastChannelService,
    private _companyStateService: SwitchCompanyStateService,
    private _navItemRouterLinkService: NavigationItemRouterLinkService, private _userService: UserService
  ) {
    this._store.dispatch(PhoneNumberAction.onCountriesLoading());

    this._authService.actionEvent.pipe(takeUntil(this._unsubcribledAll)).subscribe(event => {
      this._broadcast.dispatch<AuthActionEvent>({
        channelName: 'appState',
        type: 'authAction',
        payload: event,
      });
    });
    this._broadcast
      .on<AuthActionEvent>('appState', 'authAction')
      .pipe(takeUntil(this._unsubcribledAll))
      .subscribe(() => {
        location.reload();
      });

    this._companyStateService.onSelectionChange.pipe(takeUntil(this._unsubcribledAll)).subscribe(company => {
      this._navItemRouterLinkService.updateLink(link => {
        let paths = link.split('/').filter(val => !!val);
        if (routesUnderCompany.some(route => route.path === paths[0])) {
          // Do not match
          paths = company ? ['company', String(company.id), ...paths] : paths;
        }
        return paths.join('/');
      });
    });

    this._userService.user$.pipe(takeUntil(this._unsubcribledAll)).subscribe(user => {
      const payload = user ? {
        userId: user.id,
        name: user.name,
        email: user.username
      } : null;
      SentryCoreModule.setUser(payload)
    })
  }
  ngOnDestroy(): void {
    this._unsubcribledAll.next();
    this._unsubcribledAll.complete();
  }
}
