import { createFeature, createReducer, on } from "@ngrx/store";
import { ProfitSharingModel } from "@shared/data-access/models";
import { ProfitSharingAction } from "../actions";

export const featureName = 'profitSharing';

export interface DataState {
  detail:ProfitSharingModel | null;
}
export const initialState:DataState = {
  detail: null,
};
export const profitSharingFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(ProfitSharingAction.getDetail,  (state, prop) => ({ ...state, detail: prop}))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = profitSharingFeature;
