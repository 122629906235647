import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IAddress, IAddressBilling } from '../interfaces';
import { CountryModel } from './country.model';
import { EResaleNoOfRoom, EResaleNoOfRoomHdb } from '../enums';

export class AddressBillingModel extends BaseModel implements IAddressBilling {
  @Expose()
  id!: number;

  @Expose()
  address!: string;

  @Expose()
  addressLine1!: string;

  @Expose()
  addressLine2!: string;

  @Expose()
  addressLine3!: string;

  @Expose()
  addressTo!: string;

  @Expose()
  addressType!: string;

  @Expose()
  countryId!: number;

  // @Expose()
  // @Type(() => CountryModel)
  // country!: CountryModel;

  @Expose()
  country!: string;

  @Expose()
  postcode!: number;

  @Expose()
  isDefault!: boolean;

  @Expose()
  postal!: string;

  @Expose()
  unit!: string;

  @Expose()
  floor!: string;

  @Expose()
  building!: string;

  @Expose()
  noOfRooms!: EResaleNoOfRoom | EResaleNoOfRoomHdb | string;

  @Expose()
  block!: string;

  @Expose()
  street!: string;

  @Expose()
  district!: string;

  @Expose()
  state!: string;

  @Expose()
  city!: string;

  @Expose()
  updatedAt!: string;
}
