import { createFeature, createReducer, on } from '@ngrx/store';
import { ContactCustomerAndSupplierModel } from '@shared/data-access/models';
import { ContactCustomerOrSupplierAction } from '../actions';

export const contactName = 'contact';

export interface DataState {
  item: ContactCustomerAndSupplierModel | null;
}

export const initialState: DataState = {
  item: null,
};

export const customerFeature = createFeature({
  name: contactName,
  reducer: createReducer(
    initialState,
    on(ContactCustomerOrSupplierAction.getDetail, (state, prop: { item: ContactCustomerAndSupplierModel }) => ({ ...state, item: prop.item }))
  ),
});

export const { name, reducer } = customerFeature;
