import { Injectable } from '@angular/core';
import { ApiService } from '@red/api';
import { Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UserModel } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: ReplaySubject<UserModel> = new ReplaySubject<UserModel>(1);

  /**
   * Constructor
   */
  constructor(private apiService: ApiService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: UserModel) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<UserModel> {
    return this._user.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  get(): Observable<UserModel> {
    console.log('user get');
    return this.apiService.get('auth').pipe(
      map(data => UserModel.fromJson(data)),
      tap(user => {
        this._user.next(user);
      })
    );
  }

  /**
   * Update the user
   *
   * @param user
   */
  // update(user: UserModel): Observable<any>
  // {
  //     return this._httpClient.patch<UserModel>('api/common/user', {user}).pipe(
  //         map((response) => {
  //             this._user.next(response);
  //         })
  //     );
  // }
}
