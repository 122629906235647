import { createAction, props } from "@ngrx/store";
import { CountryPhoneNumberModel } from "@shared/data-access/models";

export const onCountriesLoading = createAction(
  '[PHONE NUMBER] LOADING COUNTRIES'
);
export const countriesLoaded = createAction(
  '[PHONE NUMBER] COUNTRIES LOADED',
  props<{payload:CountryPhoneNumberModel[]}>()
);
