import { PaginationAdapter } from '@red/data-access';
import { createAction, props } from '@ngrx/store';
import { ChequeModel } from '@shared/data-access/models';
import { IQuerySearch } from '@shared/data-access/interfaces';
import { ChequeBookCreateDto } from '@shared/data-access/dto';
const featureName = 'Cheque';
// items
export const loadItems = createAction('[Cheque] Load items', props<{ filters: IQuerySearch }>());
export const loadItemsSuccess = createAction('[Cheque] Load items success', props<{ data: PaginationAdapter<ChequeModel> }>());

// item
export const loadItem = createAction('[Cheque] Load item', props<{ id: number }>());
export const loadItemSuccess = createAction('[Cheque] Load item success', props<{ item: ChequeModel }>());
// Create item
export const createItems = createAction(`[${featureName}] Create items`, props<{ data: ChequeBookCreateDto }>());
export const createItemsSuccess = createAction(`[${featureName}] Create items success`, props<{ isSuccess: boolean }>());
