import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectTransactionReducer } from '../reducers';

export const selectProjectTransactionFeature = createFeatureSelector<ProjectTransactionReducer.DataState>(ProjectTransactionReducer.featureName);

export const selectFeatureProjectTransaction = createSelector(selectProjectTransactionFeature, (state: ProjectTransactionReducer.DataState) => state.item);

export const selectCobrokeLeaders = createSelector(selectProjectTransactionFeature, (state: ProjectTransactionReducer.DataState) => state.cobrokeLeaders);

export const selectCombinedLeaders = createSelector(selectProjectTransactionFeature, (state: ProjectTransactionReducer.DataState) => state.combinedLeaders);

export const selectHasLeaders = createSelector(selectCombinedLeaders, leaders => leaders.length > 0);

export const selectAgreementDate = createSelector(selectProjectTransactionFeature, (state: ProjectTransactionReducer.DataState) => state.agreementDate);

export const selectSelectedSalespersonIds = createSelector(
  selectProjectTransactionFeature,
  (state: ProjectTransactionReducer.DataState) => state.selectedSalespersonIds
);

export const selectSelectedExternalIds = createSelector(selectProjectTransactionFeature, (state: ProjectTransactionReducer.DataState) => state.selectedExternalIds);

export const selectFeatureSummary = createSelector(selectProjectTransactionFeature, (state: ProjectTransactionReducer.DataState) => state.summary);
