import { createFeature, createReducer, on } from "@ngrx/store";
import { BatchingApprovalModel } from "@shared/data-access/models";
import { BatchingApprovalAction } from "../actions";

export const featureName = 'batchingApproval';

export interface DataState {
  item:BatchingApprovalModel | null;

}
export const initialState:DataState = {
  item: null
};
export const batchingApprovalFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(BatchingApprovalAction.getDetail,  (state, prop:{item:BatchingApprovalModel}) => ({ ...state, item: prop.item})),

  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = batchingApprovalFeature;
