import { createAction, props } from '@ngrx/store';
import { PersonalParticularModel } from '@shared/data-access/models';

// item
export const loadItem = createAction('[Personal Particular] Load item', props<{ id: number }>());
export const loadItemSuccess = createAction('[Personal Particular] Load item success', props<{ item: PersonalParticularModel }>());

// info
export const loadInfo = createAction('[Personal Particular] Load info');
export const loadInfoSuccess = createAction('[Personal Particular] Load info success', props<{ info: PersonalParticularModel }>());
