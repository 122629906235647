import { createFeature, createReducer, on } from "@ngrx/store";
import { CustomerReportModel } from "@shared/data-access/models";
import { CustomerReportAction } from "../actions";

export const featureName = 'customerReport';

export interface DataState {
  item:CustomerReportModel | null;

}
export const initialState:DataState = {
  item: null
};
export const customerReportFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(CustomerReportAction.getDetail,  (state, prop:{item:CustomerReportModel}) => ({ ...state, item: prop.item})),

  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = customerReportFeature;
