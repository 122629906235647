import { createFeature, createReducer, on } from '@ngrx/store';
import { AssetRegisterModel } from '@shared/data-access/models';
import { AssetRegisterAction } from '../actions';

export const featureName = 'assetRegister';

export interface AssetRegisterState {
  item: AssetRegisterModel | null;
}
export const initialState: AssetRegisterState = {
  item: null,
};
export const assetRegisterFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(AssetRegisterAction.loadItemSuccess, (state, prop: { item: AssetRegisterModel }) => ({ ...state, item: prop.item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = assetRegisterFeature;
