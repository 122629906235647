// core
import { createAction, props } from '@ngrx/store';

// share
import { Ir8aAmountModel, PersonalParticularModel } from '@shared/data-access/models';
import { IQuerySearch } from '@shared/data-access/interfaces';
import { PaginationAdapter } from '@red/data-access';

// items
export const loadItems = createAction('[Ir8aAmount] Load items', props<{ filters: IQuerySearch }>());
export const loadItemsSuccess = createAction('[Ir8aAmount] Load items success', props<{ data: PaginationAdapter<Ir8aAmountModel> }>());

// item
export const loadItem = createAction('[Ir8aAmount] Load item', props<{ id: number }>());
export const loadItemSuccess = createAction('[Ir8aAmount] Load item success', props<{ item: Ir8aAmountModel }>());

// salesperson
export const loadSalesperson = createAction('[Ir8aAmount] Load salesperson', props<{ id: number }>());
export const loadSalespersonSuccess = createAction('[Ir8aAmount] Load salesperson success', props<{ salesperson: PersonalParticularModel }>());
