import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BlankFormApiService, IBlankFormBuyerPayload, UnitApiService } from '@cms/apis';
import { BlankFormAction } from '@cms/stores';
import { Store } from '@ngrx/store';
import { ApiServiceForDetailResolver } from '@shared/core';
import {
  BLankFormBdsSetUpDto,
  BLankFormOtherFeeSetUpDto,
  BLankFormPdSetUpDto,
  BLankFormReferralSetUpDto,
  BlankFormBuyerDto,
  BlankFormBuyerPayload,
  BlankFormCreateDto,
  BlankFormIcSetUpDto,
  BlankFormUpdateDto,
  BlankFormBuyerInfoPayload,
  UnitUpdateDto,
  BlankForBuyerInfoDto,
} from '@shared/data-access/dto';
import { EBlankFormStatus, EProjectType } from '@shared/data-access/enums';
import { IBlankForm, IBlankFormRelatedAgent, ISplitPart } from '@shared/data-access/interfaces';
import { BlankFormModel } from '@shared/data-access/models';
import { isNil, omitBy } from 'lodash-es';
import { catchError, forkJoin, of, switchMap, takeLast, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BlankFormService implements ApiServiceForDetailResolver {
  constructor(private _store: Store, private _unitApiService: UnitApiService, private _apiService: BlankFormApiService) { }

  getById(id: number) {
    return this._apiService.get(id).pipe(
      switchMap(item => {
        const { status, project, blankFormId, transactionId } = item;
        const sourceId = blankFormId ?? transactionId;
        const source = blankFormId ? 'blankForm' : 'transaction';
        const isHaveRejectReason = [EBlankFormStatus.rework1, EBlankFormStatus.rework2, EBlankFormStatus.rejected].includes(status);
        const isInternational = project?.entity === EProjectType.INTERNATIONAL;
        return forkJoin([
          of(item),
          isHaveRejectReason
            ? this._apiService.searchStatusHistories({
              orderBy: 'createdAt',
              blankFormIds: id,
            })
            : of(null),
          // isInternational ? this._apiService.searchBds({ blankFormIds: id }) : of(null),
          // this._apiService.searchBonuses({ blankFormIds: id }),
          // this._apiService.searchOverridings({ blankFormIds: id, limit: 1000 }),
          // this._apiService.searchPools({ blankFormIds: id, limit: 1000 }),
          // this._apiService.searchPds({ blankFormIds: id, limit: 1000 }),
          // this._apiService.searchReferrals({ blankFormIds: id, limit: 1000 }),
          // this._apiService.searchOtherFees({ blankFormIds: id, limit: 1000 }),
          this._apiService.searchBuyers({ blankFormIds: id, limit: 1000 }),
          sourceId ? this._apiService.getCommValueFromSource(source, sourceId) : of(null),
          this._apiService.getSummary(id),
        ]);
      }),
      tap(
        ([
          item,
          statusHistoriesPaginated,
          // bdsPaginated,
          // bonusesPaginated,
          // overridingsPaginated,
          // poolsPaginated,
          // pdsPaginated,
          // referralsPaginated,
          // otherFeesPaginated,
          buyersPaginated,
          commValue,
          summary,
        ]) => {
          // console.log(summary.blankFormBds);
          const isInternational = item?.project?.entity === EProjectType.INTERNATIONAL;

          // const bds = bdsPaginated?.results ?? [];
          // const bonuses = bonusesPaginated.results;
          // const overridings = overridingsPaginated.results;
          // const pools = poolsPaginated.results;
          // const pds = pdsPaginated.results;
          // const referrals = referralsPaginated.results;
          // const otherFees = otherFeesPaginated.results;
          const rejectReason = statusHistoriesPaginated?.results[0]?.data?.reason ?? null;
          const bds = isInternational ? summary.blankFormBds : [];
          const bonuses = summary.blankFormBonuses;
          const overridings = summary.blankFormOverridings;
          const pools = summary.blankFormPools;
          const pds = summary.blankFormPdManagements.concat(summary.blankFormPdOverrides, summary.blankFormPdReferrals);
          const referrals = summary.blankFormReferrals;
          const otherFees = summary.blankFormOthers;
          const buyers = buyersPaginated.results;
          const buyer = item.buyer;
          item = BlankFormModel.merge(item, {
            rejectReason,
            bds,
            bonuses,
            overridings,
            pools,
            pds,
            referrals,
            otherFees,
            buyers,
            buyer
          });

          if (commValue) {
            const object = BlankFormModel.toJson(item) as IBlankForm;
            (
              ['bds', 'bonuses', 'overridings', 'pds', 'pools', 'referrals'] as (keyof Pick<
                IBlankForm,
                'bds' | 'bonuses' | 'overridings' | 'pds' | 'pools' | 'referrals'
              >)[]
            ).forEach(key => {
              const referenceMap = commValue[key] as { tiers: ISplitPart[]; parties: ISplitPart[] }[];
              object[key] = object[key].map(ref => {
                const itemMap = referenceMap?.find(i => i.tiers[0].salespersonId === ref.tiers[0].salespersonId);
                if (itemMap) {
                  (['tiers', 'parties'] as (keyof Pick<typeof ref, 'tiers' | 'parties'>)[]).forEach(part => {
                    ref[part] = ref[part].map((p, i) => ({ ...p, defaultValue: itemMap[part][i].value }));
                  });
                }
                return ref;
              }) as any[];
            });

            const transactionRef = commValue.transaction;
            const mains = transactionRef.relatedAgent.main ? [transactionRef.relatedAgent.main] : (transactionRef as unknown as BlankFormModel).relatedAgent.mains;
            const {
              relatedAgent: { internals, leaders },
            } = transactionRef;
            (['mains', 'internals', 'leaders'] as (keyof Pick<IBlankFormRelatedAgent, 'mains' | 'internals' | 'leaders'>)[]).forEach(key => {
              const referenceMap = key === 'mains' ? mains : key === 'internals' ? internals : (leaders as { tiers: ISplitPart[]; parties: ISplitPart[] }[]);
              object.relatedAgent[key] = object.relatedAgent[key].map(ref => {
                const itemMap = referenceMap?.find(i => i.tiers[0].salespersonId === ref.tiers[0].salespersonId);
                if (itemMap) {
                  (['tiers', 'parties'] as (keyof Pick<typeof ref, 'tiers' | 'parties'>)[]).forEach(part => {
                    ref[part] = ref[part].map((p, i) => ({ ...p, defaultValue: itemMap[part][i].value }));
                  });
                }
                return ref;
              });
            });

            item = BlankFormModel.fromJson(object);
          }

          this._store.dispatch(BlankFormAction.setItemDetail({ item }));
        }
      ),
      takeLast(1)
    );
  }

  createFullData(data: BlankFormModel) {
    const dataJson = BlankFormModel.toJson(data);
    const payload = BlankFormCreateDto.fromJson(dataJson);
    const { hiProfitAmount, bds, bonuses, overridings, pools, pds, referrals, otherFees, buyers, buyer } = data;
    const { unit, unitId, unitBlkNo, unitPostalCode, unitNoOfRoom, landAreaSqft, landAreaSqm } = data;
    const isInternational = data.project?.entity === EProjectType.INTERNATIONAL;
    const bdsSetUpPayload = BLankFormBdsSetUpDto.fromJson({
      blankFormId: null,
      isOverride: true,
      hiProfitAmount,
      data: bds,
    });
    const bonusSetUpPayload = BlankFormIcSetUpDto.fromJson({
      blankFormId: null,
      isOverride: true,
      data: bonuses,
    });
    const overridingSetUpPayload = BlankFormIcSetUpDto.fromJson({
      blankFormId: null,
      isOverride: true,
      data: overridings,
    });
    const poolSetUpPayload = BlankFormIcSetUpDto.fromJson({
      blankFormId: null,
      isOverride: true,
      data: pools,
    });
    const pdSetUpPayload = BLankFormPdSetUpDto.fromJson({
      blankFormId: null,
      isOverride: true,
      data: pds,
    });
    const referralSetUpPayload = BLankFormReferralSetUpDto.fromJson({
      blankFormId: null,
      isOverride: true,
      data: referrals,
    });
    const otherFeeSetUpPayload = BLankFormOtherFeeSetUpDto.fromJson({
      blankFormId: null,
      isOverride: true,
      data: otherFees,
    });
    const buyerPayload: BlankFormBuyerPayload = {
      blankFormId: null,
      isOverride: true,
      data: buyers.map(v => BlankFormBuyerDto.fromJson(v)),
    };
    const buyerInfoPayload = BlankForBuyerInfoDto.fromJson({
      remark: buyer.remark
    });

    payload.bds = isInternational ? bdsSetUpPayload : null;
    payload.bonuses = bonusSetUpPayload;
    payload.overridings = overridingSetUpPayload;
    payload.pools = poolSetUpPayload;
    payload.pds = pdSetUpPayload;
    payload.referrals = referralSetUpPayload;
    payload.otherFees = otherFeeSetUpPayload;
    payload.buyers = buyerPayload;
    payload.buyer = buyerInfoPayload;
    payload.haTierProfit = (payload.agentTierProfit ?? 0) + (payload.companyTierProfit ?? 0);

    return this._apiService.create(payload).pipe(
      switchMap(({ id }) => {
        const isUnitInfoChange = unitBlkNo !== unit?.blkNo || unitPostalCode !== unit?.postalCode || unitNoOfRoom !== unit?.noOfRoom || landAreaSqft !== unit?.areaSqft || landAreaSqm !== unit?.areaSqm;
        const unitPayload = UnitUpdateDto.fromJson({
          ...omitBy(unit, isNil),
          blkNo: unitBlkNo,
          postalCode: unitPostalCode,
          noOfRoom: unitNoOfRoom,
          areaSqft: landAreaSqft,
          areaSqm: landAreaSqm
        });

        return forkJoin(
          [
            // bds.length && isInternational ? this._apiService.setUpBds(bdsSetUpPayload) : of(null),
            // bonuses.length ? this._apiService.setUpBonuses(bonusSetUpPayload) : of(true),
            // overridings.length ? this._apiService.setUpOverridings(overridingSetUpPayload) : of(true),
            // pools.length ? this._apiService.setUpPools(poolSetUpPayload) : of(true),
            // pds.length ? this._apiService.setUpPds(pdSetUpPayload) : of(true),
            // referrals.length ? this._apiService.setUpReferrals(referralSetUpPayload) : of(true),
            // otherFees.length ? this._apiService.setUpOtherFees(otherFeeSetUpPayload) : of(true),
            // buyers.length ? this._apiService.createBuyer(buyerPayload) : of(true),
            isUnitInfoChange ? this._unitApiService.update(unitId, unitPayload) : of(null),
          ].map(item =>
            item.pipe(
              catchError(error => {
                return of(new HttpErrorResponse({ error }));
              })
            )
          )
        ).pipe(
          switchMap(res => {
            const error = res.find(item => item instanceof HttpErrorResponse);
            return error ? throwError(() => error) : of(id);
          })
        );
      })
    );
  }

  editFullData(data: BlankFormModel) {
    const dataJson = BlankFormModel.toJson(data);
    const { id, hiProfitAmount, bds, bonuses, overridings, pools, pds, referrals, otherFees, buyers, buyer } = data;
    const { unit, unitId, unitBlkNo, unitPostalCode, unitNoOfRoom, landAreaSqft, landAreaSqm } = data;
    const isInternational = data.project?.entity === EProjectType.INTERNATIONAL;
    const bdsSetUpPayload = BLankFormBdsSetUpDto.fromJson({
      blankFormId: id,
      isOverride: true,
      data: bds,
      hiProfitAmount,
    });
    const bonusSetUpPayload = BlankFormIcSetUpDto.fromJson({
      blankFormId: id,
      isOverride: true,
      data: bonuses,
    });
    const overridingSetUpPayload = BlankFormIcSetUpDto.fromJson({
      blankFormId: id,
      isOverride: true,
      data: overridings,
    });
    const poolSetUpPayload = BlankFormIcSetUpDto.fromJson({
      blankFormId: id,
      isOverride: true,
      data: pools,
    });
    const pdSetUpPayload = BLankFormPdSetUpDto.fromJson({
      blankFormId: id,
      isOverride: true,
      data: pds,
    });
    const referralSetUpPayload = BLankFormReferralSetUpDto.fromJson({
      blankFormId: id,
      isOverride: true,
      data: referrals,
    });
    const otherFeeSetUpPayload = BLankFormOtherFeeSetUpDto.fromJson({
      blankFormId: id,
      isOverride: true,
      data: otherFees,
    });
    const buyerPayload: IBlankFormBuyerPayload = {
      blankFormId: id,
      isOverride: true,
      data: buyers.map(v => BlankFormBuyerDto.fromJson(v)),
    };

    const buyerInfoPayload = BlankForBuyerInfoDto.fromJson({
      remark: buyer.remark
    });

    const isUnitInfoChange = unitBlkNo !== unit?.blkNo || unitPostalCode !== unit?.postalCode || unitNoOfRoom !== unit?.noOfRoom || landAreaSqft !== unit?.areaSqft || landAreaSqm !== unit?.areaSqm;
    const unitPayload = UnitUpdateDto.fromJson({
      ...omitBy(unit, isNil),
      blkNo: unitBlkNo,
      postalCode: unitPostalCode,
      noOfRoom: unitNoOfRoom,
      areaSqft: landAreaSqft,
      areaSqm: landAreaSqm
    });
    dataJson['bds'] = isInternational ? bdsSetUpPayload : null;
    dataJson['bonuses'] = bonusSetUpPayload;
    dataJson['overridings'] = overridingSetUpPayload;
    dataJson['pools'] = poolSetUpPayload;
    dataJson['pds'] = pdSetUpPayload;
    dataJson['referrals'] = referralSetUpPayload;
    dataJson['otherFees'] = otherFeeSetUpPayload;
    dataJson['buyers'] = buyerPayload;
    dataJson['buyer'] = buyerInfoPayload;
    dataJson['haTierProfit'] = (dataJson['agentTierProfit'] ?? 0) + (dataJson['companyTierProfit'] ?? 0);

    const payload = BlankFormUpdateDto.fromJson({
      group: 'full',
      data: dataJson,
    });
    return forkJoin(
      [
        this._apiService.update(id, payload),
        // isInternational ? this._apiService.setUpBds(bdsSetUpPayload) : of(true),
        // this._apiService.setUpBonuses(bonusSetUpPayload),
        // this._apiService.setUpOverridings(overridingSetUpPayload),
        // this._apiService.setUpPools(poolSetUpPayload),
        // this._apiService.setUpPds(pdSetUpPayload),
        // this._apiService.setUpReferrals(referralSetUpPayload),
        // this._apiService.setUpOtherFees(otherFeeSetUpPayload),
        // this._apiService.createBuyer(buyerPayload),
        isUnitInfoChange ? this._unitApiService.update(unitId, unitPayload) : of(null),
      ].map(item =>
        item.pipe(
          catchError(error => {
            return of(new HttpErrorResponse({ error }));
          })
        )
      )
    ).pipe(
      switchMap(res => {
        const error = res.find(item => item instanceof HttpErrorResponse);
        return error ? throwError(() => error) : of(id);
      })
    );
  }
}
