import { createFeature, createReducer, on } from '@ngrx/store';
import { TransferEmplacementHistoryModel } from '@shared/data-access/models';
import { TransferEmplacementAction } from '../actions';

export const featureName = 'transferEmplacementHistoryInfo';

export interface TransferEmplacementHistoryState {
  items: TransferEmplacementHistoryModel[];
}
export const initialState: TransferEmplacementHistoryState = {
  items: [],
};
export const transferEmplacementHistoryFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(TransferEmplacementAction.getList, (state, { payload }) => ({ ...state, items: payload }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = transferEmplacementHistoryFeature;
