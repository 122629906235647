import { createFeature, createReducer, on } from "@ngrx/store";
import { BonusDistributionModel } from "@shared/data-access/models";
import { BonusDistributionAction } from "../actions";

export const featureName = 'bonusDistribution';

export interface DataState {
  item:BonusDistributionModel | null;

}
export const initialState:DataState = {
  item: null
};
export const bonusDistributionFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(BonusDistributionAction.getDetail,  (state, prop:{item:BonusDistributionModel}) => ({ ...state, item: prop.item})),

  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = bonusDistributionFeature;
