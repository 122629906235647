import { createFeature, createReducer, on } from '@ngrx/store';
import { CurrencyRateModel } from '@shared/data-access/models';
import { CurrencyRateAction } from '../actions';

export const featureName = 'currencyRate';

export interface CurrencyRateState {
  item: CurrencyRateModel | null;
}
export const initialState: CurrencyRateState = {
  item: null,
};
export const currencyRateFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(CurrencyRateAction.loadItemSuccess, (state, prop: { item: CurrencyRateModel }) => ({ ...state, item: prop.item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = currencyRateFeature;
