import { PercentPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { strToNumber } from '../../utils/dev-util';
import { MULTIPLIED_BY_100 } from './percent.module';

@Pipe({
  name: 'percent',
})
export class EnhancedPercentPipe extends PercentPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) _locale: string, @Inject(MULTIPLIED_BY_100) private multipliedBy100: boolean) {
    super(_locale);
  }
  override transform(value: number | string, digitsInfo?: string, locale?: string): string | null;
  override transform(value: null | undefined, digitsInfo?: string, locale?: string): null;
  override transform(value: number | string | null | undefined, digitsInfo?: string, locale?: string): string | null;
  override transform(value: number | string | null | undefined, digitsInfo?: string, locale?: string): string | null {
    let num = value;
    if (value) {
      const valueFormatted = strToNumber(value);
      num = this.multipliedBy100 ? valueFormatted / 100 : valueFormatted;
    }
    return super.transform(num, digitsInfo ?? '1.4-4', locale);
  }
}
