import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AssetCategoryModel } from '@shared/data-access/models';
import { filter, Observable, take } from 'rxjs';
import { AssetCategoryAction } from '../actions';
import { AssetCategoryReducer } from '../reducers';
import { AssetCategorySelector } from '../selectors';

@Injectable()
export class AssetCategoryFacade {
  selectedItem$ = this._store.select(AssetCategorySelector.selectAssetCategory);
  constructor(private _store: Store<AssetCategoryReducer.AssetCategoryState>) {}

  /**
   * Get @AssetCategoryModel by id
   */
  getById(id: number): Observable<AssetCategoryModel | null> {
    this._store.dispatch(AssetCategoryAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<AssetCategoryModel | null>;
  }
}
