import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TransactionTypeModel } from '@shared/data-access/models';
import { filter, Observable, take } from 'rxjs';
import { TransactionTypeAction } from '../actions';
import { TransactionTypeReducer } from '../reducers';
import { TransactionTypeSelector } from '../selectors';

@Injectable()
export class TransactionTypeFacade {
  selectedItem$ = this._store.select(TransactionTypeSelector.selectTransactionType);
  constructor(private _store: Store<TransactionTypeReducer.TransactionTypeState>) { }

  /**
   * Get @CommMatrixTypeModel by id
   */
  getById(id: number): Observable<TransactionTypeModel | null> {
    this._store.dispatch(TransactionTypeAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<TransactionTypeModel | null>;
  }
}
