import { PaginationAdapter } from '@red/data-access';
import { createAction, props } from '@ngrx/store';
import { AttachmentModel, BankAccountModel } from '@shared/data-access/models';
import { IQuerySearch } from '@shared/data-access/interfaces';
import { BankAccountCreateDto, BankAccountUpdateDto } from '@shared/data-access/dto';

const featureName = 'BankAccount';
// items
export const loadItems = createAction(`[${featureName}] Load items`, props<{ filters: IQuerySearch }>());
export const loadItemsSuccess = createAction(`[${featureName}] Load items success`, props<{ data: PaginationAdapter<BankAccountModel> }>());

// Get item
export const loadItem = createAction(`[${featureName}] Load item`, props<{ id: number }>());
export const loadItemSuccess = createAction(`[${featureName}] Load item success`, props<{ item: BankAccountModel }>());
// Create item
export const createItem = createAction(`[${featureName}] Create item`, props<{ data: BankAccountCreateDto }>());
export const createItemSuccess = createAction(`[${featureName}] Create item success`, props<{ item: BankAccountModel }>());
// Update item
export const updateItem = createAction(`[${featureName}] Update item`, props<{ id: number; data: BankAccountUpdateDto }>());
export const updateItemSuccess = createAction(`[${featureName}] Update item success`, props<{ item: BankAccountModel }>());
// view attachment
export const getAttachmentUrl = createAction(`[${featureName}] get attachment url`, props<{ data: AttachmentModel }>());
export const getAttachmentUrlSuccess = createAction(`[${featureName}] view attachment`, props<{ attachmentId: number; url: string }>());
