import { createFeature, createReducer, on } from "@ngrx/store";
import { LedgerPathModel, ProfitCentresModel } from "@shared/data-access/models";
import { ProfitCenterAction } from "../actions";

export const featureName = 'profitCenter';

export interface DataState {
  item: ProfitCentresModel | null;
  path: Map<number, { result: LedgerPathModel[], lastUpdatedAt: number, pathFull: string }>;
}

export const initialState: DataState = {
  item: null,
  path: new Map<number, { result: LedgerPathModel[], lastUpdatedAt: number, pathFull: string }>()
};

export const profitCenterFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(ProfitCenterAction.getDetail, (state, prop: { item: ProfitCentresModel }) => ({ ...state, item: prop.item })),
    on(ProfitCenterAction.getPathSuccess, (state, prop: { profitItemId: number, result: LedgerPathModel[], pathFull: string }) => {
      const path = state.path;
      path.set(prop.profitItemId, { result: prop.result, lastUpdatedAt: new Date().getTime(), pathFull: prop.pathFull })
      return {
        ...state, path
      }
    })
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = profitCenterFeature;
