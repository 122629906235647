import { PrintDomModule } from '@shared/components/print-dom';
import { DocumentVisibilityModule } from '@shared/components/document-visibility';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslocoModule } from '@ngneat/transloco';
import { RedApiModule } from '@red/api';
import { RedIconModule } from '@red/components/icon';
import { RedLayoutConfigModule } from '@red/components/layout-config';
import { MediaStorageModule } from '@red/components/media-storage';
import { RedMediaWatcherModule } from '@red/components/media-watcher';
import { RedTailwindConfigModule } from '@red/components/tailwind';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import * as extractedTailwindConfigStyle from '../assets/scss/config/tailwind-config.scss';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConstant } from './app.constant';
// import { AuthModule } from './core/auth';
import { appConfig } from './core/config/app.config';
import { CoreModule } from './core/core.module';
import { CUSTOME_ICON_DATA } from './core/data/icon.data';
import { LayoutModule } from './layout/layout.module';
import { NxWelcomeComponent } from './nx-welcome.component';

import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { APP_DATE_FORMATS, AppMomentDateAdapter } from './core/adapters/datepicker.adapter';
import { NavigationPermissionService } from './core/navigation/navigation-permission.service';
import { NavigationService } from './core/navigation/navigation.service';
import { PhoneNumberEffects } from './stores/effects/phone-number.effects';
import { PhoneNumberReducer } from './stores/reducers';
import { RouteLoadingModule } from '@shared/components/router-loading';
import { PERMISSION_LOADER, PermissionModule } from '@shared/permission';
import { PermissionHttpLoader } from './core/auth/permission/permisison-loader';
import { SWITCH_COMPANY_API_LOADER, SwitchCompanyModule } from '@cms/backgrounds/switch-company';
import { SwitchCompanyApiLoader } from './core/auth/switch-company/switch-company-loader';
import { EmptyModule } from '@red/pipes/empty';
import { EnhancedPercentModule } from '@red/pipes/percent';
import { MetabaseModule } from '@shared/components/metabase';
import { PageTitleModule } from '@shared/features/page-title';
import { BroadcastChannelModule } from '@shared/components/broadcast-channel';
import { EnhancedNumberModule } from '@shared/pipes/enhanced-number';
import { environment } from '../environments/environment';
import { AuthInterceptor, AuthModule, AuthWithRefreshTokenInterceptor, useUathApiLoader } from '@shared/features/auth';
import { AuthService } from '@cms/auth/services';
import { HTTP_STATUS_CODE_EXCLUDE, HttpErrorInterceptor, HttpErrorWrapperInterceptor } from '@shared/components/error-page';
import { SwitchCompanyWraperModule } from './core/auth/switch-company/switch-company-wraper/switch-company-wraper.module';
import { forcedLogoutFn } from './core/auth';
import { ExceljsViewerModule } from '@shared/components/exceljs-viewer';
import { DynamicErrorMessageModule } from '@shared/pipes/dynamic-error-message';
import { HTTP_ERROR_MESSAGES } from '@cms/constants';
import { TranslocoModuleCore } from '@shared/components/transloco';
import { SentryCoreModule } from '@shared/components/sentry';

// 'legacy' | 'standard' | 'fill' | 'outline'
const formFieldDefault: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};
const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: true,
    thousandSeparator: ',',
    separatorLimit: '999999999999999',
  };
};

@NgModule({
  declarations: [AppComponent, NxWelcomeComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    RedLayoutConfigModule.forRoot(appConfig),
    RedIconModule.forRoot(CUSTOME_ICON_DATA),
    RedApiModule.forRoot({
      apiHost: AppConstant.API_HOST,
      urlPrefix: AppConstant.API_URL_PREFIX,
      timeout: AppConstant.DEFAULT_SETTINGS.REQUEST_TIMEOUT,
      appId: AppConstant.APP_ID,
    }),
    RedMediaWatcherModule,
    RedTailwindConfigModule.forRoot(extractedTailwindConfigStyle),
    TranslocoModuleCore.forRoot({ prodMode: environment.production }),
    LayoutModule,
    SwitchCompanyModule,
    // AuthModule.forRoot({
    //   urlLogin: AppConstant.ROUTE.LOGIN,
    // }),
    AuthModule.forRoot({
      serviceInstance: useUathApiLoader(AuthService),
      loginRoute: AppConstant.ROUTE.LOGIN,
      logoutRoute: AppConstant.ROUTE.LOGOUT,
      forcedLogoutFn: forcedLogoutFn,
      customAuthInterceptor: true,
    }),
    MediaStorageModule.forRoot({
      storageDrive: AppConstant.APP_STORAGE_DRIVE,
    }),
    NgxMaskModule.forRoot(maskConfigFunction),
    StoreModule.forRoot({
      phoneNumber: PhoneNumberReducer.phoneNumberReducer,
    }),
    EffectsModule.forRoot([PhoneNumberEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      name: 'Huttons DevTool',
      // logOnly: environment.production, // Restrict extension to log-only mode
      // autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    RouteLoadingModule,
    PermissionModule.forRoot({
      redirectCommand: ['error', '403'],
    }),
    EmptyModule.forRoot({
      defaultValue: '-',
    }),
    EnhancedPercentModule.forRoot({
      multipliedBy100: true,
    }),
    EnhancedNumberModule.forRoot({
      defaultNumberFormat: '1.2-2',
    }),
    DynamicErrorMessageModule.forRoot({
      defaultErrorMessage: null,
      errorMessages: HTTP_ERROR_MESSAGES,
    }),
    MetabaseModule.forRoot(),
    PageTitleModule.forRoot({ prefix: !environment.production ? environment.name : '', default: 'HITS - Huttons Integrated Transaction System' }),
    BroadcastChannelModule.forRoot({
      channelNames: ['appState'],
    }),
    DocumentVisibilityModule,
    SwitchCompanyWraperModule,
    ExceljsViewerModule.forRoot({
      origin: environment.baseUrl,
    }),
    PrintDomModule.forRoot({
      dataDefaults: {
        copyrightText: `${new Date().getFullYear()} Redoc.co. All Rights Reserved`,
      },
    }),
    SentryCoreModule.forRoot(environment.sentryConfig.options)
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorWrapperInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthWithRefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_STATUS_CODE_EXCLUDE,
      useValue: AppConstant.HTTP_ERROR.HTTP_STATUS_CODE_EXCLUDE,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
    {
      provide: DateAdapter,
      useClass: AppMomentDateAdapter,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: formFieldDefault,
    },
    {
      provide: PERMISSION_LOADER,
      useClass: PermissionHttpLoader,
    },
    {
      provide: SWITCH_COMPANY_API_LOADER,
      useClass: SwitchCompanyApiLoader,
    },
    {
      provide: NavigationService,
      useClass: NavigationPermissionService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
