import { createAction, props } from '@ngrx/store';
import { ManagementCommissionCreateDto, ManagementCommissionPdCreateDto, ManagementCommissionPdUpdateDto } from '@shared/data-access/dto';
import { EManagementCommissionPdRole } from '@shared/data-access/enums';
import { ManagementCommissionModel } from '@shared/data-access/models';

export const setItemDetail = createAction('[Management Commission] SET ITEM DETAIL', props<{ item: ManagementCommissionModel | null }>());

export const updateItemDetail = createAction('[Management Commission] UPDATE ITEM DETAIL', props<{ data: Partial<ManagementCommissionModel> }>());

export const loadItemDetail = createAction('[Management Commission] LOAD ITEM DETAIL', props<{ id: number }>());

export const reloadItemDetail = createAction('[Management Commission] RELOAD ITEM DETAIL');

export const submitItemCreating = createAction('[Management Commission] SUBMIT ITEM CREATING');

export const resetItemCreating = createAction('[Management Commission] RESET ITEM CREATING');

export const submitItemCreatingSuccess = createAction('[Management Commission] SUBMIT ITEM CREATING SUCCESS');

export const submitItemCreatingFail = createAction('[Management Commission] SUBMIT ITEM CREATING FAIL', props<{ error: any }>());

export const resetSubmittingStatus = createAction('[Management Commission] RESET SUBMITTING STATUS');
export const setItemCreating = createAction('[Management Commission] SET ITEM CREATING', props<{ data: Partial<ManagementCommissionCreateDto> }>());

export const addPdCreating = createAction(
  '[Management Commission] ADD PD CREATING',
  props<{
    data: ManagementCommissionPdCreateDto;
  }>()
);

export const editPdCreating = createAction(
  '[Management Commission] EDIT PD CRETING',
  props<{
    id: number;
    pdRole: EManagementCommissionPdRole;
    data: ManagementCommissionPdUpdateDto;
  }>()
);

export const deletePdCreating = createAction(
  '[Management Commission] DELETE PD CRETING',
  props<{
    id: number;
    pdRole: EManagementCommissionPdRole;
  }>()
);

export const toggleActivePdCreating = createAction(
  '[Management Commission] TOGGLE ACTIVE PD CREATING',
  props<{
    pdRole: EManagementCommissionPdRole;
    active: boolean;
  }>()
);
