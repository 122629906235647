// core
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

// share
import { PersonalParticularSelector } from '../selectors';
import { PersonalParticularReducer } from '../reducers';
import { PersonalParticularAction } from '../actions';

@Injectable()
export class PersonalParticularFacade {
  detail$ = this._store.select(PersonalParticularSelector.selectPersonalParticularDetail);

  constructor(private _store: Store<PersonalParticularReducer.PersonalParticularState>) {}

  getPersonalParticularById(id: number): void {
    this._store.dispatch(PersonalParticularAction.loadItem({ id }));
  }

  getPersonalParticularByAccessToken(): void {
    this._store.dispatch(PersonalParticularAction.loadInfo());
  }
}
