import { createAction, props } from "@ngrx/store";
import { LedgerPathModel, ProfitCentresModel } from "@shared/data-access/models";

export const getPathSuccess = createAction(
  '[PROFIT CENTER] GET PATH SUCCESS',
  props<{ profitItemId: number, result: LedgerPathModel[], pathFull: string }>()
);

export const getPath = createAction(
  '[PROFIT CENTER] GET PATH',
  props<{ profitItemId: number }>()
);

export const countMemberFailure = createAction(
  '[PROFIT CENTER] PATH FAILURE',
  props<{ error: any }>()
);

export const getDetail = createAction(
  '[PROFIT CENTRER] GET DETAIL',
  props<{ item: ProfitCentresModel }>()
);
