import { createAction, props } from '@ngrx/store';
import { ResaleAdjustmentModel, ResaleCoBrokeModel, ResaleTransactionModel, SplitPartModel } from '@shared/data-access/models';

export const getDetail = createAction('[Resale Adjustment] GET DETAIL', props<{ item: ResaleAdjustmentModel }>());

export const pullDataFormResaleTransactionForDraftItem = createAction(
  '[Resale Adjustment] PULL DATA FROM RESALE TRANSACTION FOR DRAFT ITEM',
  props<{ data: ResaleTransactionModel }>()
);

export const setDraftItemAsCreate = createAction('[Resale Adjustment] SET DRAFT ITEM AS CREATE');

export const setDraftItemAsEdit = createAction('[Resale Adjustment] SET DRAFT ITEM AS EDIT');

export const saveDraftItem = createAction('[Resale Adjustment] SAVE DRAFT ITEM');

export const updateDraftItem = createAction('[Resale Adjustment] UPDATE DRAFT ITEM', props<{ data: Partial<ResaleAdjustmentModel> }>());

export const saveAndSubmitDraftItem = createAction('[Resale Adjustment] SAVE AND SUBMIT DRAFT ITEM');

export const submitItemSuccess = createAction('[Resale Adjustment] SUBMIT DRAFT ITEM SUCCESS', props<{ res: any }>());

export const submitItemFail = createAction('[Resale Adjustment] SUBMIT DRAFT ITEM FAIL', props<{ error: any }>());

export const resetDraftItem = createAction('[Resale Adjustment] RESET DRAFT ITEM');

export const resetSubmittingStatus = createAction('[Resale Adjustment] RESET SUBMITTING STATUS');

export const updateInvalidAgents = createAction('[Resale Adjustment] UPDATE INVAILD AGENTS', props<{ agents: Partial<SplitPartModel[]> }>());