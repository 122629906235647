import { createFeatureSelector, createSelector } from "@ngrx/store";
import { BatchingApprovalReducer } from "../reducers";

export const selectBatchingApprovalFeature = createFeatureSelector<BatchingApprovalReducer.DataState>(
  BatchingApprovalReducer.featureName
);

export const selectFeatureBatchingApproval = createSelector(
  selectBatchingApprovalFeature,
  (state: BatchingApprovalReducer.DataState) => state.item
);

