// core
import { exhaustMap, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// share
import { GceAmountApiService } from '@cms/apis';
import { GceAmountAction } from '../actions';

@Injectable()
export class GceAmountEffect {
  loadItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(GceAmountAction.loadItem),
      exhaustMap(action => {
        return this._gceAmountApiService.get(action.id).pipe(map(item => GceAmountAction.loadItemSuccess({ item })));
      })
    );
  });

  constructor(private _actions$: Actions, private _gceAmountApiService: GceAmountApiService) {}
}
