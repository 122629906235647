import { MatSelect } from '@angular/material/select';
import { Directive, Host, Self } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[compareWithId]',
})
export class CompareWithIdDirective {
  constructor(@Host() @Self() private matSelect: MatSelect) {
    if (this.matSelect) {
      this.matSelect.compareWith = this.compare.bind(this);
    }
  }
  compare(obj1?: { id: string | number | symbol }, obj2?: { id: string | number | symbol }): boolean {
    return obj1?.id === obj2?.id;
  }
}
