import { Injectable } from '@angular/core';
import { TransactionTypeApiService } from '@cms/apis';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, map } from 'rxjs';
import { TransactionTypeAction } from '../actions';

@Injectable()
export class TransactionTypeEffect {
  loadItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(TransactionTypeAction.loadItem),
      exhaustMap(action => {
        return this._apiService.get(action.id).pipe(map(item => TransactionTypeAction.loadItemSuccess({ item })));
      })
    );
  });
  constructor(private _apiService: TransactionTypeApiService, private _actions$: Actions, private _store: Store) { }
}
