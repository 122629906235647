import { createFeature, createReducer, on } from '@ngrx/store';
import { PersonalParticularModel } from '@shared/data-access/models';
import { TeamOrgChartAction } from '../actions';

export const featureName = 'teamOrgChart';

export interface TeamOrgChartState {
  item: PersonalParticularModel | null;
}
export const initialState: TeamOrgChartState = {
  item: null,
};
export const teamOrgChartFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(TeamOrgChartAction.loadItemSuccess, (state, prop: { item: PersonalParticularModel }) => ({ ...state, item: prop.item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = teamOrgChartFeature;
